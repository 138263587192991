import React, { useEffect, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {useLocation, useNavigate} from 'react-router-dom';
import { Form, InputGroup, Row, Button, Card } from 'react-bootstrap';





  

function OtpForm(){
   
   
    const location = useLocation();
   // console.log(location);
    const uin = location.state
   // console.log(uin)
    const navigate = useNavigate();

    const fetchData = () => {
     
      fetch(`https://sahulat.fsedge.com:8085/admin/getOtpClientData/${location.state}`)
   // fetch(`http://192.168.0.182:8085/admin/getOtpClientData/${location.state}`)
           .then(response => {
           
             return response.json()
            
           })
           .then(data => {
            setName(data.name);
            setEmail(data.email);
            setResidentialStatus(data.residential_STATUS);
            setIdType(data.identification_TYPE);
            setIdNumber(data.uin)
            setIdIssueDate(data.issue_DATE)
            setMobileNumber(data.mobile_NO)
            setIbanNo(data.iban_NO)
            setMpIdCard(data.relative_UIN)
            setRelationShip(data.relationship)
            setRelationshipProof(data.proofofRelationship)
            setIbanFile(data.proofofIBAN)
           })
       }

       useEffect(() => {
        fetchData()
      }, [])

      const [name , setName] = useState("");  
      const [email , setEmail] = useState("");
      const [residentialStatus , setResidentialStatus] = useState("");
      const [idType , setIdType] = useState("");
      const [idNumber , setIdNumber] = useState("");
      const [idIssueDate , setIdIssueDate] = useState(new Date());
      const [mobileNumber , setMobileNumber] = useState("");
      const [ibanNo , setIbanNo] = useState();
      const [ibanFile , setIbanFile] = useState();
      const [relationshipProof ,setRelationshipProof] = useState('');
      const [relationShip , setRelationShip] = useState('');

      const [mpIdCard , setMpIdCard]=useState('');
      const [message, setMessage] = useState("");
      const [error, setError] = useState("");
      const [validateError , setValidateError] = useState('');
      const [nameError , setNameError] = useState("");  
      const [emailError , setEmailError] = useState("");
      const [idError , setIdError] = useState("");
      const [ibanError , setIbanError] = useState("");
      const [mobileError , setMobileError] =useState("");

      const [showHide , setShowHide] =useState(''); 
      const [showForm , setShowForm] = useState('');


    function handleChange3(e) {
  
        let ibanFile = e.target.files;
        let ibanReader = new FileReader();
        ibanReader.readAsDataURL(ibanFile[0]);
        ibanReader.onload=(e)=>{
            setIbanFile(e.target.result);

        }        
    }
    function handleChange4(e) {

        let relationshipFile  = e.target.files;
        let relationshipReader = new FileReader();
        relationshipReader.readAsDataURL(relationshipFile[0]);
        relationshipReader.onload=(e)=>{
            setRelationshipProof(e.target.result);
        }       
    }
 

    let handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Inside handle Submit..");
        {
        try {
            setMessage("");
            setError("");

        let res = await fetch("https://sahulat.fsedge.com:8085/sahulat/otpUpdate", {
      //  let res = await fetch("http://192.168.0.182:8085/sahulat/otpUpdate", {
            method: "POST",
            method: "POST",
            body: JSON.stringify({

              name: name,
              email: email,
              residential_STATUS: residentialStatus,
              identification_TYPE: idType,
              uin: idNumber,
              issue_DATE: idIssueDate,
              mobile_NO: mobileNumber,
              
              relationship:relationShip,
             
              relative_UIN:mpIdCard,
              
              iban_NO: ibanNo,
              
              
              relationFile:relationshipProof,
              
             
            
   
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
              },
              credentials: 'include',
            withCredentials: true
          });
          let resJson = await res.json();
          if (res.status === 200) {
          
            setMessage(resJson.desc);
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }
        }
      };
   

    return(
        <>
             <br></br>
             <br></br>
             <br></br>
            
          <div className="registration-form">
            <center>
              <h2 className="signup-heading">Otp Generation Form</h2>     
              </center>
           <form className="container mt-3 mb-3">

            <Row className="mb-3">
            <Form.Group controlId="name" className="col col-sm-6">
                <Form.Label className="signUp-Label">Full Name of Applicant</Form.Label>
                <p className="sign-paragraph">As per valid CNIC</p>
                <input  type="text" 
                        onChange={(e) => setName(e.target.value)} 
                        value={name} name="nomineeName" 
                        className="form-control" 
                        placeholder='Enter Here' 
                        pattern="[A-Za-z]{50}" 
                        title="Number and Special character not allowed" 
                        required/>
              <p className='validateStyle'>{nameError}</p>
                </Form.Group>
                <Form.Group controlId="nomineeName" className="col col-sm-6">
                <Form.Label className="signUp-Label">Enter your Email Address</Form.Label>
                <p className="sign-paragraph">Email is the primary mode of communication for correspondence.</p>
                <input type="text" placeholder='email@address.com' onChange={(e) => setEmail(e.target.value)} value={email} name="nomineeName" className="form-control" required/>
       
                </Form.Group>
            </Row>

            <Row className="mb-3">
            <Form.Group controlId="name" className="col col-sm-6">
                <Form.Label className="signUp-Label">Residential Status</Form.Label>
 
            <Form.Select className="form-control" name="a_state" value={residentialStatus} onChange={(e) => setResidentialStatus(e.target.value)}>
                <option value="">Select...</option>
                <option value="01">Pakistani Resident</option>
                <option value="02">Foreign Resident</option>
           
                
            </Form.Select>
                </Form.Group>
                <Form.Group controlId="nomineeName" className="col col-sm-6">
                <Form.Label className="signUp-Label">ID Type</Form.Label>

             <Form.Select className="form-control" name="a_state" value={idType} onChange={(e) => setIdType(e.target.value)}>
                <option value="">Select...</option>
              
                <option value="cnic">CNIC</option>
                <option value="snic">SNIC</option>
               

                <option value="nicop">NICOP</option>
                <option value="poc">POC</option>
              

            </Form.Select>
                </Form.Group>
            </Row>

            <Row className="mb-3">
           
                <Form.Group controlId="uin" className="col col-sm-6">
                <Form.Label className="signUp-Label">Identity Card Number</Form.Label>
                <p className='sign-paragraph'>i.e. CNIC</p>
                <input type="text" placeholder='00000-0000000-0' onChange={(e) => setIdNumber(e.target.value)} value={idNumber} name="uin" className="form-control" required />
        
                </Form.Group>
            
            <Form.Group controlId="cnicExpiryDate" className="col col-sm-6">
                <Form.Label className="signUp-Label">Identity Card Date of Issue</Form.Label>
                <p className="sign-paragraph">As per valid CNIC</p>
             
                <input type="date" onChange={(e) => setIdIssueDate(e.target.value)}  value={idIssueDate} className="form-control" />

                </Form.Group>
                </Row>
          

            <Row className="mb-3">
            <Form.Group controlId="incomeDetail" className="col col-sm-6">
                <Form.Label className="signUp-Label">Enter your mobile number</Form.Label>
                <input type="text" placeholder='Mobile number format should be 03XXXXXXXXX' onChange={(e)=>setMobileNumber(e.target.value)} value={mobileNumber} name="nomineeMobile" className="form-control"/>
         
            </Form.Group>

            <Form.Group controlId="incomeDetail" className="col col-sm-6">
                    <Form.Label>Relationship with the person whose mobile number is provided</Form.Label>
                    <Form.Select className="form-control" name="nomineeRelation" value={relationShip} onChange={(e) => setRelationShip(e.target.value)}>            
                    <option value="">Select...</option>
                    <option value="01">Self</option>
                    <option value="02">Father</option>
                    <option value="03">Mother</option>
                    <option value="04">Son</option>
                    <option value="05">Daughter</option>
                    <option value="06">Husband</option>
                    <option value="07">Company</option>              
                </Form.Select>
             </Form.Group>

           

            </Row>

        
           <Row className="mb-3">
         
          
                    
           <Form.Group controlId="incomeDetail" className="col col-sm-6">
               <Form.Label className="signUp-Label">Identity Card of person selected above</Form.Label>
               <p className="sign-paragraph">i.e. CNIC/NICOP</p>
               <input type="text" placeholder='00000-0000000-0' onChange={(e)=>setMpIdCard(e.target.value)} value={mpIdCard} name="nomineeMobile" className="form-control"/>
           </Form.Group>

           <Form.Group controlId="incomeDetail" className="col col-sm-6">
                <Form.Label className="signUp-Label">International Bank Account Number (IBAN) Number</Form.Label>
                <p className="sign-paragraph">Enter your  24 digits account number as mentioned in your cheque book</p>
                <input type="text" placeholder='PK24 MUCB **** **** **** ****' onChange={(e)=>setIbanNo(e.target.value)} value={ibanNo} name="nomineeMobile" className="form-control"/>
           
            </Form.Group>

           </Row>
           <Row className="mb-3">
        

           </Row>

           <Row>
           <Form.Group controlId="incomeDetail" className="col col-sm-6">
           <Form.Label className="signUp-Label">Proof of relationship selected above </Form.Label>
           <p className="sign-paragraph">I.e. CNIC, Family Registration Certificate (FRC)  or any other acceptable document              </p>
           <p className="sign-paragraph">  Supported Formats: JPG, PNG, PDF; Max File Size: 5MB </p>
            <p className="sign-paragraph">  Do not upload password protected files </p>
           <br></br>
           <input type="file" onChange={handleChange4} />
           <img className="col-sm-6" src= {relationshipProof} />
           </Form.Group>

           <Form.Group controlId="incomeDetail" className="col col-sm-6">
            <Form.Label className="signUp-Label">Provide the proof of IBAN </Form.Label>
            <p className="sign-paragraph">I.e. Copy of cheque  or any other acceptable document  </p>
            <p className="sign-paragraph">  Supported Formats: JPG, PNG, PDF; Max File Size: 5MB </p>
            <p className="sign-paragraph">  Do not upload password protected files </p>
            
            <input type="file" onChange={handleChange3} />
            <img className="col-sm-6" src= {ibanFile} />
          
            </Form.Group>
      
           </Row>
          
 
            <div className="form-row row">
                <div className="col">
                    <input type="submit" onClick={handleSubmit} className="btn btn-primary signup-btn" />
                   
              
                </div>
                </div>

                <div className=" alert-success">
            {message}
           </div>
          <div className=" alert-danger">
            {error}
        </div >
        
          
        </form>
        </div>

   </>

        
    )
}
export default OtpForm;