import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, InputGroup, Row, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import DatePicker from "react-datepicker";  
import Navbar from "./Navbar.js";
import "react-datepicker/dist/react-datepicker.css";  
import report from './Reports/Foundation_Securities.pdf' 
import Sidebar from "./Sidebar.js";
import ContactStyle from "./ContactStyle.css"
//import report from 'D:/LOCAL_CONF_WEB/2023/JANUARY/23/Foundation_Securities.pdf' 

const reportStyle={
  display:"none"
};

function Report(){
    const navigate = useNavigate();

    const navigateToOther = () => {
    navigate('/OtherDetails');
      };



const [message, setMessage] = useState("");
const [error, setError] = useState("");


let handleSubmit = async (e) => {
  e.preventDefault();


  try {
      setMessage("");
      setError("");
  let res = await fetch("https://sahulat.fsedge.com:8085/sahulat/reportId", {
 //  let res = await fetch("https://192.168.0.182:8085/sahulat/reportId", {
      method: "POST",
      body: JSON.stringify({
        userId: sessionStorage.getItem('userId'),
             

    }),
      headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
        },
        credentials: 'include',
            withCredentials: true
    });
    let resJson = await res;
    if (res.status === 200) {
     
     // setMessage("Form Submitted Successfully");
    //  const path = 'D:LOCAL_CONF_WEB/2023/JANUARY/23/Foundation_Securities.pdf';
    //  const myWindow = window.open(report,'_blank','fullscreen=yes');
   // window.open(document.getElementById("go").click(), '_self');  
    const myWindow = window.open(document.getElementById("go").click(), '_blank' ,'fullscreen=yes');
      return false;
  
     
    } else {
      setError("Some error occured");
    }
  } catch (err) {
    console.log(err);
    setError("Server is not responding");
  }
};


   return(
        <>
        <Navbar/>
        <br></br>
        <br></br>
        <br></br>

     

    
        <form className="container mt-3 mb-3">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <center>
            <div className="form-row row">
                <div className="col">
        
           <a href = "#"  onClick={handleSubmit} className="btn btn-primary">Print Form</a>
               </div>
                </div>
        </center>
    
        </form>
    
        <form method="get" action="https://sahulat.fsedge.com:8085/sahulat/printReport"  id="hidden" target="_blank">
	     	
			  <input type="submit" value="Enter" id="go" style={reportStyle} />	      
	      </form>
      
        </>
     
    )
    }
    export default Report;