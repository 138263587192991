import { Navigate } from "react-router-dom";

export const isLogin=()=>{
  
    if(sessionStorage.length  != 0)
    {
        return true;
    }
    return false;
}

