import React from 'react';
import style from './style.css';
import logo from './img/FSDGE/FSDGE-logo.png'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    
    Link
  } from "react-router-dom";
  import styled from "styled-components";
  const NavUnlisted = styled.ul`
  text-decoration: none;
`;

const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  color: '#b29d3f'
};

const linkLogoStyle = {
  margin: "0 !important",
  display:"flex"
};

function Navbar(){

    return(
        <>
           <NavUnlisted className="navbar">
           <div className="logo-navbar">
		       <img  src={logo} alt={"logo"} width="130px" height="35px" align="left" style={linkLogoStyle}/> 
	          </div>
           <Link to="/BasicDetails" style={linkStyle}>Basic Details</Link>
           <Link to="/ContactDetails" style={linkStyle}>Contact Details</Link>
           <Link to="/AttorneyDetails" style={linkStyle}>Attorney Details</Link>
           <Link to="/NomineeDetails" style={linkStyle}>Nominee Details</Link>
           <Link to="/OtherDetails" style={linkStyle}>Other Details</Link>
           <Link to="/Report" style={linkStyle}>Report</Link>
           <Link to="/Login" style={linkStyle}>Logout</Link>
           <Link to="/AccountDetail" style={linkStyle}>Account Detail</Link>
           <Link to="/AdditionalDetail" style={linkStyle}>Additional Detail</Link>

          
       </NavUnlisted>   
        </>
    )

}

export default Navbar;