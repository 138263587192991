import React, { useEffect, useState } from 'react';
import style from './style.css';
import logo from './img/FSDGE/FSDGE-logo.png'
import softechLogo from './img/softech/softech.jfif'

import {
    BrowserRouter as Router,
    Routes,
    Route,
    
    Link
  } from "react-router-dom";
  import styled from "styled-components";
import { left } from '@popperjs/core';
  const NavUnlisted = styled.ul`
  text-decoration: none;
`;

const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  color: '#b29d3f',
  float: 'right'
};

const linkLogoStyle = {
  margin: "3rem !important",
  display:"flex",
 margin : "0px 5px 1px 30px "
};



function Navbar(){

    return(
        <>
       
           <NavUnlisted className="navbar">
           <div className="logo-navbar">
		       <img  src={logo} alt={"logo"} width="130px" height="35px" align="left" style={linkLogoStyle}/> 
	          </div>
        
           
           <Link to="/BasicDetails" style={linkStyle}>Personal Details</Link>
           <Link to="/ContactDetails" style={linkStyle}>Contact Details</Link>
           <Link to="/NomineeDetails" style={linkStyle}>Nominee Details</Link>
           <Link to="/AccountDetail" style={linkStyle}>Account Details</Link>
           <Link to="/AdditionalDetail" style={linkStyle}>Additional Details</Link>
           <Link to="/Report" style={linkStyle}>Print Form</Link>
           <Link to="/Logout" style={linkStyle}>Logout</Link>
  

          
       </NavUnlisted>   
       

      
        </>
    )

}

export default Navbar;