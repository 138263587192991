import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";  
import Navbar from "./Navbar.js";
import Sidebar from "./Sidebar.js";
import ContactStyle from "./ContactStyle.css"
  
import "react-datepicker/dist/react-datepicker.css";  
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './style.css'
import { Form, InputGroup, Row, Button } from 'react-bootstrap';
import termAndCondition from './Reports/Term&Conditions.pdf' 
//import slabDoc from './Reports/commissionSlab.png' 
import slabDoc from './Reports/commissionSlabWF.png' 
import accountDoc from './Reports/accountPreferences.png' 
import nZakatDoc from './Reports/Zakat-ExemptionforNonMuslims.pdf' 
import mZakatDoc from './Reports/ZakatFormat.docx' 



function AccountDetail(){

    const navigate = useNavigate();

    const navigateToContacts = () => {
    navigate('/AdditionalDetail');
      };

    const [disableInput , setDisableInput] = useState("true");
    const [transactionMode , setTransactionMode] = useState('');
    const [investmentIntention , setInvestmentIntention] = useState('');
    const [otherInvestment , setOtherInvestment] = useState('');
    const [zakatDeducted , setZakatDeducted] = useState('');
    const [profitSharing , setProfitSharing] = useState('');
    const [costIVR , setCostIVR] = useState('');
    const [tradingAccount , setTradingAccount] = useState('');
    const [commissionSlab , setCommissionSlab] = useState('');
    const [termCondition , setTermCondition] = useState('');  
    const [remittanceBasis , setRemittanceBasis] = useState("");
     const [zakatFile , setZakatFile] = useState('');
     const[disable , setDisable] = useState('');

     const[zakatFileSize , setZakatFileSize] = useState('');
     const[uknFileSize , setUknFileSize] = useState('');

     const[ukn , setUkn] = useState('');
     const[uknNo ,setUknNo] = useState('');
     const[uknForeigner , setUknForeigner] = useState('');
     const[uknFile , setUknFile] = useState(''); 

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [showOther , setShowOther] = useState('');

    const fetchData = () => {
    fetch(`https://sahulat.fsedge.com:8085/sahulat/fetchAccountDetails/${sessionStorage.getItem('userId')}`)
   //  fetch(`http://192.168.0.182:8085/sahulat/fetchAccountDetails/${sessionStorage.getItem('userId')}`)
            .then(response => {
                if(response.status == 500){
                    setDisableInput(false);
                }
              
              return response.json()
            })
            .then(data => {
                setTransactionMode(data.modeOfTransaction)
                setInvestmentIntention(data.businessRelationship)
                setOtherInvestment(data.businessRelationshipOther)
                setZakatDeducted(data.zakatDeduction)
                setProfitSharing(data.profitSharing)
                setCostIVR(data.webServicesCost)
                setTradingAccount(data.tradingAccountPrefrence)
                setCommissionSlab(data.commissionSlabs)
                setTermCondition(data.termsAndCondition)
                setRemittanceBasis(data.remittanceType)
             
                setUkn(data.haveUkn)
                setUknNo(data.uknNumber)
               

          
            })
        }

        useEffect(() => {
       
            fetchData()
          }, [])

    

    function handleShowOther  (e) {
        const getOption = e.target.value;
       
        setShowOther(getOption);
        setInvestmentIntention(getOption);
       }

    function showPreferences(){
        const myWindow = window.open(accountDoc,'_blank','fullscreen=yes');
    }

    function showSlab(){
        const myWindow = window.open(slabDoc,'_blank','fullscreen=yes');
    }

    function showMuslimZakat(){
        const myWindow = window.open(mZakatDoc,'fullscreen=yes');
    }

    function showNonMuslimZakat(){
        const myWindow = window.open(nZakatDoc,'_blank','fullscreen=yes');
    }

    function showTermCOndition(){
        const myWindow = window.open(termAndCondition,'_blank','fullscreen=yes');
    }

    function handleImage(e){

        let zakatFile  = e.target.files;
        let fileSize = e.target.files[0].size;
        let fileSizeInKB = (fileSize/1024); 
        setZakatFileSize(fileSizeInKB);
        let zakatReader = new FileReader();
        zakatReader.readAsDataURL(zakatFile[0]);
        zakatReader.onload=(e)=>{
        setZakatFile(e.target.result);
    }

}

function handleUknDocument(e) {
  
    let uknFile = e.target.files;
    let fileSize = e.target.files[0].size;
    let fileSizeInKB = (fileSize/1024); 
    setUknFileSize(fileSizeInKB);
    let uknReader = new FileReader();
    uknReader.readAsDataURL(uknFile[0]);
    uknReader.onload=(e)=>{
        setUknFile(e.target.result);
    }
    
}

function validateForm (){
 
    if(uknFileSize > 600){
        return  setError("Ukn file size should not be greater than 600 KB") 
    }

    if(zakatFileSize > 600){
        return  setError("Zakat file size should not be greater than 600 KB") 
    }

        return "Ok"; 
   }


    let handleSubmit = async (e) => {


        e.preventDefault();
        const result=validateForm();
        console.log("Inside handle Submit..");
        if(result === "Ok")
        {
        try {
            setMessage("");
            setError("");

   //   let res = await fetch("http://192.168.0.182:8085/sahulat/saveAccountDetails", {
      let res = await fetch("https://sahulat.fsedge.com:8085/sahulat/saveAccountDetails", {
            method: "POST",
            body: JSON.stringify({
               
                userId: sessionStorage.getItem('userId'),
                modeOfTransaction:transactionMode,
                businessRelationship:investmentIntention,
                businessRelationshipOther:otherInvestment,
                zakatDeduction:zakatDeducted,
                profitSharing:profitSharing,
                webServicesCost:costIVR,
                tradingAccountPrefrence:tradingAccount,
                commissionSlabs:commissionSlab,
                termsAndCondition:"yes",
                zakatFile:zakatFile,
                remittanceType:remittanceBasis,
                haveUkn:ukn,
                uknNumber:uknNo,
                uknFile:uknFile,
           }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
              },
              credentials: 'include',
            withCredentials: true
          });
          let resJson = await res.json();
          if (res.status === 200) {
         
          
            setMessage(resJson.desc);
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }
    }
      };
    return(
        <>
     
        <Navbar/>
       
      
        <br></br>
        <br></br>
        <br></br>
      

    <br></br>
       <center>
        <h3><strong>Account Details</strong></h3>  
        </center>
        <form className="container mt-3 mb-3">

        <Row>
  
  <Form.Group controlId="formBasicEmail" className="col col-sm-8">
          <Form.Label>Type of trading account</Form.Label>
          <Form.Select className="form-control font-weight-normal" name="a_state" value={tradingAccount} onChange={(e) => setTradingAccount(e.target.value)}>
          
          <option value="">Select...</option>
          <option value="foundationEdgeValue">Foundation Edge Value</option>
          <option value="foundationEdgePrime">Foundation Edge Prime</option>
          <option value="foundationShariahEdge">Foundation Shariah Edge</option>
         
      </Form.Select>
      <p> Please  <a href = "https://fs.com.pk/online-trading/" target="_blank" >Click Here</a> to read the trading account types that suits your trading  preferences</p>
      </Form.Group>

</Row>

 
<Row className="mb-3">
  <Form.Group controlId="formGridState" className="col col-sm-8">
      <Form.Label>Commission slab as per trading preference</Form.Label>
      <Form.Select  className="form-control font-weight-normal" name="a_state" value={commissionSlab} onChange={(e) => setCommissionSlab(e.target.value)}>
          <option value="">Select...</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
                
      </Form.Select>
      <p> Please  <a href = "#" onClick={showSlab} >Click Here</a> to read the commission slab, before acceptance.</p>
  </Form.Group>

 </Row>


        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>Expected Modes of Transactions</Form.Label>
            <Form.Select  className="form-control font-weight-normal" name="a_state" value={transactionMode} onChange={(e) => setTransactionMode(e.target.value)}>
                <option value="">Select...</option>
                <option value="online">Online</option>
                <option value="offline">Offline</option>
                <option value="both">Both(Online/Offline)</option>

                
            </Form.Select>
            </Form.Group>
     
        </Row>
      
    <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-8">
            <Form.Label>Purpose of the business relationship and investment intention</Form.Label>
            <Form.Select className="form-control font-weight-normal" name="a_state" value={investmentIntention} onChange={(e) => handleShowOther(e)}>
                <option value="">Select...</option>
                <option value="longTerm">Long Term Investment (More than 12 months)</option>
                <option value="shortTerm">Short Term Investment (less than 12 months)</option>
                <option value="both">Both (Long / Short Term Investment)</option>
                <option value="other">Other (Please specify)</option>
                
            </Form.Select>
        </Form.Group>
        { showOther === 'other' && (
        <div>
        <Row>
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>Other (Please specify)</Form.Label>
            <input  type="text" onChange={(e) => setOtherInvestment(e.target.value)} value={otherInvestment} name="applicationId" className="form-control font-weight-normal" />
        </Form.Group>
        </Row>
        </div>
        )}
    </Row>
    <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-8">
            <Form.Label>Whether Zakat to be deducted</Form.Label>
            <Form.Select  className="form-control font-weight-normal" name="a_state" value={zakatDeducted} onChange={(e) => setZakatDeducted(e.target.value)}>
                <option value="">Select...</option>
                <option value="05">Muslim Zakat deductible</option>
                <option value="06">Muslim Zakat non-deductible</option>
                <option value="07">Not Applicable</option>
                
            </Form.Select>
            { zakatDeducted === '06' && (
                <>
            <p>If, Zakat is not deductible, please submit CZ-50 Form or Non-Muslims shall submit an affidavit (Whichever is applicable)</p>
            <p>Please download this<a href = "#"  onClick={showMuslimZakat}> CZ-50 format</a> or <a href = "#"  onClick={showNonMuslimZakat} >affidavit</a>, and upload it here  <input type="file" onChange={handleImage} /> </p>
            <img className="col-sm-6" src={zakatFile} /> 
            </>
            )}
              { zakatDeducted === '07' && (
                <>
            <p>If, Zakat is not deductible, please submit CZ-50 Form or Non-Muslims shall submit an affidavit (Whichever is applicable)</p>
            <p>Please download this<a href = "#"  onClick={showMuslimZakat}> CZ-50 format</a> or <a href = "#"  onClick={showNonMuslimZakat} >affidavit</a>, and upload it here  <input type="file" onChange={handleImage} /> </p>
            <img className="col-sm-6" src={zakatFile} /> 
            </>
            )}

        </Form.Group>

       <Row>
       </Row> 
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
                <Form.Label>Do you want intertest on your cash balance</Form.Label>
                <Form.Select className="form-control font-weight-normal" name="a_state" value={profitSharing} onChange={(e) => setProfitSharing(e.target.value)}>
                
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                
            </Form.Select>
            </Form.Group>

    </Row>
    <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-8">
            <Form.Label>Do you wish to subscribe to free of cost IVR / Web Service</Form.Label>
            <Form.Select className="form-control font-weight-normal" name="a_state" value={costIVR} onChange={(e) => setCostIVR(e.target.value)}>
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                             
            </Form.Select>
            <p>Central Depository Company (CDC) provides FREE OF COST services under CDC access whereby Sub-account holders / Investor Account holders can have real time access to their account related information.</p>

        </Form.Group>
        </Row>

        <Row className = "mb-3">
       <Form.Group controlId="incomeDetail" className="col col-sm-8">
        <Form.Label>Remittance Type</Form.Label>
 
        <Form.Select onChange={(e)=>setRemittanceBasis(e.target.value)} value={remittanceBasis} name= "remittance" className = "form-control">
                  <option value="">Select...</option>  
                  <option value="1">Non-Resident Pakistani (Repatriable)</option>              
                  <option value="2">Non-Resident Pakistani (Non-Repatriable)</option>
                  <option value="3">Foreigner Resident (Repatriable)</option>
                  <option value="4">Foreigner Resident (Non-Repatriable)</option>
                  <option value="5">Foreigner Non-Resident (Repatriable)</option>
                  <option value="6">Foreigner Non-Resident (Non-Repatriable)</option>
                  <option value="7">Resident Pakistani</option>
                </Form.Select>
        </Form.Group>
       </Row>

       
       <Row> 
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
                <Form.Label>Do you agree with Terms & Conditions</Form.Label>
                <Form.Select className="form-control font-weight-normal" name="a_state" value={termCondition} onChange={(e) => setTermCondition(yes)}>
      
                <option value="Y">Yes</option>
    
                
            </Form.Select>
            <p> Please  <a href = "#" onClick={showTermCOndition} >Click Here</a> to read and understand the Terms and Conditions, before acceptance and executing this form.</p>
            </Form.Group>

    </Row>

    <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-8">
            <Form.Label>Do you have Ukn </Form.Label>
            <Form.Select  className="form-control font-weight-normal" name="a_state" value={ukn} onChange={(e) => setUkn(e.target.value)}>
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
             
                
            </Form.Select>
            </Form.Group>
        </Row>
            { ukn === 'yes' && (
            <>
              <div>

              <Row>
                <Form.Group controlId="nomineeName" className="col col-sm-8">
                    <Form.Label>Ukn Number</Form.Label>
                    <input type="text" placeholder='Please type your ukn number '  onChange={(e) => setUknNo(e.target.value)} value={uknNo} name="nomineeName" className="form-control font-weight-normal" />
                    <p className='validateStyle'>{}</p>
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                <Form.Group controlId="formGridState" className="col col-sm-8">
                  <Form.Label>Are you Foreigner</Form.Label>
                 <Form.Select  className="form-control font-weight-normal" name="a_state" value={uknForeigner} onChange={(e) => setUknForeigner(e.target.value)}>
                      <option value="">Select...</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                 </Form.Select>
                </Form.Group>
                </Row>

                { uknForeigner === 'yes' && (
                 <>
                   <p>Please attach your ukn documnet</p>
                   <input type="file" onChange={handleUknDocument} />
                   <img className="col-sm-6" src={uknFile} /> 
                   
                 </>
               )}

                </div> 
            </>
            )}

    
    <div className="form-row row">
                <div className="col">
                    <input type="submit" value="Save"  onClick={handleSubmit}  className="btn btn-primary" />
                    <input type="button" value="Next" onClick={navigateToContacts}  className="btn btn-primary" />
                </div>
                </div>

                <div className="alert alert-success">
            {message}
           </div>
          <div className="alert alert-danger">
            {error}
        </div>
</form>

        </>
     
      
    )
    }
    export default AccountDetail;