import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";  
import Navbar from "./Navbar.js";
import SidebarNormal from "./SidebarNormal.js";  
import "react-datepicker/dist/react-datepicker.css";  
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './style.css'
import { Form, InputGroup, Row, Button } from 'react-bootstrap';


function BasicDetails(){

    const navigate = useNavigate();

    const navigateToContacts = () => {
    navigate('/ContactDetailNormal');
      };

    const [bdUinTypeCode, setBdUinTypeCode] = useState("");
    const [bdUinTypeDesc, setBdUinTypeDesc] = useState("");
    const [bdSalutation, setBdSalutation] = useState("");
    const [bdName, setBdName] = useState("");

    const [bdRelationship, setBdRelationship] = useState("");
    const [bdRelationshipName, setBdRelationshipName] = useState("");
    const [bdNationality, setBdNationality] = useState("");
    const [bdMaritalStatus, setBdMaritalStatus] = useState("");

    const [bdCnicLifeTimeExpiry, setBdCnicLifeTimeExpiry] = useState("");
    const [bdCnicDateOfBirth, setBdCnicDateOfBirth] = useState(new Date());
    const [bdCnicExpiryDate, setBdCnicExpiryDate] = useState(new Date());
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");


    let handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Inside handle Submit..");
        try {
            setMessage("");
            setError("");
          let res = await fetch("https://192.168.0.182:8085/normal/saveNormalBasicDetails", {
            method: "POST",
            body: JSON.stringify({
             

                userId:sessionStorage.getItem('userId'),
                bdUinTypeCode:bdUinTypeCode,
                bdUinTypeDesc:bdUinTypeDesc,
                bdSalutation:bdSalutation,
                bdName:bdName,
                bdRelationship:bdRelationship,
                bdRelationshipName:bdRelationshipName,
                bdNationality:bdNationality,
                bdMaritalStatus:bdMaritalStatus,
                bdCnicLifeTimeExpiry:bdCnicLifeTimeExpiry,
                bdCnicDateOfBirth:bdCnicDateOfBirth,
                status:bdMaritalStatus,
               
           
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
              },
              credentials: 'include',
            withCredentials: true
          });
          let resJson = await res.json();
          if (res.status === 200) {
            setBdUinTypeCode("");
            setBdUinTypeDesc("");
            setBdSalutation("");

            setBdName("");
            setBdRelationship("");
            setBdRelationshipName("");
            setBdNationality("");
           
            setBdMaritalStatus("");
            setBdCnicLifeTimeExpiry("");

            setBdCnicDateOfBirth("");
            setBdCnicExpiryDate("");
          
            setMessage("Form Submitted Successfully");
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }
      };
    return(
        <>
        <Row className="mb-3">
         <Navbar/>
    <div className="col-3">
  
        <br></br>
        <br></br>
    <SidebarNormal/>
    </div>
    <div className="col-9">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h3><b>Basic Details</b></h3>
        <form className="container mt-3 mb-3">
        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
            <Form.Label>Uin Type</Form.Label>
            <Form.Select className="form-control" name="a_state" value={bdUinTypeCode} onChange={(e) => setBdUinTypeCode(e.target.value)}>
                <option value="">Select...</option>
                <option value="CNIC">CNIC</option>
                <option value="SNIC">SNIC</option>
                <option value="NICOP">NICOP</option>
                <option value="POC">POC</option>
                
            </Form.Select>
            </Form.Group>
        
        </Row>
        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                <Form.Label>Uin</Form.Label>
                 <input type="text" onChange={(e) => setBdUinTypeDesc(e.target.value)} value={bdUinTypeDesc} name="applicationId" className="form-control" />
            </Form.Group>
        
        </Row>
    <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Mr./Mrs.</Form.Label>
            <Form.Select className="form-control" name="a_state" value={bdSalutation} onChange={(e) => setBdSalutation(e.target.value)}>
                <option value="Blank"></option>
                <option value="MR">MR.</option>
                <option value="MRS">MRS.</option>
                <option value="MS">MS.</option>
                
            </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>Name</Form.Label>
            <input type="text" onChange={(e) => setBdName(e.target.value)} value={bdName} name="applicationId" className="form-control" />
        </Form.Group>

    </Row>
    <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Father/Husband</Form.Label>
            <Form.Select className="form-control" name="a_state" value={bdRelationship} onChange={(e) => setBdRelationship(e.target.value)}>
                <option value="">Select...</option>
                <option value="F">Father</option>
                <option value="H">Husband</option>
                
            </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>Father/Husband Name</Form.Label>
            <input type="text" onChange={(e) => setBdRelationshipName(e.target.value)} value={bdRelationshipName} name="applicationId" className="form-control" />
        </Form.Group>

    </Row>
    <Row className="mb-3">

           <Form.Group controlId="formBasicEmail" className="col col-sm-4">
                <Form.Label>Date of Birth</Form.Label>
                <DatePicker className="form-control" selected={bdCnicDateOfBirth} onChange={(date) =>   setBdCnicDateOfBirth(date)} dateFormat="dd/MM/yyyy"   />
                
            </Form.Group>

            <Form.Group controlId="formBasicEmail" className="col col-sm-8">
                <Form.Label>Nationality</Form.Label>
                <Form.Select className="form-control" name="a_state" value={bdNationality} onChange={(e) => setBdNationality(e.target.value)}>
                <option value="">Select...</option>
                <option value="PAK">Pakistan</option>
                <option value="AS">American Samoa</option>
                <option value="GI">Gibraltar</option>
                <option value="TH">Thailand</option>
                <option value="GE">Georgia</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="SE">Sweden</option>
                <option value="SD">Sudan</option>
                <option value="TN">Tunisia</option>
                <option value="ER">Eritrea</option>
                <option value="SL">Sierra Leone</option>
                <option value="EC">Ecuador</option>
                <option value="DO">Dominican Republic</option>
                <option value="DJ">Djibouti</option>
                <option value="CY">Cyprus</option>
                <option value="CU">Cuba</option>
                <option value="RU">Russian Federation</option>
                <option value="SB">Solomon Islands</option>
                <option value="QA">Qatar</option>
                <option value="CR">Costa Rica</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="OM">Oman</option>
                <option value="PG">Papua New Guinea</option>
                <option value="NI">Nicaragua</option>
                <option value="AN">Netherlands Antilles</option>
                <option value="NZ">New Zealand</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="BG">Bulgaria</option>
                <option value="AF">Afghanistan</option>
                <option value="DZ">Algeria</option>
                <option value="KI">Kiribati</option>
                <option value="KP">Korea, North</option>
                <option value="AO">Angola</option>
                <option value="LV">Latvia</option>
                <option value="KW">Kuwait</option>
                <option value="LA">Lao Peoples Democratic Republic</option>
                <option value="LB">Lebanon</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libyan Arab Jamahiriya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="MK">Macedonia</option>
                <option value="BD">Bangladesh</option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="BB">Barbados</option>
                <option value="MY">Malaysia</option>
                <option value="BY">Belarus</option>
                <option value="MV">Maldives</option>
                <option value="MU">Mauritius</option>
                <option value="BT">Bhutan</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="BV">Bouvet Island</option>
                <option value="MA">Morocco</option>
                <option value="NA">Namibia</option>
                <option value="CN">China</option>
                <option value="UA">Ukraine</option>
                <option value="GT">Guatemala</option>
                <option value="GY">Guyana</option>
                <option value="VE">Venezuela</option>
                <option value="ZM">Zambia</option>
                <option value="JM">Jamaica</option>
                <option value="IQ">Iraq</option>
                <option value="JO">Jordan</option>
                <option value="CX">Christmas Island</option>
                <option value="TD">Chad</option>
                <option value="BF">Burkina Faso</option>
                <option value="BE">Belgium</option>
                <option value="AT">Austria</option>
                <option value="AU">Australia</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="BJ">Benin</option>
                <option value="CA">Canada</option>
                <option value="CF">Central African Republic</option>
                <option value="GA">Gabon</option>
                <option value="CC">Cocos Kelling Island</option>
                <option value="CG">Congo - Republic of</option>
                <option value="CK">Cook Islands</option>
                <option value="DM">Dominica</option>
                <option value="FO">Faroe Islands</option>
                <option value="FI">Finland</option>
                <option value="MQ">Martinique</option>
                <option value="GR">Greece</option>
                <option value="GU">Guam</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="NR">Nauru</option>
                <option value="PW">Palau</option>
                <option value="VC">Saint Vi-Grenadines</option>
                <option value="TW">Taiwan</option>
                <option value="TK">Tokelau</option>
                <option value="UG">Uganda</option>
                <option value="GB">United Kingdom</option>
                <option value="NO">Norway</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PT">Portugal</option>
                <option value="RO">Romania</option>
                <option value="LC">Saint Lucia</option>
                <option value="SN">Senegal</option>
                <option value="CH">Switzerland</option>
                <option value="YE">Yemen</option>
                <option value="VG">Virgin Islands - GB</option>
                <option value="AE">United Arab Emirates</option>
                <option value="TV">Tuvalu</option>
                <option value="HN">Honduras</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="FJ">Fiji</option>
                <option value="ET">Ethiopia</option>
                <option value="HT">Haiti</option>
                <option value="TO">Tonga</option>
                <option value="TJ">Tajikistan</option>
                <option value="GS">South Georgia - South Sandwich Islands</option>
                <option value="SC">Seychelles</option>
                <option value="SA">Saudi Arabia</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="TP">East Timor</option>
                <option value="DK">Denmark</option>
                <option value="CZ">Czech Republic</option> 
                <option value="RW">Rwanda</option>
                <option value="EG">Egypt</option>
                <option value="PA">Panama</option>
                <option value="CL">Chile</option>
                <option value="KY">Cayman Islands</option>
                <option value="BI">Burundi</option>
                <option value="MM">Myanmar</option>
                <option value="MZ">Mozambique</option>
                <option value="AL">Albania</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="AI">Anguilla</option>
                <option value="AR">Argentina</option>
                <option value="AW">Aruba</option>
                <option value="MO">Macau</option>
                <option value="LS">Lesotho</option>
                <option value="MT">Malta</option>
                <option value="ML">Mali</option>
                <option value="MH">Marshall Islands</option>
                <option value="BZ">Belize</option>
                <option value="BM">Bermuda</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="MN">Mongolia</option>
                <option value="NP">Nepal</option>
                <option value="TR">Turkey</option>
                <option value="GN">Guinea</option>
                <option value="KZ">Kazakhstan</option>
                <option value="HK">Hong Kong</option>
                <option value="VN">Vietnam</option>
                <option value="IL">Israel</option>
                <option value="IR">Iran Islamic Republic of</option>
                <option value="HU">Hungary</option>
                <option value="ZW">Zimbabwe</option>
                <option value="VU">Vanuatu</option>
                <option value="JP">Japan</option>
                <option value="AD">Andorra</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="GL">Greenland</option>
                <option value="GP">Guadeloupe</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IE">Ireland</option>
                <option value="MC">Monaco</option>
                <option value="FM">MicronesiaFederated</option>
                <option value="LU">Luxembourg</option>
                <option value="IT">Italy</option>
                <option value="DE">Germany</option>
                <option value="GD">Grenada</option>
                <option value="TC">Turks-Caicos Islands</option>
                <option value="UM">US Minor Outlying</option>
                <option value="KE">Kenya</option>
                <option value="YU">Yugoslavia</option>
                <option value="FK">Falkland Islands Malvinas</option>
                <option value="GH">Ghana</option>
                <option value="EE">Estonia</option>
                <option value="SZ">Swaziland</option>
                <option value="ZA">South Africa</option>
                <option value="HR">Croatia Hrvatska</option>
                <option value="SO">Somalia</option>
                <option value="CD">Congo-Brazzaville</option>
                <option value="KM">Comoros</option>
                <option value="PL">Poland</option>
                <option value="NG">Nigeria</option>
                <option value="CV">Cape Verde</option>
                <option value="NC">New Caledonia</option>
                <option value="AM">Armenia</option>
                <option value="MR">Mauritania</option>
                <option value="BO">Bolivia</option>
                <option value="BR">Brazil</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="SV">El Salvador</option>
                <option value="SR">Suriname</option>
                <option value="GM">Gambia</option>
                <option value="IS">Iceland</option>
                <option value="CO">Colombia</option>
                <option value="FR">France</option>
                <option value="YT">Mayotte</option>
                <option value="CI">Ivory Coast</option>
                <option value="NL">Netherlands</option>
                <option value="RE">Reunion</option>
                <option value="KN">Saint Kitts</option>
                <option value="NF">Norfolk Island</option>
                <option value="NU">Niue</option>
                <option value="VA">Vatican City State</option>
                <option value="VI">Virgin Islands - US</option>
                <option value="SM">San Marino</option>
                <option value="SI">Slovenia</option>
                <option value="WF">Wallis-Futuna Island</option>
                <option value="UZ">Uzbekistan</option>
                <option value="GF">French Guiana</option>
                <option value="TF">French Southern Terr</option>
                <option value="PF">French Polynesia</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="MS">Montserrat</option>
                <option value="HM">HeardMcDonald Island</option>
                <option value="MX">Mexico</option>
                <option value="NE">Niger</option>
                <option value="SJ">Svalbard-Jan Mayen</option>
                <option value="EH">Western Sahara</option>
                <option value="MP">North Mariana Island</option>
                <option value="PR">Puerto Rico</option>
                <option value="WS">Samoa</option>
                <option value="SG">Singapore</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="TM">Turkmenistan</option>
                <option value="TG">Togo</option>
                <option value="TZ">Tanzania</option>
                <option value="KR">Korea, South</option>
                <option value="US">United States</option>
                <option value="UY">Uruguay</option>
                <option value="SAT">SAMAO</option>
                
            </Form.Select>
            </Form.Group>      
        </Row>

        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-4">
                <Form.Label>Marital Status</Form.Label>
                <Form.Select className="form-control" name="a_state" value={bdMaritalStatus} onChange={(e) => setBdMaritalStatus(e.target.value)}>
                
                <option value="">Select...</option>
                <option value="S">Single</option>
                <option value="M">Married</option>
                
            </Form.Select>
            </Form.Group>

             <Form.Group controlId="formGridState" className="col col-sm-8">
            <Form.Label>CNIC Lifetime Expiry</Form.Label>
            <Form.Select className="form-control" name="a_state" value={bdCnicLifeTimeExpiry} onChange={(e) => setBdCnicLifeTimeExpiry(e.target.value)}>
                <option value="">Select...</option>
                <option value="Y">Life Time CNIC is available</option>
                <option value="NULL">Life Time CNIC is not available and expiry date is provided </option>
                
            </Form.Select>
        </Form.Group>
        
        </Row>
        <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-4">
                <Form.Label>CNIC Expiry Date</Form.Label>
                <DatePicker className="form-control" selected={bdCnicExpiryDate} onChange={(date) =>   setBdCnicExpiryDate(date)} dateFormat="dd/MM/yyyy"   />
                
            </Form.Group>
    </Row>
    <Row className="mb-3">
          
        
        </Row>
    
    <div className="form-row row">
                <div className="col">
                    <input type="submit" onClick={handleSubmit}  className="btn btn-primary" />
                    <input type="button" value="Next" onClick={navigateToContacts}  className="btn btn-primary" />
                </div>
                </div>

                <div className="alert alert-success">
            {message}
           </div>
          <div className="alert alert-danger">
            {error}
        </div>
</form>
</div>
</Row>
        </>
     
      
    )
    }
    export default BasicDetails;