import React, { useEffect, useState } from 'react';

import Style from "./loginStyle.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes, Route, useParams, useNavigate} from 'react-router-dom';
import { Form, InputGroup, Row, Button, Card } from 'react-bootstrap';
import DatePicker from "react-datepicker";  
import BasicDetails from './BasicDetails.js'
import slabDoc from './Reports/Mobile Exemption affidavit  undertaking - mobile number of family member.docx' 


  

function SignUp(){
   
   

    const navigate = useNavigate();

    const navigateToLogin = () => {
       
        navigate('/Login');
      };
    
      const [name , setName] = useState("");  
      const [email , setEmail] = useState("");
      const [residentialStatus , setResidentialStatus] = useState("");
      const [idType , setIdType] = useState("");
      const [idNumber , setIdNumber] = useState("");
      const [idIssueDate , setIdIssueDate] = useState(new Date());
      const [mobileNumber , setMobileNumber] = useState("");
      const [mobileRegistered , setMobileRegistered] = useState("");
      const [ibanNo , setIbanNo] = useState();
      const [cnicFrontFile , setCnicFrontFile] = useState();
      const [cnicBackFile , setCnicBackFile] = useState();
      const [ibanFile , setIbanFile] = useState();
      const [relationshipProof ,setRelationshipProof] = useState('');
      const [authorization , setAuthorization] = useState('');
      const [relationShip , setRelationShip] = useState('');
      const [bankName , setBankName] = useState('');
      const [alreadyHaveAccount , setAlreadyHaveAccount] = useState('');
      const [mpName , setMpName] = useState('');
      const [mpIdCard , setMpIdCard]=useState('');
      const [message, setMessage] = useState("");
      const [error, setError] = useState("");
      const [validateError , setValidateError] = useState('');
      const [nameError , setNameError] = useState("");  
      const [emailError , setEmailError] = useState("");
      const [idError , setIdError] = useState("");
      const [ibanError , setIbanError] = useState("");
      const [mobileError , setMobileError] =useState("");
      const [ewalletNo , setEwalletNo] = useState("");
      const [ewalletProof ,  setEwalletProof] = useState("");
      const [eWalletName , setEWalletName] = useState("");
      const [eWalletOtherName , setEWalletOtherName] = useState("");
      const [showHide , setShowHide] =useState(''); 
      const [showForm , setShowForm] = useState('');

      const[cnicFrontSize , setCnicFrontSize] = useState('');
      const[cnicBackSize , setCnicBackSize] = useState('');
      const[ibanSize , setIbanSize] = useState('');
      const[authorizationSize , setAuthorizationSize] = useState('');
      const[relationSize , setRelationSize] = useState('');
      const[ewalletSize , setEwalletSize] = useState('');


      function nameVal(e){

            if(name != ""){
                let namePattern= /^[a-zA-Z ]*$/;
               if( !namePattern.test(name) )
               {
                setNameError("Enter valid name")
               }
               else{
                setNameError("")
               }
            }
       
            setName(e.target.value)
      }

    function handleChange(e) {
       // console.log(e.target.files);
        let files = e.target.files;
        let fileSize = e.target.files[0].size;
        let fileSizeInKB = (fileSize/1024); 
        setCnicFrontSize(fileSizeInKB);
        console.warn("Data File", files)
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload=(e)=>{
            console.warn("img data" , e.target.result);
            setCnicFrontFile(e.target.result);
        }
       // setCnicFrontFile(URL.createObjectURL(e.target.files[0]));
        
    }
    function handleChange2(e) {
    
        let cnicBack = e.target.files;
        let fileSize = e.target.files[0].size;
        let fileSizeInKB = (fileSize/1024); 
        setCnicBackSize(fileSizeInKB);
        console.log("in kb file size is " + fileSizeInKB);
        let cnicReader = new FileReader();
        cnicReader.readAsDataURL(cnicBack[0]);
        cnicReader.onload=(e)=>{
            setCnicBackFile(e.target.result);
        }
        //setCnicBackFile(URL.createObjectURL(e.target.files[0]));
        
    }
    function handleChange3(e) {
  
        let ibanFile = e.target.files;
        let fileSize = e.target.files[0].size;
        let fileSizeInKB = (fileSize/1024); 
        setIbanSize(fileSizeInKB);
        let ibanReader = new FileReader();
        ibanReader.readAsDataURL(ibanFile[0]);
        ibanReader.onload=(e)=>{
            setIbanFile(e.target.result);
        }
        //setIbanFile(URL.createObjectURL(e.target.files[0]));
        
    }
    function handleChange6(e) {
  
        let ewalletProof = e.target.files;
        let fileSize = e.target.files[0].size;
        let fileSizeInKB = (fileSize/1024); 
        setEwalletSize(fileSizeInKB);
        let ewalletReader = new FileReader();
        ewalletReader.readAsDataURL(ewalletProof[0]);
        ewalletReader.onload=(e)=>{
            setEwalletProof(e.target.result);
        }
    
        
    }
    function handleChange4(e) {

        let relationshipFile  = e.target.files;
        let fileSize = e.target.files[0].size;
        let fileSizeInKB = (fileSize/1024); 
        setRelationSize(fileSizeInKB);
        let relationshipReader = new FileReader();
        relationshipReader.readAsDataURL(relationshipFile[0]);
        relationshipReader.onload=(e)=>{
            setRelationshipProof(e.target.result);
        }
      //  setRelationshipProof(URL.createObjectURL(e.target.files[0]));
        
    }
    function handleChange5(e) {
    
        let authorizeFile  = e.target.files;
        let fileSize = e.target.files[0].size;
        let fileSizeInKB = (fileSize/1024); 
        setAuthorizationSize(fileSizeInKB);
        let authorizeReader = new FileReader();
        authorizeReader.readAsDataURL(authorizeFile[0]);
        authorizeReader.onload=(e)=>{
            setAuthorization(e.target.result);
        }

       // setAuthorization(URL.createObjectURL(e.target.files[0]));
        
    }
    function handleShowHide  (e) {
        const getOption = e.target.value;

        setShowHide(getOption);
        setMobileRegistered(getOption);
       }

    function handleShowForm(e){
        const getOption = e.target.value;
        setShowForm(getOption);
        setAlreadyHaveAccount(getOption);
    }

    function showAuthorization(){
        const myWindow = window.open(slabDoc,'fullscreen=yes');
    }

    
       function validateForm (){
        setNameError("");
        setEmailError("");
        setIdError("");
        setIbanError("");
        setMobileError("");
     if(name =="") {
        return setError("Name should not be null")
        }
        if(name != ""){
            let  namePattern= /^[a-zA-Z ]*$/;
           if( !namePattern.test(name) )
           {
           return setError("Enter valid name")
           }
        }
        if(email =="") {
           return setError("Email should not be null")
        }
        if(email != ""){
            let emailPattern=  /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/;
           if( !emailPattern.test(email) )
           {
            return setError("Enter valid Email")
           }
        }

        if(idNumber=="")
        {
            return  setError("Id Card should not be null")
            
        }
        if(idNumber != ""){
            let idPattern=  /^[0-9]{5}[0-9]{7}[0-9]$/;
            if(!idPattern.test(idNumber))
           {
            return  setError("Enter valid Identity Card Number ")
           }
        }


        if(mobileNumber == ""){
            return setError("Mobile number should not be null") 
        }
          
        if(mobileNumber != ""){
            let mobilePattern=  /^[0-9]+$/;       
            if(mobileNumber.length>13){        
                return setError("Enter valid mobile number") 
        }
        if(!mobilePattern.test(mobileNumber))
            {
                return setError("Enter valid mobile number") 
            }
        }

        if(ibanNo == "")
        {
            return  setError("Iban should not be null")
        }
        if(ibanNo != "")
        {
            let ibanPattern= /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/; 
           if( !ibanPattern.test(ibanNo) )
           {
            return  setError("Enter valid Iban")
           }
        }

        if(bankName == "")
        {
            return setError("Bank name should not be null");
        }

        if(bankName != ""){
            let  namePattern= /^[a-zA-Z ]*$/;
           if( !namePattern.test(bankName) )
           {
           return setError("Enter valid bank name")
           }
        }

        if(eWalletOtherName != ""){
            let  namePattern= /^[a-zA-Z ]*$/;
           if( !namePattern.test(eWalletOtherName) )
           {
           return setError("Enter valid bank name")
           }
        }

        if(mpName != ""){
            let  namePattern= /^[a-zA-Z ]*$/;
           if( !namePattern.test(mpName) )
           {
           return setError("Enter valid name of selected person")
           }
        }

        if(mpIdCard != ""){
            let idPattern=  /^[0-9]{5}[0-9]{7}[0-9]$/;
            if(!idPattern.test(mpIdCard))
           {
            return  setError("Enter valid Identity card number of selected person ")
           }
        }

        if(cnicFrontSize > 600){
            return  setError("Cnic front file size should not be greater than 600KB") 
        }

        if(cnicBackSize > 600){
            return  setError("Cnic back file size should not be greater than 600KB") 
        }

        if(ibanSize > 600){
            return  setError("Iban file size should not be greater than 600KB") 
        }

        if(authorizationSize > 600){
            return  setError("Authorization file size should not be greater than 600KB") 
        }

        if(relationSize > 600){
            return  setError("Relationship file size should not be greater than 600KB") 
        }

        if(ewalletSize > 600){
            return  setError("Ewallet file size should not be greater than 600KB") 
        }

            return "Ok"; 
       }

    let handleSubmit = async (e) => {
        e.preventDefault();
        const result=validateForm();
        console.log("Inside handle Submit..");
        if(result === "Ok")
        {
        try {
            setMessage("");
            setError("");
        let res = await fetch("https://sahulat.fsedge.com:8085/sahulat/signUp", {
   //   let res = await fetch("http://192.168.0.182:8085/sahulat/signUp", {
            method: "POST",
            body: JSON.stringify({
                userName: name,
                email: email,
                residentialStatus: residentialStatus,
                uinIdentificationType: idType,
                uin: idNumber,
                nicIssueDate: idIssueDate,
                mobile: mobileNumber,
                mobileRelationship: mobileRegistered,
                mobilePersonRelationship:relationShip,
                closeFamilyMemberName:mpName,
                closeFamilyMemberIdCard:mpIdCard,
                bankName:bankName,
                alreadyHaveAccount:alreadyHaveAccount,
                iban: ibanNo,
                cnicFront: cnicFrontFile,
                cnicBack:cnicBackFile,
                ibanFile:ibanFile,
                relationFile:relationshipProof,
                authorizeFile:authorization,
                cnicRelative:mpIdCard,
                eWalletNumber:ewalletNo,
                ewalletFile:ewalletProof,
                eWalletName:eWalletName,
                eWalletOtherName:eWalletOtherName,

            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
              },
              credentials: 'include',
            withCredentials: true
          });
          let resJson = await res.json();
          if (res.status === 200) {
          
            setMessage(resJson.desc);
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }
        }
      };
   

    return(
        <>
             <br></br>
             <br></br>
             <br></br>
            
          <div className="registration-form">
            <center>
              <h2 className="signup-heading">Registration Form</h2>     
              </center>
           <form className="container mt-3 mb-3">

           <Row className="mb-3">
            <Form.Group controlId="incomeDetail" className="col col-sm-12">
            <Form.Label className="signUp-Label">Do you have already trading account with any brokerage firm?</Form.Label>
            <Form.Select className="form-control" name="cnicLifeTime" value={alreadyHaveAccount} onChange={(e) => handleShowForm(e)}>            
                <option value="">Select...</option>   
                <option value="yes">Yes</option> 
                <option value="no">No</option>   
                         
            </Form.Select>
            </Form.Group>
            </Row>
        { showForm === 'no' && (
        <div>
            <Row className="mb-3">
            <Form.Group controlId="name" className="col col-sm-6">
                <Form.Label className="signUp-Label">Full Name of Applicant</Form.Label>
                <p className="sign-paragraph">As per valid CNIC</p>
                <input  type="text" 
                        onChange={(e) => setName(e.target.value)} 
                        value={name} name="nomineeName" 
                        className="form-control" 
                        placeholder='Enter Here' 
                        pattern="[A-Za-z]{50}" 
                        title="Number and Special character not allowed" 
                        required/>
              <p className='validateStyle'>{nameError}</p>
                </Form.Group>
                <Form.Group controlId="nomineeName" className="col col-sm-6">
                <Form.Label className="signUp-Label">Enter your Email Address</Form.Label>
                <p className="sign-paragraph">Email is the primary mode of communication for correspondence.</p>
                <input type="text" placeholder='email@address.com' onChange={(e) => setEmail(e.target.value)} value={email} name="nomineeName" className="form-control" required/>
                <p className='validateStyle'>{emailError}</p>
                </Form.Group>
            </Row>

            <Row className="mb-3">
            <Form.Group controlId="name" className="col col-sm-6">
                <Form.Label className="signUp-Label">Residential Status</Form.Label>
 
            <Form.Select className="form-control" name="a_state" value={residentialStatus} onChange={(e) => setResidentialStatus(e.target.value)}>
                <option value="">Select...</option>
                <option value="01">Pakistani Resident</option>
                <option value="02">Foreign Resident</option>
           
                
            </Form.Select>
                </Form.Group>
                <Form.Group controlId="nomineeName" className="col col-sm-6">
                <Form.Label className="signUp-Label">ID Type</Form.Label>

             <Form.Select className="form-control" name="a_state" value={idType} onChange={(e) => setIdType(e.target.value)}>
                <option value="">Select...</option>
                { residentialStatus === '01' && (
                <>
                <option value="CNIC">CNIC</option>
                <option value="SNIC">SNIC</option>
                </>
                )}

                { residentialStatus === '02' && (
                <>
                <option value="NICOP">NICOP</option>
                <option value="POC">POC</option>
                </>  
                 )} 

            </Form.Select>
                </Form.Group>
            </Row>

            <Row className="mb-3">
           
                <Form.Group controlId="uin" className="col col-sm-6">
                <Form.Label className="signUp-Label">Identity Card Number</Form.Label>
                <p className='sign-paragraph'>i.e. CNIC</p>
                <input type="text" placeholder='0000000000000' onChange={(e) => setIdNumber(e.target.value)} value={idNumber} name="uin" className="form-control" required />
                <p className='validateStyle'>{idError}</p>
                </Form.Group>
            
            <Form.Group controlId="cnicExpiryDate" className="col col-sm-6">
                <Form.Label className="signUp-Label">Identity Card Date of Issue</Form.Label>
                <p className="sign-paragraph">As per valid CNIC</p>
             
                <input type="date" onChange={(e) => setIdIssueDate(e.target.value)} dateFormat="dd/MMM/yyyy"  value={idIssueDate} className="form-control" />

                </Form.Group>
                </Row>
          

            <Row className="mb-3">
           
            <Form.Group controlId="incomeDetail" className="col col-sm-6">
            <Form.Label className="signUp-Label">Front Side</Form.Label>
            <p className="sign-paragraph">  Supported Formats: JPG, PNG, PDF; Max File Size: 600 KB </p>
            <p className="sign-paragraph">  Do not upload password protected files </p>
            <input type="file"  onChange={handleChange} />
            <img className="col-sm-6" src={cnicFrontFile} />
            </Form.Group>

         
            <Form.Group controlId="incomeDetail" className="col col-sm-6">
            <Form.Label className="signUp-Label">Back Side</Form.Label>
            <p className="sign-paragraph">  Supported Formats: JPG, PNG, PDF; Max File Size: 600 KB </p>
            <p className="sign-paragraph">  Do not upload password protected files </p>
            <input type="file" onChange={handleChange2} />
            <img className="col-sm-6" src={cnicBackFile} />
            </Form.Group>
            </Row>

            <Row className="mb-3">
            <Form.Group controlId="incomeDetail" className="col col-sm-6">
                <Form.Label className="signUp-Label">Enter your mobile number</Form.Label>
                <input type="text" placeholder='Mobile number format should be 03XXXXXXXXX' onChange={(e)=>setMobileNumber(e.target.value)} value={mobileNumber} name="nomineeMobile" className="form-control"/>
                <p className='validateStyle'>{mobileError}</p>
            </Form.Group>

            <Form.Group controlId="cnicLifeTime" className="col col-sm-6">
                <Form.Label className="signUp-Label">Is your mobile number registered in your name</Form.Label>
                <Form.Select className="form-control" name="cnicLifeTime" value={mobileRegistered} onChange={(e) => handleShowHide(e)}>            
                <option value="">Select...</option>   
                <option value="yes">Yes</option> 
                <option value="no">No</option>   
                         
            </Form.Select>
                </Form.Group>
            </Row>

            { mobileRegistered === 'no' && (
           
           <div>
           <Row className="mb-3">
         
           <Form.Group controlId="incomeDetail" className="col col-sm-12">
                    <Form.Label>Relationship with the person whose mobile number is provided</Form.Label>
                    <Form.Select className="form-control" name="nomineeRelation" value={relationShip} onChange={(e) => setRelationShip(e.target.value)}>            
                    <option value="">Select...</option>
                    
                    <option value="02">Father</option>
                    <option value="03">Mother</option>
                    <option value="04">Son</option>
                    <option value="05">Daughter</option>
                    <option value="06">Husband</option>
                    <option value="07">Company</option>              
                </Form.Select>
                    </Form.Group>
           </Row>
           <Row className="mb-3">
           <Form.Group controlId="incomeDetail" className="col col-sm-6">
           <Form.Label className="signUp-Label">Full name of person selected above</Form.Label>
           <p className="sign-paragraph">As per valid CNIC</p>
           <input type="text" placeholder='Enter Here' onChange={(e)=>setMpName(e.target.value)} value={mpName} name="nomineeMobile" className="form-control"/>

           </Form.Group>

           <Form.Group controlId="incomeDetail" className="col col-sm-6">
               <Form.Label className="signUp-Label">Identity Card of person selected above</Form.Label>
               <p className="sign-paragraph">i.e. CNIC/NICOP</p>
               <input type="text" placeholder='0000000000000' onChange={(e)=>setMpIdCard(e.target.value)} value={mpIdCard} name="nomineeMobile" className="form-control"/>
           </Form.Group>
           </Row>

           <Row>
           <Form.Group controlId="incomeDetail" className="col col-sm-6">
           <Form.Label className="signUp-Label">Proof of relationship selected above </Form.Label>
           <p className="sign-paragraph">I.e. CNIC, Family Registration Certificate (FRC)  or any other acceptable document              </p>
           <p className="sign-paragraph">  Supported Formats: JPG, PNG, PDF; Max File Size: 600 KB </p>
            <p className="sign-paragraph">  Do not upload password protected files </p>
           <br></br>
           <input type="file"  onChange={handleChange4} />
           <img className="col-sm-6" src={relationshipProof} />
           </Form.Group>
          
           <Form.Group controlId="incomeDetail" className="col col-sm-6">
           <Form.Label className="signUp-Label">Attach signed copy of authorization from person selected above  </Form.Label>
           <p className="sign-paragraph">  Please download this <a href = "#" target="_blank" onClick={showAuthorization} >authorization</a>, and upload it here  </p>
           <p className="sign-paragraph">  Supported Formats: JPG, PNG, PDF; Max File Size: 600 KB </p>
            <p className="sign-paragraph">  Do not upload password protected files </p>
           <br></br>
           <input type="file"  onChange={handleChange5} />
           <img className="col-sm-6" src={authorization} />
           </Form.Group>
           </Row>
           </div>
           )
            }

           
            <Row className="mb-3">
            <Form.Group controlId="incomeDetail" className="col col-sm-6">
                <Form.Label className="signUp-Label">International Bank Account Number (IBAN) Number</Form.Label>
                <p className="sign-paragraph">Enter your  24 digits account number as mentioned in your cheque book</p>
                <p><hr></hr></p>
                <input type="text" placeholder='PK24 MUCB **** **** **** ****' onChange={(e)=>setIbanNo(e.target.value)} value={ibanNo} name="nomineeMobile" className="form-control"/>
                <p className='validateStyle'>{ibanError}</p>
            </Form.Group>

            
            <Form.Group controlId="incomeDetail" className="col col-sm-6">
            <Form.Label className="signUp-Label">Provide the proof of IBAN </Form.Label>
            <p className="sign-paragraph">I.e. Copy of cheque  or any other acceptable document  </p>
            <p className="sign-paragraph">  Supported Formats: JPG, PNG, PDF; Max File Size: 600 KB </p>
            <p className="sign-paragraph">  Do not upload password protected files </p>
            
            <input type="file" onChange={handleChange3} />
            <img className="col-sm-6" src={ibanFile} />
            </Form.Group>
           
            </Row>

            <Row className="mb-3">

            <Form.Group controlId="incomeDetail" className="col col-sm-6">
                <Form.Label className="signUp-Label">Bank Name</Form.Label>
                <input type="text" placeholder='Enter here' onChange={(e)=>setBankName(e.target.value)} value={bankName} name="nomineeMobile" className="form-control"/>
            </Form.Group>

            <Form.Group controlId="incomeDetail" className="col col-sm-6">
                    <Form.Label className="signUp-Label">E-Wallet Provider</Form.Label>
                    <Form.Select className="form-control" name="ewalletName" value={eWalletName} onChange={(e) => setEWalletName(e.target.value)}>            
                    <option value="">Select...</option>
                    
                    <option value="1">Askari Digital Wallet</option>
                    <option value="2">Upaisa</option>
                    <option value="3">Zindigi</option>
                    <option value="4">JazzCash</option>
                    <option value="5">SADAPAY</option>
                    <option value="6">NAYAPAY</option>
                    <option value="7">UBL Digital Wallet</option>
                    <option value="8">Easypaisa</option>
                    <option value="9">Bank Aflalah Wallet</option>
                    <option value="10">HBL Kconnect</option>
                    <option value="11"> OTHERS</option>
                      
                </Form.Select>
                </Form.Group>

            </Row>
            { eWalletName === '11' && (
           
           <div>
           <Row className="mb-3">
           <Form.Group controlId="incomeDetail" className="col col-sm-6">
                <Form.Label className="signUp-Label">Other E-Wallet Provider</Form.Label>
                <input type="text" placeholder='Enter here' onChange={(e)=>setEWalletOtherName(e.target.value)} value={eWalletOtherName} name="nomineeMobile" className="form-control"/>
            </Form.Group>
          
           </Row>
           </div>
           )
            }

            <Row className="mb-3">
            <Form.Group controlId="incomeDetail" className="col col-sm-6">
                <Form.Label className="signUp-Label">E Wallet Number</Form.Label>
                <p className="sign-paragraph">Enter the Account Number for E Wallet</p>
                <p><hr></hr></p>
                <input type="text" placeholder='03** *******' onChange={(e)=>setEwalletNo(e.target.value)} value={ewalletNo} name="ewallet" className="form-control"/>
             
            </Form.Group>

            
            <Form.Group controlId="incomeDetail" className="col col-sm-6">
            <Form.Label className="signUp-Label">Proof of E Wallet </Form.Label>
            <p className="sign-paragraph">I.e. Screenshot of your E-wallet account number or any other authentic document </p>
            <p className="sign-paragraph">   </p>
            <p className="sign-paragraph"> <hr></hr> </p>
            
            <input type="file" onChange={handleChange6} />
            <img className="col-sm-6" src={ewalletProof} />
            </Form.Group>
           
            </Row>

         
 
            <div className="form-row row">
           
            <div className=" alert-danger">
            {error}
            </div >
                <div className="col">
                    <input type="submit" onClick={handleSubmit} className="btn btn-primary signup-btn" />
                    <input type="button" value="Login" onClick={navigateToLogin} className="btn btn-primary signup-btn" />
              
                </div>
                </div>

                <div className=" alert-success">
            {message}
           </div>
        
          <div className=" alert-danger"><p>Sahulat account is offered to only individual resident Pakistani Customers, who have no existing Sahulat or normal account with Foundation Securities or any other broker.</p></div>
          </div>
          )}
              { showForm === 'yes' && (
                <div>
                      <div className=" alert-danger"><p>Sahulat account is offered to those Customers, who have no existing Sahulat or normal trading account with any broker.</p></div>
                </div>
              )}
        </form>
        </div>

   </>

        
    )
}
export default SignUp;