import React from 'react'
import SidebarStyle from './SidebarStyle.css';
import App from '../App.css'
import {SidebarData}  from './SidebarNormalData.js';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    
    Link
  } from "react-router-dom";
function Sidebar(){
    return(
        
          <div className="sidebar">
          <ul className="sidebarList">
         
           {SidebarData.map((val,key)=>{
            return(
               
                <Link className="row" to={val.link} >
                 <div id="icon">{val.icon} </div>
                <div id="title">{val.title}</div>
                </Link>
            );
           })}
            
           </ul>
          </div>

        
    );
}

export default Sidebar;