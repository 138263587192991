import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from "./Navbar.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, InputGroup, Row, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";  
import SidebarNormal from "./SidebarNormal.js";  
function AttorneyDetails(){

    const navigate = useNavigate();

    const navigateToNominee = () => {
    navigate('/OtherDetails');
      };

    const [attorneyAddress1, setAttorneyAddress1] = useState("");
    const [attorneyAddress2, setAttorneyAddress2] = useState("");
    const [attorneyAddress3, setAttorneyAddress3] = useState("");
    const [attorneyCountry, setAttorneyCountry] = useState("");
    const [attorneyProvince, setAttorneyProvince] = useState("");
    const [attorneyOtherProvince, setAttorneyOtherProvince] = useState("");
    const [attorneyCity, setAttorneyCity] = useState("");
    const [attorneyOtherCity, setAttorneyOtherCity] = useState("");
    const [attorneyPhoneNo, setAttorneyPhoneNo] = useState("");
    const [attorneyResidence, setAttorneyResidence] = useState("");
    const [attorneyFax, setAttorneyFax] = useState("");
    const [attorneyName, setAttorneyName] = useState("");
    const [attorneySalutation, setAttorneySalutation] = useState("");
    const [attorneyMobileNo, setAttorneyMobileNo] = useState("");
    const [attorneyEmail, setAttorneyEmail] = useState("");
    const [attorneyCnicExpiry, setAttorneyCnicExpiry] = useState("");
    const [attorneyCnicLifeTime, setAttorneyCnicLifeTime] = useState("");
    const [attorneyUinType, setAttorneyUinType] = useState("");
    const [attorneyUIN, setAttorneyUIN] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Inside handle Submit..");
        try {
    //    let res = await fetch("http://192.168.0.214:8085/saveBasicDetails", {
         //   let res = await fetch("http://192.168.0.35:8085/saveAttornyInfo", {   Foundation IP Address
         setMessage("");
            setError("");
         let res =await fetch("https://192.168.0.182:8085/normal/saveNormalAttornyInfo", {
            method: "POST",
            body: JSON.stringify({
                userId:  sessionStorage.getItem('userId'),
                attMailingAddress1: attorneyAddress1,
                attMailingAddress2: attorneyAddress2,
                attMailingAddress3: attorneyAddress3,
                attMailingCountry: attorneyCountry,
                attMailingProvince: attorneyProvince,
                attMailingProvinceOther: attorneyOtherProvince,
                attMailingCity: attorneyCity,
                attMailingCityOther: attorneyOtherCity,
                attMailingPhoneOffice: attorneyPhoneNo,
                attMailingPhoneResidence: attorneyResidence,
                attMailingFax: attorneyFax,
                attName: attorneyName,
                attSalutation: attorneySalutation,
                attMobileNo: attorneyMobileNo,
                attEmail: attorneyEmail,
                attCnicLifeTimeCheck: attorneyCnicExpiry,
                attCnicExpiryDate : attorneyCnicLifeTime,
                attUinType: attorneyUinType,
                attUinTypeDesc: attorneyUIN,

            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
              },
            });
          let resJson = await res.json();
          if (res.status === 200) {

            setAttorneyAddress1("");
            setAttorneyAddress2("");
            setAttorneyAddress3("");
            setAttorneyCountry("");
            setAttorneyProvince("");
            setAttorneyOtherProvince("");
            setAttorneyCity("");
            setAttorneyOtherCity("");
            setAttorneyPhoneNo("");
            setAttorneyResidence("");
            setAttorneyFax("");
            setAttorneyName("");
            setAttorneySalutation("");
            setAttorneyMobileNo("");
            setAttorneyEmail("");
            setAttorneyCnicExpiry("");
            setAttorneyCnicLifeTime("");
            setAttorneyUinType("");
            setAttorneyUIN("");
            
            setMessage("Form Submitted Successfully");
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }
      };

    return(
        <>
        <Row className="mb-3">
         <Navbar/>
    <div className="col-3">
  
        <br></br>
        <br></br>
    <SidebarNormal/>
    </div>
    <div className="col-9">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
    
        <h3><b>ATTORNEY DETAILS</b></h3>
        <form className="container mt-3 mb-3">
        <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Mr./Mrs.</Form.Label>
            <Form.Select className="form-control" name="a_state" value={attorneySalutation} onChange={(e) => setAttorneySalutation(e.target.value)}>
                
                <option value="Blank"></option>
                <option value="MR">MR.</option>
                <option value="MRS">MRS.</option>
                <option value="MS">MS.</option>
                
            </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>Name</Form.Label>
            <input type="text" onChange={(e) => setAttorneyName(e.target.value)} value={attorneyName} name="applicationId" className="form-control" />
        </Form.Group>

    </Row>

    <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>UIN Type</Form.Label>
            <Form.Select className="form-control" name="uinType" value={attorneyUinType} onChange={(e) => setAttorneyUinType(e.target.value)}>
                <option value="">Select...</option>
                <option value="CNIC">CNIC</option>
                <option value="SNIC">SNIC</option>
                <option value="NICOP">NICOP</option>
                <option value="POC">POC</option>
                
            </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>UIN</Form.Label>
            <input type="text" onChange={(e) => setAttorneyUIN(e.target.value)} value={attorneyUIN} name="uin" className="form-control" />
        </Form.Group>

    </Row>

        <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>CNIC Expiry</Form.Label>
            <Form.Select className="form-control" name="a_state" value={attorneyCnicExpiry} onChange={(e) => setAttorneyCnicExpiry(e.target.value)}>
                <option value="">Select...</option>
                <option value="Y">Life Time CNIC is available</option>
                <option value="NULL">Life Time CNIC is not available and expiry date is provided </option>
                
            </Form.Select>
        </Form.Group>
        <Form.Group controlId="formEmailInfo" className="col col-sm-8">
            <Form.Label>CNIC Expiry Date</Form.Label>

            <DatePicker className="form-control" selected={attorneyCnicLifeTime} onChange={(date) =>   setAttorneyCnicLifeTime(date)} dateFormat="dd/MM/yyyy"   />


        </Form.Group>
       

    </Row>


    <Row className="mb-3">
        <Form.Group controlId="formContactInfo" className="col col-sm-4">
            <Form.Label>Mobile No</Form.Label>
            <input type="text" onChange={(e) => setAttorneyMobileNo(e.target.value)} value={attorneyMobileNo} name="officePhoneNo" className="form-control" />
        </Form.Group>
        <Form.Group controlId="formEmailInfo" className="col col-sm-8">
            <Form.Label>EMAIL</Form.Label>
            <input type="text" onChange={(e) => setAttorneyEmail(e.target.value)} value={attorneyEmail} name="residencePhoneNo" className="form-control" />
        </Form.Group>
       

    </Row>

        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                <Form.Label>Mailing Address1</Form.Label>
                 <input type="text" onChange={(e) => setAttorneyAddress1(e.target.value)} value={attorneyAddress1} name="mailingAddress1" className="form-control" />
            </Form.Group>
        
        </Row>
        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                <Form.Label>Mailing Address2</Form.Label>
                 <input type="text" onChange={(e) => setAttorneyAddress2(e.target.value)} value={attorneyAddress2} name="mailingAddress2" className="form-control" />
            </Form.Group>
        
        </Row>
        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                <Form.Label>Mailing Address3</Form.Label> 
                 <input type="text" onChange={(e) => setAttorneyAddress3(e.target.value)} value={attorneyAddress3} name="mailingAddress3" className="form-control" />
            </Form.Group>
        
        </Row>
        <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
            <Form.Label>Mailing Country</Form.Label>
            <Form.Select className="form-control" name="mailingCountry" value={attorneyCountry} onChange={(e) => setAttorneyCountry(e.target.value)}>
                <option value="">Select...</option>
                <option value="PAK">Pakistan</option>
                <option value="AS">American Samoa</option>
                <option value="GI">Gibraltar</option>
                <option value="TH">Thailand</option>
                <option value="GE">Georgia</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="SE">Sweden</option>
                <option value="SD">Sudan</option>
                <option value="TN">Tunisia</option>
                <option value="ER">Eritrea</option>
                <option value="SL">Sierra Leone</option>
                <option value="EC">Ecuador</option>
                <option value="DO">Dominican Republic</option>
                <option value="DJ">Djibouti</option>
                <option value="CY">Cyprus</option>
                <option value="CU">Cuba</option>
                <option value="RU">Russian Federation</option>
                <option value="SB">Solomon Islands</option>
                <option value="QA">Qatar</option>
                <option value="CR">Costa Rica</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="OM">Oman</option>
                <option value="PG">Papua New Guinea</option>
                <option value="NI">Nicaragua</option>
                <option value="AN">Netherlands Antilles</option>
                <option value="NZ">New Zealand</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="BG">Bulgaria</option>
                <option value="AF">Afghanistan</option>
                <option value="DZ">Algeria</option>
                <option value="KI">Kiribati</option>
                <option value="KP">Korea, North</option>
                <option value="AO">Angola</option>
                <option value="LV">Latvia</option>
                <option value="KW">Kuwait</option>
                <option value="LA">Lao Peoples Democratic Republic</option>
                <option value="LB">Lebanon</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libyan Arab Jamahiriya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="MK">Macedonia</option>
                <option value="BD">Bangladesh</option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="BB">Barbados</option>
                <option value="MY">Malaysia</option>
                <option value="BY">Belarus</option>
                <option value="MV">Maldives</option>
                <option value="MU">Mauritius</option>
                <option value="BT">Bhutan</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="BV">Bouvet Island</option>
                <option value="MA">Morocco</option>
                <option value="NA">Namibia</option>
                <option value="CN">China</option>
                <option value="UA">Ukraine</option>
                <option value="GT">Guatemala</option>
                <option value="GY">Guyana</option>
                <option value="VE">Venezuela</option>
                <option value="ZM">Zambia</option>
                <option value="JM">Jamaica</option>
                <option value="IQ">Iraq</option>
                <option value="JO">Jordan</option>
                <option value="CX">Christmas Island</option>
                <option value="TD">Chad</option>
                <option value="BF">Burkina Faso</option>
                <option value="BE">Belgium</option>
                <option value="AT">Austria</option>
                <option value="AU">Australia</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="BJ">Benin</option>
                <option value="CA">Canada</option>
                <option value="CF">Central African Republic</option>
                <option value="GA">Gabon</option>
                <option value="CC">Cocos Kelling Island</option>
                <option value="CG">Congo - Republic of</option>
                <option value="CK">Cook Islands</option>
                <option value="DM">Dominica</option>
                <option value="FO">Faroe Islands</option>
                <option value="FI">Finland</option>
                <option value="MQ">Martinique</option>
                <option value="GR">Greece</option>
                <option value="GU">Guam</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="NR">Nauru</option>
                <option value="PW">Palau</option>
                <option value="VC">Saint Vi-Grenadines</option>
                <option value="TW">Taiwan</option>
                <option value="TK">Tokelau</option>
                <option value="UG">Uganda</option>
                <option value="GB">United Kingdom</option>
                <option value="NO">Norway</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PT">Portugal</option>
                <option value="RO">Romania</option>
                <option value="LC">Saint Lucia</option>
                <option value="SN">Senegal</option>
                <option value="CH">Switzerland</option>
                <option value="YE">Yemen</option>
                <option value="VG">Virgin Islands - GB</option>
                <option value="AE">United Arab Emirates</option>
                <option value="TV">Tuvalu</option>
                <option value="HN">Honduras</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="FJ">Fiji</option>
                <option value="ET">Ethiopia</option>
                <option value="HT">Haiti</option>
                <option value="TO">Tonga</option>
                <option value="TJ">Tajikistan</option>
                <option value="GS">South Georgia - South Sandwich Islands</option>
                <option value="SC">Seychelles</option>
                <option value="SA">Saudi Arabia</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="TP">East Timor</option>
                <option value="DK">Denmark</option>
                <option value="CZ">Czech Republic</option> 
                <option value="RW">Rwanda</option>
                <option value="EG">Egypt</option>
                <option value="PA">Panama</option>
                <option value="CL">Chile</option>
                <option value="KY">Cayman Islands</option>
                <option value="BI">Burundi</option>
                <option value="MM">Myanmar</option>
                <option value="MZ">Mozambique</option>
                <option value="AL">Albania</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="AI">Anguilla</option>
                <option value="AR">Argentina</option>
                <option value="AW">Aruba</option>
                <option value="MO">Macau</option>
                <option value="LS">Lesotho</option>
                <option value="MT">Malta</option>
                <option value="ML">Mali</option>
                <option value="MH">Marshall Islands</option>
                <option value="BZ">Belize</option>
                <option value="BM">Bermuda</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="MN">Mongolia</option>
                <option value="NP">Nepal</option>
                <option value="TR">Turkey</option>
                <option value="GN">Guinea</option>
                <option value="KZ">Kazakhstan</option>
                <option value="HK">Hong Kong</option>
                <option value="VN">Vietnam</option>
                <option value="IL">Israel</option>
                <option value="IR">Iran Islamic Republic of</option>
                <option value="HU">Hungary</option>
                <option value="ZW">Zimbabwe</option>
                <option value="VU">Vanuatu</option>
                <option value="JP">Japan</option>
                <option value="AD">Andorra</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="GL">Greenland</option>
                <option value="GP">Guadeloupe</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IE">Ireland</option>
                <option value="MC">Monaco</option>
                <option value="FM">MicronesiaFederated</option>
                <option value="LU">Luxembourg</option>
                <option value="IT">Italy</option>
                <option value="DE">Germany</option>
                <option value="GD">Grenada</option>
                <option value="TC">Turks-Caicos Islands</option>
                <option value="UM">US Minor Outlying</option>
                <option value="KE">Kenya</option>
                <option value="YU">Yugoslavia</option>
                <option value="FK">Falkland Islands Malvinas</option>
                <option value="GH">Ghana</option>
                <option value="EE">Estonia</option>
                <option value="SZ">Swaziland</option>
                <option value="ZA">South Africa</option>
                <option value="HR">Croatia Hrvatska</option>
                <option value="SO">Somalia</option>
                <option value="CD">Congo-Brazzaville</option>
                <option value="KM">Comoros</option>
                <option value="PL">Poland</option>
                <option value="NG">Nigeria</option>
                <option value="CV">Cape Verde</option>
                <option value="NC">New Caledonia</option>
                <option value="AM">Armenia</option>
                <option value="MR">Mauritania</option>
                <option value="BO">Bolivia</option>
                <option value="BR">Brazil</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="SV">El Salvador</option>
                <option value="SR">Suriname</option>
                <option value="GM">Gambia</option>
                <option value="IS">Iceland</option>
                <option value="CO">Colombia</option>
                <option value="FR">France</option>
                <option value="YT">Mayotte</option>
                <option value="CI">Ivory Coast</option>
                <option value="NL">Netherlands</option>
                <option value="RE">Reunion</option>
                <option value="KN">Saint Kitts</option>
                <option value="NF">Norfolk Island</option>
                <option value="NU">Niue</option>
                <option value="VA">Vatican City State</option>
                <option value="VI">Virgin Islands - US</option>
                <option value="SM">San Marino</option>
                <option value="SI">Slovenia</option>
                <option value="WF">Wallis-Futuna Island</option>
                <option value="UZ">Uzbekistan</option>
                <option value="GF">French Guiana</option>
                <option value="TF">French Southern Terr</option>
                <option value="PF">French Polynesia</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="MS">Montserrat</option>
                <option value="HM">HeardMcDonald Island</option>
                <option value="MX">Mexico</option>
                <option value="NE">Niger</option>
                <option value="SJ">Svalbard-Jan Mayen</option>
                <option value="EH">Western Sahara</option>
                <option value="MP">North Mariana Island</option>
                <option value="PR">Puerto Rico</option>
                <option value="WS">Samoa</option>
                <option value="SG">Singapore</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="TM">Turkmenistan</option>
                <option value="TG">Togo</option>
                <option value="TZ">Tanzania</option>
                <option value="KR">Korea, South</option>
                <option value="US">United States</option>
                <option value="UY">Uruguay</option>
                <option value="SAT">SAMAO</option>
             
            </Form.Select>
            </Form.Group>
        
        </Row>
      
        <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Mailing Province (Select "Other" If Not In List)</Form.Label>
            <Form.Select defaultValue="Choose..." className="form-control" name="mailingProvince" value={attorneyProvince} onChange={(e) => setAttorneyProvince(e.target.value)}>
               <option value="">Select...</option>
               <option value="01">FATA / FANA</option>
                <option value="02">SINDH</option>
                <option value="03">PUNJAB</option>
                <option value="04">KHYBER PAKHTUNKHWA</option>
                <option value="05">BALOCHISTAN</option>
                <option value="06">FEDERAL CAPITAL</option>
                <option value="07">A.J.K.</option>
            </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>If Province Is Not In The List</Form.Label>
            <input type="text" onChange={(e) => setAttorneyOtherProvince(e.target.value)} value={attorneyOtherProvince} name="otherMailingProvince" className="form-control" />
        </Form.Group>

    </Row>
    <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Mailing City (Select "Other" If Not In List)</Form.Label>
            <Form.Select defaultValue="Choose..." className="form-control" name="mailingCity" value={attorneyCity} onChange={(e) => setAttorneyCity(e.target.value)}>
                <option value="">Select...</option> 
                <option value="Choose City...">Choose...</option>
                <option value="0001">BAGH</option>
                <option value="0002">BHIMBER</option>
                <option value="0003">DHUNGI</option>
                <option value="0004">KHUIRATTA</option>
                <option value="0005">KOTLI</option>
                <option value="0006">MANGLA</option>
                <option value="0007">MIRPUR</option>
                <option value="0008">MUZAFFARABAD</option>
                <option value="0009">PLANDRI</option>
                <option value="0010">PUNCH</option>
                <option value="0011">RAWALAKOT</option>
                <option value="0012">AMIR CHAH</option>
                <option value="0013">BAZDAR</option>
                <option value="0014">BELA</option>
                <option value="0015">BELLPAT</option>
                <option value="0016">BHAG</option>
                <option value="0017">BURJ</option>
                <option value="0018">CHAGAI</option>
                <option value="0019">CHAH SANDAN</option>
                <option value="0020">CHAKKU</option>
                <option value="0021">CHAMAN</option>
                <option value="0022">CHHATR</option>
                <option value="0023">DALBANDIN</option>
                <option value="0024">DERA BUGTI</option>
                <option value="0025">DHANA SAR</option>
                <option value="0026">DIWANA</option>
                <option value="0027">DUKI</option>
                <option value="0028">DUSHI</option>
                <option value="0029">DUZAB</option>
                <option value="0030">GAJAR</option>
                <option value="0031">GANDAVA</option>
                <option value="0032">GARHI KHAIRO</option>
                <option value="0033">GARRUCK</option>
                <option value="0034">GHAZLUNA</option>
                <option value="0035">GIRDAN</option>
                <option value="0036">GULISTAN</option>
                <option value="0037">GWADAR</option>
                <option value="0038">GWASH</option>
                <option value="0039">HAB CHAUKI</option>
                <option value="0040">HAMEEDABAD</option>
                <option value="0041">HARNAI</option>
                <option value="0042">HINGLAJ</option>
                <option value="0043">HOSHAB</option>
                <option value="0044">HUB</option>
                <option value="0045">ISPIKAN</option>
                <option value="0046">JAFFERABAD</option>
                <option value="0047">JHAL</option>
                <option value="0048">JHAL JHAO</option>
                <option value="0049">JHAL MAGSI</option>
                <option value="0050">JHATPAT</option>
                <option value="0051">JIWANI</option>
                <option value="0052">KALANDI</option>
                <option value="0053">KALAT</option>
                <option value="0054">KAMARAROD</option>
                <option value="0055">KANAK</option>
                <option value="0056">KANDI</option>
                <option value="0057">KANPUR</option>
                <option value="0058">KAPIP</option>
                <option value="0059">KAPPAR</option>
                <option value="0060">KARODI</option>
                <option value="0061">KATURI</option>
                <option value="0062">KHARAN</option>
                <option value="0063">KHUZDAR</option>
                <option value="0064">KIKKI</option>
                <option value="0065">KOHAN</option>
                <option value="0066">KOHLU</option>
                <option value="0067">KORAK</option>
                <option value="0068">LAHRI</option>
                <option value="0069">LASBELA</option>
                <option value="0070">LIARI</option>
                <option value="0071">LORALAI</option>
                <option value="0072">MACH</option>
                <option value="0073">MAND</option>
                <option value="0074">MANGUCHAR</option>
                <option value="0075">MASHKI CHAH</option>
                <option value="0076">MASLTI</option>
                <option value="0077">MASTUNG</option>
                <option value="0078">MEKHTAR</option>
                <option value="0079">MERUI</option>
                <option value="0080">MIANEZ</option>
                <option value="0081">MURGHA KIBZAI</option>
                <option value="0082">MUSA KHEL BAZAR</option>
                <option value="0083">NAGHA KALAT</option>
                <option value="0084">NAL</option>
                <option value="0085">NASEERABAD</option>
                <option value="0086">NAUROZ KALAT</option>
                <option value="0087">NUR GAMMA</option>
                <option value="0088">NUSHKI</option>
                <option value="0089">NUTTAL</option>
                <option value="0090">ORMARA</option>
                <option value="0091">PALANTUK</option>
                <option value="0092">PANJGUR</option>
                <option value="0093">PASNI</option>
                <option value="0094">PIHARAK</option>
                <option value="0095">PISHIN</option>
                <option value="0096">QAMRUDDIN KAREZ</option>
                <option value="0097">QILA ABDULLAH</option>
                <option value="0098">QILA LADGASHT</option>
                <option value="0099">QILA SAFED</option>
                <option value="0100">QILA SAIFULLAH</option>
                <option value="0101">QUETTA</option>
                <option value="0102">RAKHNI</option>
                <option value="0103">ROBAT THANA</option>
                <option value="0104">RODKHAN</option>
                <option value="0105">SAINDAK</option>
                <option value="0106">SANJAWI</option>
                <option value="0107">SARUNA</option>
                <option value="0108">SHABAZ KALAT</option>
                <option value="0109">SHAHPUR</option>
                <option value="0110">SHARAM JOGIZAI</option>
                <option value="0111">SHINGAR</option>
                <option value="0112">SHORAP</option>
                <option value="0113">SIBI</option>
                <option value="0114">SONMIANI</option>
                <option value="0115">SPEZAND</option>
                <option value="0116">SPINTANGI</option>
                <option value="0117">SUI</option>
                <option value="0118">SUNTSAR</option>
                <option value="0119">SURAB</option>
                <option value="0120">THALO</option>
                <option value="0121">TUMP</option>
                <option value="0122">TURBAT</option>
                <option value="0123">UMARAO</option> 
                <option value="0124">UTHAL</option>
                <option value="0125">VITAKRI</option>
                <option value="0126">WADH</option>
                <option value="0127">WASHAP</option>
                <option value="0128">WASJUK</option>
                <option value="0129">YAKMACH</option>
                <option value="0130">ZHOB</option>
                <option value="0131">ZIARAT</option>
                <option value="0132">ISLAMABAD</option>
                <option value="0133">ABBOTTABAD</option>
                <option value="0134">ADEZAI</option>
                <option value="0135">AYUBIA</option>
                <option value="0136">BANDA DAUD SHAH</option>
                <option value="0137">BANNU</option>
                <option value="0138">BATAGRAM</option>
                <option value="0139">BIROTE</option>
                <option value="0140">BUNER</option>
                <option value="0141">CHAKDARA</option>
                <option value="0142">CHARSADDA</option>
                <option value="0143">CHITRAL</option>
                <option value="0144">DARGAI</option>
                <option value="0145">DARYA KHAN</option>
                <option value="0146">DERA ISMAIL KHAN</option>
                <option value="0147">DRASAN</option>
                <option value="0148">DROSH</option>
                <option value="0149">HANGU</option>
                <option value="0150">HARIPUR</option>
                <option value="0151">KALAM</option>
                <option value="0152">KARAK</option>
                <option value="0153">KHANASPUR</option>
                <option value="0154">KOHAT</option>
                <option value="0155">KOHISTAN</option>
                <option value="0156">LAKKI MARWAT</option>
                <option value="0157">LATAMBER</option>
                <option value="0158">LOWER DIR</option>
                <option value="0159">MADYAN</option>
                <option value="0160">MALAKAND</option>
                <option value="0161">MANSEHRA</option>
                <option value="0162">MARDAN</option>
                <option value="0163">MASTUJ</option>
                <option value="0164">MONGORA</option>
                <option value="0165">NOWSHERA</option>
                <option value="0166">PAHARPUR</option>
                <option value="0167">PESHAWAR</option>
                <option value="0168">SAIDU SHARIF</option>
                <option value="0169">SAKESAR</option>
                <option value="0170">SHANGLA</option>
                <option value="0171">SWABI</option>
                <option value="0172">SWAT</option>
                <option value="0173">TANGI</option>
                <option value="0174">TANK</option>
                <option value="0175">THALL</option>
                <option value="0176">TIMARGARA</option>
                <option value="0177">TORDHER</option>
                <option value="0178">UPPER DIR</option>
                <option value="0179">ASTOR</option>
                <option value="0180">BAJAUR</option>
                <option value="0181">BARAMULA</option>
                <option value="0182">DIR</option>
                <option value="0183">GILGIT</option>
                <option value="0184">HANGU</option>
                <option value="0185">KHYBER</option>
                <option value="0186">KURRAM</option>
                <option value="0187">MALAKAND</option>
                <option value="0188">MIRAM SHAH</option>
                <option value="0189">MOHMAND</option>
                <option value="0190">NAGAR</option>
                <option value="0191">NORTH WAZIRISTAN</option>
                <option value="0192">PARACHINAR</option>
                <option value="0193">PEACOCK</option>
                <option value="0194">SHANDUR</option>
                <option value="0195">SHANGRILA</option>
                <option value="0196">SKARDU</option>
                <option value="0197">SOUTH WAZIRISTAN</option>
                <option value="0198">WANA</option>
                <option value="0199">AHMED NAGER CHATHA</option>
                <option value="0200">AHMEDPUR EAST</option>
                <option value="0201">ALI PUR</option>
                <option value="0202">ARIFWALA</option>
                <option value="0203">ATTOCK</option>
                <option value="0204">BAHAWALNAGAR</option>
                <option value="0205">BAHAWALPUR</option>
                <option value="0206">BASTI MALOOK</option>
                <option value="0207">BHAGALCHUR</option>
                <option value="0208">BHAIPHERU</option>
                <option value="0209">BHAKKAR</option>
                <option value="0210">BHALWAL</option>
                <option value="0211">BUREWALA</option>
                <option value="0212">CHAILIANWALA</option>
                <option value="0213">CHAKWAL</option>
                <option value="0214">CHICHAWATNI</option>
                <option value="0215">CHINIOT</option>
                <option value="0216">CHOWK AZAM</option>
                <option value="0217">CHOWK SARWAR SHAHEED</option>
                <option value="0218">DARYA KHAN</option>
                <option value="0219">DASKA</option>
                <option value="0220">DERA GHAZI KHAN</option>
                <option value="0221">DERAWAR FORT</option>
                <option value="0222">DHAULAR</option>
                <option value="0223">DINA CITY</option>
                <option value="0224">DINGA</option>
                <option value="0225">DIPALPUR</option>
                <option value="0226">FAISALABAD</option>
                <option value="0227">FATEH JANG</option>
                <option value="0228">GADAR</option>
                <option value="0229">GHAKHAR MANDI</option>
                <option value="0230">GUJAR KHAN</option>
                <option value="0231">GUJRANWALA</option>
                <option value="0232">GUJRAT</option>
                <option value="0233">HAFIZABAD</option>
                <option value="0234">HAROONABAD</option>
                <option value="0235">HASILPUR</option>
                <option value="0236">HAVELI LAKHA</option>
                <option value="0237">JAHANIA</option>
                <option value="0238">JALLA ARAAIN</option>
                <option value="0239">JAMPUR</option>
                <option value="0240">JAUHARABAD</option>
                <option value="0241">JHANG</option>
                <option value="0242">JHELUM</option>
                <option value="0243">KALABAGH</option>
                <option value="0244">KAMALIA</option>
                <option value="0245">KAMOKEY</option>
                <option value="0246">KAROR LAL ESAN</option>
                <option value="0247">KASUR</option>
                <option value="0248">KHANEWAL</option>
                <option value="0249">KHANPUR</option>
                <option value="0250">KHARIAN</option>
                <option value="0251">KHUSHAB</option>
                <option value="0252">KOT ADDU</option>
                <option value="0253">LAAR</option>
                <option value="0254">LAHORE</option>
                <option value="0255">LALAMUSA</option>
                <option value="0256">LAYYAH</option>
                <option value="0257">LODHRAN</option>
                <option value="0258">MAILSI</option>
                <option value="0259">MAKHDOOM AALI</option>
                <option value="0260">MAMOORI</option>
                <option value="0261">MANDI BAHAUDDIN</option>
                <option value="0262">MANDI WARBURTON</option>
                <option value="0263">MIAN CHANNU</option>
                <option value="0264">MIANWALI</option>
                <option value="0265">MINAWALA</option>
                <option value="0266">MULTAN</option>
                <option value="0267">MURIDKE</option>
                <option value="0268">MURREE</option>
                <option value="0269">MUZAFFARGARH</option>
                <option value="0270">NAROWAL</option>
                <option value="0271">OKARA</option>
                <option value="0272">PAK PATTAN</option>
                <option value="0273">PANJGUR</option>
                <option value="0274">PATTOKI</option>
                <option value="0275">PIRMAHAL</option>
                <option value="0276">QILA DIDAR SINGH</option>
                <option value="0277">RABWAH</option>
                <option value="0278">RAHIM YAR KHAN</option>
                <option value="0279">RAIWIND</option>
                <option value="0280">RAJAN PUR</option>
                <option value="0281">RAWALPINDI</option>
                <option value="0282">RENALA KHURD</option>
                <option value="0283">ROHRI</option>
                <option value="0284">SADIQABAD</option>
                <option value="0285">SAFDARABAD-DABANSING</option>
                <option value="0286">SAHIWAL</option>
                <option value="0287">SAMBERIAL</option>
                <option value="0288">SANGLA HILL</option>
                <option value="0289">SARAI ALAMGIR</option>
                <option value="0290">SARGODHA</option>
                <option value="0291">SHAFQAT SHAHED CHOWK</option>
                <option value="0292">SHAKARGARH</option>
                <option value="0293">SHARAQPUR</option>
                <option value="0294">SHEIKHUPURA</option>
                <option value="0295">SIALKOT</option>
                <option value="0296">SOHAWA</option>
                <option value="0297">SOOIANWALA</option>
                <option value="0298">SUNDAR</option>
                <option value="0299">TAKHTBAI</option>
                <option value="0300">TALAGANG</option>
                <option value="0301">TARBELA</option>
                <option value="0302">TAXILA</option>
                <option value="0303">TOBA TEK SINGH</option>
                <option value="0304">VEHARI</option>
                <option value="0305">WAH CANTT</option>
                <option value="0306">WAZIRABAD</option>
                <option value="0307">ALI BANDAR</option>
                <option value="0308">BADEN</option>
                <option value="0309">CHACHRO</option>
                <option value="0310">DADU</option>
                <option value="0311">DAHARKI</option>
                <option value="0312">DIGRI</option>
                <option value="0313">DIPLO</option>
                <option value="0314">DOKRI</option>
                <option value="0315">GADRA</option>
                <option value="0316">GHANIAN</option>
                <option value="0317">GHAUSPUR</option>
                <option value="0318">GHOTKI</option>
                <option value="0319">GOTH MACHI</option>
                <option value="0320">HALA (SINDH) HALA</option>
                <option value="0321">HYDERABAD</option>
                <option value="0322">ISLAMKOT</option>
                <option value="0323">JACOBABAD</option>
                <option value="0324">JAMESABAD</option>
                <option value="0325">JAMSHORO</option>
                <option value="0326">JANGHAR</option>
                <option value="0327">JATI</option>
                <option value="0328">JHUDO</option>
                <option value="0329">JUNGSHAHI</option>
                <option value="0330">KAMBER</option>
                <option value="0331">KANDIARO</option>
                <option value="0332">KARACHI</option>
                <option value="0333">KASHMOR</option>
                <option value="0334">KETI BANDAR</option>
                <option value="0335">KHAIRPUR</option>
                <option value="0336">KHOKHROPUR</option>
                <option value="0337">KHORA</option>
                <option value="0338">KLUPRO</option>
                <option value="0339">KOT SARAE</option>
                <option value="0340">KOTRI</option>
                <option value="0341">LARKANA</option>
                <option value="0342">LUND</option>
                <option value="0343">MATHI</option>
                <option value="0344">MATIARI</option>
                <option value="0345">MEHAR</option>
                <option value="0346">MIRPUR BATORO</option>
                <option value="0347">MIRPUR KHAS</option>
                <option value="0348">MIRPUR SAKRO</option>
                <option value="0349">MITHANI</option>
                <option value="0350">MITHI</option>
                <option value="0351">MORO</option>
                <option value="0352">NAGAR PARKAR</option>
                <option value="0353">NAOKOT</option>
                <option value="0354">NAUDERO</option>
                <option value="0355">NAUSHARA</option>
                <option value="0356">NAUSHERO FEROZE</option>
                <option value="0357">NAWABSHAH</option>
                <option value="0358">NAZIMABAD</option>
                <option value="0359">MOIN JO DARO</option>
                <option value="0360">PENDOO</option>
                <option value="0361">POKRAN</option>
                <option value="0362">QAMBAR</option>
                <option value="0363">QAZI AHMAD</option>
                <option value="0364">RANIPUR</option>
                <option value="0365">RATODERO</option>
                <option value="0366">ROHRI</option>
                <option value="0367">SAIDU SHARIF</option>
                <option value="0368">SAKRAND</option>
                <option value="0369">SANGHAR</option>
                <option value="0370">SHADADKHOT</option>
                <option value="0371">SHAHBANDAR</option>
                <option value="0372">SHAHDADPUR</option>
                <option value="0373">SHAHPUR CHAKAR</option>
                <option value="0374">SHIKARPUR</option>
                <option value="0375">SUJAWAL</option>
                <option value="0376">SUKKUR</option>
                <option value="0377">TANDO ADAM</option>
                <option value="0378">TANDO ALAH YAR</option>
                <option value="0379">TANDO BAGO</option>
                <option value="0380">TAR AHAMD RIND</option>
                <option value="0381">THARPARKAR</option>
                <option value="0382">THATTA</option>
                <option value="0383">TUJAL</option>
                <option value="0384">UMARKOT</option>
                <option value="0385">VEIRWARO</option>
                <option value="0386">WARAH</option>
                <option value="0387">MIRPUR MATHELO</option>
                <option value="0388">PANOAKIL</option>
                <option value="0389">NANKANA SAHIB</option>
                <option value="0390">GOJRA</option>
                <option value="0391">JARANWALA</option>
                <option value="0392">CHUNIA</option>
                <option value="0393">KANDHKOT</option>
                <option value="0394">KHAIRPUR MIRIS</option>
                <option value="0395">UBARO</option>
                <option value="0396">TANDO MUHAMMAD KHAN</option>
                <option value="0397">MATLI</option>
                <option value="0398">SUDHNOTI</option>
                <option value="0399">HASAN ABDAL</option>
                <option value="0400">HUNZA</option>
                <option value="401">TANDLIANWALA</option>
                <option value="0402">DADYAL</option>
                <option value="0403">NEELUM</option>
                <option value="0404">HATTIAN BALA</option>
            </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>If City Is Not In The List</Form.Label>
            <input type="text" onChange={(e) => setAttorneyOtherCity(e.target.value)} value={attorneyOtherCity} name="otherMailingCity" className="form-control" />
        </Form.Group>

    </Row>
    <Row className="mb-3">
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Phone No (Office)</Form.Label>
            <input type="text" onChange={(e) => setAttorneyPhoneNo(e.target.value)} value={attorneyPhoneNo} name="officePhoneNo" className="form-control" />
        </Form.Group>
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Phone No (Residence)</Form.Label>
            <input type="text" onChange={(e) => setAttorneyResidence(e.target.value)} value={attorneyResidence} name="residencePhoneNo" className="form-control" />
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-4">
            <Form.Label>Phone No (Fax)</Form.Label>
            <input type="text" onChange={(e) => setAttorneyFax(e.target.value)} value={attorneyFax} name="faxPhoneNo" className="form-control" />
        </Form.Group>

    </Row>
   
    <div className="form-row row">
                <div className="col">
                    <input type="submit" onClick={handleSubmit} className="btn btn-primary" />
                    <input type="button" value="Next" onClick={navigateToNominee}  className="btn btn-primary" />
                </div>
                </div>

                <div className="alert alert-success">
            {message}
           </div>
          <div className="alert alert-danger">
            {error}
        </div>
</form>
</div>
</Row>
        </>
     
      
    )
    }
    export default AttorneyDetails;