import React from 'react'
//import HomeIcon from '@mui/icons-material/Home';

export const SidebarData = [ 
    {
        title:"Basic Details",
       
        link:"/BasicDetailNormal",

    },
    {
        title:"Contact Details",
   
        link:"/ContactDetailNormal",

    },
    {
        title:"Nominee Details",
    
        link:"/NomineeDetailNormal",

    },
    {
        title:"Attorney Details",
    
        link:"/AttorneyDetails",

    },
    {
        title:"Other Details",
    
        link:"/OtherDetails",

    },
    {
        title:"Report",
    
        link:"/PrintFormNormal",

    }
   
   
];