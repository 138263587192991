import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, InputGroup, Row, Button } from 'react-bootstrap';

import React, { useEffect, useState } from 'react';
import style from './style.css';
import Navbar from "./Navbar.js";
import Sidebar from "./Sidebar.js";
import ContactStyle from "./ContactStyle.css";
import { useNavigate, useParams } from 'react-router-dom';


function AdditionalDetail(){

    const fetchData = () => {
     fetch(`https://sahulat.fsedge.com:8085/sahulat/fetchAdditionalDetails/${sessionStorage.getItem('userId')}`)
      
   // fetch(`http://192.168.0.182:8085/sahulat/fetchAdditionalDetails/${sessionStorage.getItem('userId')}`)
          .then(response => {
            if(response.status === 500){
                setDisableInput(false);
            }
            return response.json()
          })
          .then(data => {
           // setUsers(data)
           setGrossIncome(data.grossAnnualIncome);
           setIncomeSource(data.sourceOfIncome)
           setOtherIncomeSource(data.sourceOfIncomeOther)
           setOccupation(data.occupation)
           setOtherOccupation(data.othersOccupation)
           setJobTitle(data.designation)
           setDepartment(data.department)
           setEmployeeName(data.employeerName)
           setEmployeeAddress(data.employeerAddress)
           setBusinessNature(data.natueOfBusiness)
           setBusinessGeograhics(data.businessGeographies)
           setOtherBusinessGeograhics(data.otherBusinessGeographies)
           setCounterParties(data.counterParties)
           setOtherCounterParties(data.otherCounterParties)
           setFundSource(data.stockInvestment)
           //setOtherFundSource(data.)
           setFinancialPersonName(data.fsFullName)
           setFinancialPHFName(data.fsFatherName)
           setFinancialPRelation(data.fsRelationship)
           setFinancialPId(data.fsIdCardNo)
           setFinancialPNo(data.fsMobileNo)
           setFinancialPEmail(data.fsEmail)
           setFinancialPOccupation(data.fsOccupation)
           setFinancialPOtherOccupation(data.fsOccupationOther)
           setFinancialPBusiness(data.fsEmployerName)
           setFinancialPJobTitle(data.fsDesignation)
           setFinancialPAddress(data.fsEmployerAddress)
           setFinancialPGrossIncome(data.fsGrossAnnualIncome)
           setBirthCountry(data.countryOfBirth)
           setBirthCity(data.cityOfBirth)
           setNationality(data.nationality)
          
           setTaxPayerIdentificationNo(data.osCountryTaxResident)
           setTin(data.osTin)
           setUnavailableTin(data.osTinOther)
           setUnavailabilityReason(data.unableTin)
           setUsCitizen(data.greenCardHolder)
           setUsBorn(data.usBorn)
           setUsAddress(data.usAddress)
           setAttorneyPower(data.usPowerOfAttorny)
           setUsAccount(data.usTransferFunds)
           setPhoneNo(data.usPobox)
           setPoliticalExposed(data.pep)
           setCurrentStatus(data.currentStatus)
         
           setDesignation(data.designationGrade)
           setPDepartment(data.departmentOrganization)
           setFMPoliticalExposed(data.pepFamilyMember)
           setFMName(data.pepFamilyMemberName)
           setFMRelation(data.closeAssociate)
           setFMCategory(data.closeAssociateCategory)
           setFMDesignation(data.closeAssociateDesignation)
           setFMDepartment(data.closeAssociateDepartment)
           setOtherCountryTaxResident(data.osTaxResident)
           setTaxResidentCountry(data.anotherCountry)
           setCurrentStatus(data.osNationality)
           setCategory(data.pepCategory)
           
          })
      }
      const[data,setData]=useState([]);

      const fetchCSC = () => {
        fetch("https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json")
            .then(response => {
                if(response.status === 500){
                    setDisableInput(false);
                }
                return response.json()
            })
            .then(data=>{
                setData(data);
               
            })
        
        }

      useEffect(() => {
        fetchCSC()
        fetchData()
       
      }, [])

   const [disableInput , setDisableInput] = useState("true");
   const [grossIncome , setGrossIncome] = useState('');
   const [incomeSource , setIncomeSource] = useState('');
   const [otherIncomeSource , setOtherIncomeSource] = useState('');
   const [occupation , setOccupation] = useState('');
   const [otherOccupation , setOtherOccupation] = useState('');
   const [jobTitle , setJobTitle] = useState('');
   const [department , setDepartment] = useState('');
   const [employeeName , setEmployeeName] = useState('');
   const [employeeAddress , setEmployeeAddress] = useState('');
   const [businessNature , setBusinessNature] = useState('');
   const [businessGeograhics , setBusinessGeograhics] = useState('');
   const [otherBusinessGeograhics , setOtherBusinessGeograhics] = useState('');
   const [counterParties , setCounterParties] = useState('');
   const [otherCounterParties , setOtherCounterParties] = useState('');
   const [fundSource , setFundSource] = useState('');
   const [otherFundSource , setOtherFundSource] = useState('');
   const [financialPersonName , setFinancialPersonName] = useState('');
   const [financialPHFName , setFinancialPHFName] = useState('');
   const [financialPRelation , setFinancialPRelation] = useState('');
   const [financialPId , setFinancialPId] = useState('');
   const [financialPNo , setFinancialPNo] = useState('');
   const [financialPEmail , setFinancialPEmail] = useState('');
   const [financialPOccupation , setFinancialPOccupation] =useState('');
   const [financialPOtherOccupation , setFinancialPOtherOccupation] = useState('');
   const [financialPBusiness , setFinancialPBusiness] = useState('');
   const [financialPJobTitle , setFinancialPJobTitle] = useState('');
   const [financialPAddress , setFinancialPAddress] = useState('');
   const [financialPGrossIncome , setFinancialPGrossIncome] = useState('');
   const [birthCountry , setBirthCountry] = useState('');
   const [birthCity , setBirthCity] = useState('');
   const [nationality , setNationality] = useState('');
   const [otherCountryTaxResident , setOtherCountryTaxResident] = useState('');
   const [taxResidentCountry , setTaxResidentCountry] = useState('');
   const [taxpayerIdentificationNo , setTaxPayerIdentificationNo] = useState('');
   const [tin , setTin] = useState('');
   const [unavailableTin , setUnavailableTin] = useState('');
   const [unavailabilityReason , setUnavailabilityReason] = useState('');
   const [otherCountry , setOtherCountry] = useState('');
   const [usCitizen , setUsCitizen] = useState('');
   const [usBorn , setUsBorn] = useState('');
   const [usAddress , setUsAddress] = useState('');
   const [attornyPower , setAttorneyPower] = useState('');
   const [usAccount , setUsAccount] = useState('');
   const [phoneNo , setPhoneNo] = useState('');
   const [politicalExposed , setPoliticalExposed] = useState('');
   const [currentStatus , setCurrentStatus] = useState('');
   const [category , setCategory] = useState('');
   const [designation , setDesignation] = useState('');
   const [pdepartment , setPDepartment] = useState('');
   const [fMPoliticalExposed , setFMPoliticalExposed] = useState('');
   const [fMName , setFMName] = useState('');
   const [fMRelation , setFMRelation] = useState('');
   const [fMCategory , setFMCategory] = useState('');
   const [fMDesignation , setFMDesignation] = useState('');
   const [fMDepartment ,setFMDepartment] = useState('');

   const [message, setMessage] = useState("");
   const [error, setError] = useState("");
 

    const [showOther , setShowOther] = useState('');
    const [showOtherOccupation , setShowOtherOccupation] = useState('');
    const [showOtherGeographics , setShowOtherGeograhics] = useState('');
    const [showOtherCounterParties , setShowOtherCounterParties] = useState('');
    const [showOtherSourceOfIncome , setShowOtherSourceOfIncome] = useState('');
    const [showOccupation , setShowOccupation] = useState('');
    const [showOtherCountryResident , setShowOtherCountryResident] = useState('');
    const [showTinNo , setShowTinNo] =useState('');
    const [showTinOption , setShowTinOption] = useState('');
    const [showPoliticalPerson , setShowPoliticalPerson] = useState('');
    const [showRelativePoliticalPerson , setShowRelativePoliticalPerson] = useState('');
    const [showOtherCountry , setShowOtherCountry] = useState("PAK");


        const navigate = useNavigate();
    
        const navigateToAccount = () => {
        navigate('/AccountDetail');
          };

    function handleShowOther  (e) {
        const getOption = e.target.value;
      
        setShowOther(getOption);
        setIncomeSource(getOption);
       }

       function handleShowOtherOccupation  (e) {
        const getOption = e.target.value;
        
        setShowOtherOccupation(getOption);
        setOccupation(getOption);
       }

       function handleOtherGeographics (e){
        const getOption = e.target.value;
    
        setShowOtherGeograhics(getOption);
        setBusinessGeograhics(getOption);
       }

       function handleCounterParties(e){
        const getOption = e.target.value;
      
        setShowOtherCounterParties(getOption);
        setCounterParties(getOption);

       }

       function handleSourceOfIncome (e) {
        const getOption = e.target.value;
        
        setShowOtherSourceOfIncome(getOption);
        setFundSource(getOption);
       }

       function handleOccupation (e){
        const getOption = e.target.value;
        
        setShowOccupation(getOption);
        setFinancialPOccupation(getOption);
       }

       function handleOtherCountryResident(e){
        const getOption = e.target.value;
        
        setShowOtherCountryResident(getOption);
        setOtherCountryTaxResident(getOption);
       }

       function handleTinNumber (e){
        const getOption = e.target.value;
      
        setShowTinNo(getOption);
        setTaxPayerIdentificationNo(getOption);
       }

       function handleTinOption (e){
        const getOption = e.target.value;
       
        setShowTinOption(getOption);
        setUnavailableTin(getOption);
       }

       function handlePoliticalPerson (e){
        const getOption = e.target.value;
        
        setPoliticalExposed(getOption);
        setShowPoliticalPerson(politicalExposed);

       }

       function handleRelativePoliticalPerson(e){
        const getOption = e.target.value;
        
        setFMPoliticalExposed(getOption);
        setShowRelativePoliticalPerson(fMPoliticalExposed);
      
       }

       function handleOtherCountry(e){
        const getOption = e.target.value;
        
        setShowOtherCountry(getOption);
        setOtherCountry(getOption);
       }


    let handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Inside handle Submit..");
        try {
          setMessage("");
          setError("");

     
  //  let res = await fetch("http://192.168.0.182:8085/sahulat/saveAdditionaldetails", {
   let res = await fetch("https://sahulat.fsedge.com:8085/sahulat/saveAdditionaldetails", {          
            method: "POST",
            body: JSON.stringify({
              
                userId: sessionStorage.getItem('userId'),
                grossAnnualIncome:grossIncome,
                sourceOfIncomeOther:otherIncomeSource,
                sourceOfIncome:incomeSource,
                occupation:occupation,
                othersOccupation:otherOccupation,
                designation:jobTitle,
                department:department,
                employeerName:employeeName,
                employeerAddress:employeeAddress,
                natueOfBusiness:businessNature,
                businessGeographies:businessGeograhics,
                otherBusinessGeographies:otherBusinessGeograhics,
                counterParties:counterParties,
                otherCounterParties:otherCounterParties,
                stockInvestment:fundSource,
                countryOfBirth:birthCountry,
                cityOfBirth:birthCity,
                nationality:nationality,
                fsFullName:financialPersonName,
                fsFatherName:financialPHFName,
                fsRelationship:financialPRelation,
                fsIdCardNo:financialPId,
                fsMobileNo:financialPNo,
                usBorn:usBorn,
                fsEmail:financialPEmail,
                fsOccupationOther:financialPOtherOccupation,
                fsDesignation:financialPJobTitle,
                fsOccupation:financialPOccupation,
                fsEmployerName:financialPBusiness,
                fsEmployerAddress:financialPAddress,
                fsGrossAnnualIncome:financialPGrossIncome,
                osCountryTaxResident:taxpayerIdentificationNo,
                osTin:tin,
                osTinOther:unavailableTin,
                unableTin:unavailabilityReason,
                anotherCountry:otherCountry,
                greenCardHolder:usCitizen,
                usBorn:usBorn,
                usAddress:usAddress,
                usPowerOfAttorny:attornyPower,
                usTransferFunds:usAccount,
                usPobox:phoneNo,
                pep:politicalExposed,
                currentStatus:currentStatus,
                designationGrade:designation,
                pepCategory:category,
                departmentOrganization:pdepartment,
                pepFamilyMember:fMPoliticalExposed,
                pepFamilyMemberName:fMName,
                closeAssociate:fMRelation,
                closeAssociateCategory:fMCategory,
                closeAssociateDesignation:fMDesignation,
                closeAssociateDepartment:fMDepartment,
                osTaxResident:otherCountryTaxResident,
                anotherCountry:taxResidentCountry,
                closeAssociateCategory:fMCategory,
               
                osNationality:currentStatus,


          
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            credentials: 'include',
            withCredentials: true
          });
          let resJson = await res.json();
          if (res.status === 200) {
         
            setMessage(resJson.desc);
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }
      };

  
      const[getState,setState] = useState([]);
   
      const[selectedState,setSelectedState]=useState([]);
      const[cities,setCities]=useState([]);
   
      const country =[...new Set(data.map(item => item.country))];
      country.sort();
    

      const handleCountry=(e)=>{
       
          let states = data.filter(state=>state.country===e.target.value);
          
          setBirthCountry(e.target.value);
          states=[...new Set(states.map(item=>item.name))];
          states.sort();
        
          setCities(states);

      }


    return(
        <>
        <Navbar/>
        <br></br>
        <br></br>
        <br></br>

      
        <br></br>
       <center>
        <h3><strong>Additional Details</strong></h3>  
        </center>
        

    <form className="container mt-3 mb-3">
       <Row className="mb-3">         
            <br></br>
            <Form.Group controlId="incomeDetail" className="col col-sm-4">
                <Form.Label>Gross Annual Income (in PKR)</Form.Label>
                <Form.Select className="form-control font-weight-normal" name="gross_income" value={grossIncome} onChange={(e) => setGrossIncome(e.target.value)}>            
                <option value="">Select...</option>  
                <option value="J07">Upto Rs. 100,000</option>   
                <option value="J08">Rs. 100,001 - Rs. 250,000</option>   
                <option value="J09">Rs. 250,001 - Rs. 500,000</option>   
                <option value="J10">Above Rs. 500,000</option>   
                         
            </Form.Select>
            </Form.Group>
            <Form.Group controlId="incomeDetail" className="col col-sm-8">
            <Form.Label>Source Of Income</Form.Label>           
            <Form.Select className="form-control font-weight-normal" name="income_Source" value={incomeSource} onChange={(e) => handleShowOther(e)}>            
                <option value="">Select...</option>  
                <option value="salary">Salary</option>   
                <option value="business">Business</option>   
                <option value="pension">Pension</option>   
                <option value="inherited-fund">Inherited fund</option>   
                <option value="support-from-family-member">Support from family member</option>   
                <option value="rented-property">Rented property</option>
                <option value="agriculture">Agriculture</option>
                <option value="other">Other source</option>             
            </Form.Select>         
            </Form.Group>           
        </Row>
      
        { incomeSource === 'other' && (
        <div>
        <Row>
        <Form.Group controlId="formBasicEmail" className="col col-sm-12">
            <Form.Label>Please provide other source of income</Form.Label>
            <input type="text" onChange={(e) => setOtherIncomeSource(e.target.value)} value={otherIncomeSource} name="applicationId" className="form-control font-weight-normal" />
        </Form.Group>
        </Row>
        </div>
        )}

        <Row className="mb-3">     
        <br></br>
            <Form.Group controlId="SelectOccupation" className="col col-sm-12">
                <Form.Label>Occupation</Form.Label> 
                    <Form.Select className="form-control font-weight-normal" name="gross_income" value={occupation} onChange={(e) => handleShowOtherOccupation(e)}>
                        <option value="">Select...</option>  
                        <option value="P001">Agriculturist</option>
                        <option value="P002">Business</option>
                        <option value="P003">Business Executive</option>
                        <option value="P005">House Hold</option>
                        <option value="P006">House Wife</option>
                        <option value="P007">Industrialist</option>
                        <option value="P012">Professional</option>
                        <option value="P013">Retired Person</option>
                        <option value="P014">Service</option>
                        <option value="P015">Student</option>
                        <option value="P019">Govt. / Public Sector</option>
                        <option value="P999">Others</option>
                    </Form.Select> 
                    </Form.Group>

                    { occupation === 'P999' && (
                    <div>
                    <Row>
                    <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                    <Form.Label>Other Occupation (Specify)</Form.Label>
                    <input  type="text" onChange={(e)=>setOtherOccupation(e.target.value)} value={otherOccupation} name="occupNotINList" className="form-control font-weight-normal" />                
                    </Form.Group>
                    </Row>
                    </div>
                    )}

                   
        </Row>

        <Row className = "mb-3">
           
           <br></br>
           <Form.Group controlId="employerName" className = "col col-sm-6">
               <Form.Label>Name of Employer / Business</Form.Label>
               <input type = "text" onChange={(e)=>setEmployeeName(e.target.value)} value={employeeName} name="employee" className = "form-control font-weight-normal" />
              </Form.Group>
              <Form.Group controlId = "occupation" className="col col-sm-6">
            <Form.Label>Job Title / Designation</Form.Label>
            <input type = "text" onChange={(e)=>setJobTitle(e.target.value)} value={jobTitle} name="jobTitle" className="form-control font-weight-normal" />
            </Form.Group>
             
       </Row>

        <Row className = "mb-3">
           
            <br></br>
            <Form.Group controlId="incomeDetail" className="col col-sm-12">
            <Form.Label>Department</Form.Label>
            <input type = "text" onChange={(e)=>setDepartment(e.target.value)} value={department} name="department" className="form-control font-weight-normal" />
            </Form.Group>   
            <Form.Group controlId="incomeDetail" className="col col-sm-12"> 
               <Form.Label>Address of Employer / Business:</Form.Label>
               <input type = "text" onChange={(e)=>setEmployeeAddress(e.target.value)} value={employeeAddress} name="address" className = "form-control font-weight-normal" />
           </Form.Group>   
           
                
        </Row>

      

        <Row className = "mb-3">
          
            <br></br>
            <Form.Group controlId="employerName" className = "col col-sm-12">
                <Form.Label>Nature of business</Form.Label>
                <p className="sign-paragraph">Please share details about product or service line of your employer or business</p>
                <input type = "text" onChange={(e)=>setBusinessNature(e.target.value)} value={businessNature} name="employee" className = "form-control font-weight-normal" />
               </Form.Group>
               <Form.Group controlId="SelectOccupation" className="col col-sm-12">
                <Form.Label>Business Geographies (if applicable)</Form.Label> 
                <p className="sign-paragraph">Business geographies refers to any  country or region where your company or self business operates, markets or distributes products and services </p>
                    <Form.Select className="form-control font-weight-normal" name="gross_income" value={businessGeograhics} onChange={(e) => handleOtherGeographics(e)}>
                        <option value="">Select...</option>  
                        <option value="Sindh">Sindh</option>
                        <option value="Punjab">Punjab</option>
                        <option value="KPK">KPK</option>
                        <option value="Baluchistan">Baluchistan</option>
                        <option value="AlloverPakistan">All over Pakistan</option>
                        <option value="NotApplicable">Not Applicable</option>
                        <option value="other">Other (if any)</option>
                       
                    </Form.Select> 
                    </Form.Group>
        </Row>

        {showOtherGeographics === 'other' && (
        <div>
        <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Other Business Geographies</Form.Label>
              <input type = "text" onChange={(e)=>setOtherBusinessGeograhics(e.target.value)} value={otherBusinessGeograhics} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
        </Row>
        </div>)}
        <Row className = "mb-3">
          
          <br></br>
          
             <Form.Group controlId="SelectOccupation" className="col col-sm-12">
              <Form.Label>Type of counter-Parties (if applicable)</Form.Label> 
              <p className="sign-paragraph">Counterparty can refer to other party that participates in a financial transaction. This can include deals between individuals, governments, or any other organization </p>
                 <Form.Select className="form-control font-weight-normal" name="gross_income" value={counterParties} onChange={(e) => handleCounterParties(e)}>
                      <option value="">Select...</option>  
                      <option value="Individual">Individual</option>
                      <option value="PrivateOrganization">Private Organization</option>
                      <option value="GovernmentOrganization">Government Organization</option>
                      <option value="NGO/Trust">NGO/Trust</option>
                      <option value="NotApplicable">Not Applicable</option> 
                      <option value="other">Other (if any)</option>
                     
                  </Form.Select> 
                  </Form.Group>
         </Row>
         {showOtherCounterParties === 'other' && (<div>
        <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Other Counter-Parties </Form.Label>
              <input type = "text" onChange={(e)=>setOtherCounterParties(e.target.value)} value={otherCounterParties} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>
         </div>)}

        <Row className = "mb-3">
          
          <br></br>
          
             <Form.Group controlId="SelectOccupation" className="col col-sm-12">
              <Form.Label>Share details about your source of fund / wealth for stock investment</Form.Label> 
              <p className="sign-paragraph">The source of funds can be described as the origin of the monies being used by the applicant for investment. </p>

                  <Form.Select className="form-control font-weight-normal" name="gross_income" value={fundSource}  onChange={(e) => handleSourceOfIncome(e)}>
                      <option value="">Select...</option>  
                      <option value="ownInvestment">Own investment</option>
                      <option value="financialSupportPerson">Financial support person</option>
                      <option value="other">Other source of income / fund</option>
                  </Form.Select> 
                  </Form.Group>
      </Row>

      {
        fundSource === 'financialSupportPerson' && (
        <div>
       <Row className = "mb-3">   
          <br></br>
          <Form.Label>Financial support person details</Form.Label>
          <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Full name of financial support person</Form.Label>
              <p className="sign-paragraph">As per valid CNIC/NICOP/Passport </p>

              <input type = "text" onChange={(e)=>setFinancialPersonName(e.target.value)} value={financialPersonName} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

             <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Father / husband’s name </Form.Label>
              <p className="sign-paragraph">As per valid CNIC/NICOP/Passport </p>
              <input type = "text" onChange={(e)=>setFinancialPHFName(e.target.value)} value={financialPHFName} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

         </Row>

         <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Relationship with financial support person</Form.Label>
              <input type = "text" onChange={(e)=>setFinancialPRelation(e.target.value)} value={financialPRelation} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

             <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Identity Card Number of financial support person</Form.Label>
              <p className="sign-paragraph">i.e. CNIC/NICOP/POC </p>
              <input type = "text" onChange={(e)=>setFinancialPId(e.target.value)} value={financialPId} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

         </Row>

         <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Mobile number of financial support person</Form.Label>
              <input type = "text" onChange={(e)=>setFinancialPNo(e.target.value)} value={financialPNo} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

             <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Email address of financial support person</Form.Label>
              <input type = "text" onChange={(e)=>setFinancialPEmail(e.target.value)} value={financialPEmail} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

         </Row>

         <Row>
         <Form.Label>Share details about work and income of financial support person</Form.Label>
         <Form.Group controlId="SelectOccupation" className="col col-sm-12">
              <Form.Label>Occupation</Form.Label> 
                  <Form.Select className="form-control font-weight-normal" name="gross_income" value={financialPOccupation} onChange={(e) => handleOccupation(e)}>
                  
                  <option value="">Select...</option>  
                        <option value="P001">Agriculturist</option>
                        <option value="P002">Business</option>
                        <option value="P003">Business Executive</option>
                        <option value="P005">House Hold</option>
                        <option value="P006">House Wife</option>
                        <option value="P007">Industrialist</option>
                        <option value="P012">Professional</option>
                        <option value="P013">Retired Person</option>
                        <option value="P014">Service</option>
                        <option value="P015">Student</option>
                        <option value="P019">Govt. / Public Sector</option>
                        <option value="P999">Others</option>
                     
                  </Form.Select> 
                  </Form.Group>

                  { financialPOccupation === 'P999' &&(
                    <div>
                        <Form.Group controlId="employerName" className = "col col-sm-12">
                        <Form.Label>Other Occupation (Specify)</Form.Label>
                        <input type = "text" onChange={(e)=>setFinancialPOtherOccupation(e.target.value)} value={financialPOtherOccupation} name="employee" className = "form-control font-weight-normal" />
                        </Form.Group>  
                    </div>
                  )
                
                  }
         </Row>

         <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Name of Employer / Business</Form.Label>
              <input type = "text" onChange={(e)=>setFinancialPBusiness(e.target.value)} value={financialPBusiness} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

             <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Job Title / Designation</Form.Label>
              <input type = "text" onChange={(e)=>setFinancialPJobTitle(e.target.value)} value={financialPJobTitle} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

         </Row>

         <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Address of Employer / Business:</Form.Label>
              <input type = "text" onChange={(e)=>setFinancialPAddress(e.target.value)} value={financialPAddress} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

             <Form.Group controlId="employerName" className = "col col-sm-6">
              <Form.Label>Gross Annual Income (in PKR)</Form.Label>
              <input type = "text" onChange={(e)=>setFinancialPGrossIncome(e.target.value)} value={financialPGrossIncome} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>    

         </Row>

        </div>
      )
      }
 
      {
        fundSource === 'other' && (
        <div>
        <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Other source of fund</Form.Label>
              <input type = "text" onChange={(e)=>setOtherFundSource(e.target.value)} value={otherFundSource} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>

        </div>
      )
      }

          <Row className="mb-3">
           <Form.Label>Share details of your tax and residency status.</Form.Label>
            <Form.Group controlId="formBasicEmail" className="col col-sm-4">
            <Form.Label>Country of Birth</Form.Label>
            <Form.Select className="form-control font-weight-normal" name="mailingCountry" value={birthCountry} onChange={(e) => handleCountry(e)}>
                <option value="">Select...</option>
                {country.map(items => <option key={items} >{items}</option>)}
            </Form.Select>
            </Form.Group>

        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>City of Birth</Form.Label>
            <Form.Select defaultValue="Choose..." className="form-control font-weight-normal" name="mailingCity" value={birthCity} onChange={(e) => setBirthCity(e.target.value)}>
            <option value="">Select...</option>
            {cities.map(items => <option key={items} >{items}</option>)}    
                
            </Form.Select>
        </Form.Group>

        <Form.Group controlId="formGridState" className="col col-sm-4">
        <Form.Label>Nationality</Form.Label>
            <Form.Select className="form-control" name="mailingCountry" value={nationality} onChange={(e) => setNationality(e.target.value)}>
                <option value="">Select...</option>
                <option value="PAK">Pakistan</option>
                <option value="AS">American Samoa</option>
                <option value="GI">Gibraltar</option>
                <option value="TH">Thailand</option>
                <option value="GE">Georgia</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="SE">Sweden</option>
                <option value="SD">Sudan</option>
                <option value="TN">Tunisia</option>
                <option value="ER">Eritrea</option>
                <option value="SL">Sierra Leone</option>
                <option value="EC">Ecuador</option>
                <option value="DO">Dominican Republic</option>
                <option value="DJ">Djibouti</option>
                <option value="CY">Cyprus</option>
                <option value="CU">Cuba</option>
                <option value="RU">Russian Federation</option>
                <option value="SB">Solomon Islands</option>
                <option value="QA">Qatar</option>
                <option value="CR">Costa Rica</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="OM">Oman</option>
                <option value="PG">Papua New Guinea</option>
                <option value="NI">Nicaragua</option>
                <option value="AN">Netherlands Antilles</option>
                <option value="NZ">New Zealand</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="BG">Bulgaria</option>
                <option value="AF">Afghanistan</option>
                <option value="DZ">Algeria</option>
                <option value="KI">Kiribati</option>
                <option value="KP">Korea, North</option>
                <option value="AO">Angola</option>
                <option value="LV">Latvia</option>
                <option value="KW">Kuwait</option>
                <option value="LA">Lao Peoples Democratic Republic</option>
                <option value="LB">Lebanon</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libyan Arab Jamahiriya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="MK">Macedonia</option>
                <option value="BD">Bangladesh</option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="BB">Barbados</option>
                <option value="MY">Malaysia</option>
                <option value="BY">Belarus</option>
                <option value="MV">Maldives</option>
                <option value="MU">Mauritius</option>
                <option value="BT">Bhutan</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="BV">Bouvet Island</option>
                <option value="MA">Morocco</option>
                <option value="NA">Namibia</option>
                <option value="CN">China</option>
                <option value="UA">Ukraine</option>
                <option value="GT">Guatemala</option>
                <option value="GY">Guyana</option>
                <option value="VE">Venezuela</option>
                <option value="ZM">Zambia</option>
                <option value="JM">Jamaica</option>
                <option value="IQ">Iraq</option>
                <option value="JO">Jordan</option>
                <option value="CX">Christmas Island</option>
                <option value="TD">Chad</option>
                <option value="BF">Burkina Faso</option>
                <option value="BE">Belgium</option>
                <option value="AT">Austria</option>
                <option value="AU">Australia</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="BJ">Benin</option>
                <option value="CA">Canada</option>
                <option value="CF">Central African Republic</option>
                <option value="GA">Gabon</option>
                <option value="CC">Cocos Kelling Island</option>
                <option value="CG">Congo - Republic of</option>
                <option value="CK">Cook Islands</option>
                <option value="DM">Dominica</option>
                <option value="FO">Faroe Islands</option>
                <option value="FI">Finland</option>
                <option value="MQ">Martinique</option>
                <option value="GR">Greece</option>
                <option value="GU">Guam</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="NR">Nauru</option>
                <option value="PW">Palau</option>
                <option value="VC">Saint Vi-Grenadines</option>
                <option value="TW">Taiwan</option>
                <option value="TK">Tokelau</option>
                <option value="UG">Uganda</option>
                <option value="GB">United Kingdom</option>
                <option value="NO">Norway</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PT">Portugal</option>
                <option value="RO">Romania</option>
                <option value="LC">Saint Lucia</option>
                <option value="SN">Senegal</option>
                <option value="CH">Switzerland</option>
                <option value="YE">Yemen</option>
                <option value="VG">Virgin Islands - GB</option>
                <option value="AE">United Arab Emirates</option>
                <option value="TV">Tuvalu</option>
                <option value="HN">Honduras</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="FJ">Fiji</option>
                <option value="ET">Ethiopia</option>
                <option value="HT">Haiti</option>
                <option value="TO">Tonga</option>
                <option value="TJ">Tajikistan</option>
                <option value="GS">South Georgia - South Sandwich Islands</option>
                <option value="SC">Seychelles</option>
                <option value="SA">Saudi Arabia</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="TP">East Timor</option>
                <option value="DK">Denmark</option>
                <option value="CZ">Czech Republic</option> 
                <option value="RW">Rwanda</option>
                <option value="EG">Egypt</option>
                <option value="PA">Panama</option>
                <option value="CL">Chile</option>
                <option value="KY">Cayman Islands</option>
                <option value="BI">Burundi</option>
                <option value="MM">Myanmar</option>
                <option value="MZ">Mozambique</option>
                <option value="AL">Albania</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="AI">Anguilla</option>
                <option value="AR">Argentina</option>
                <option value="AW">Aruba</option>
                <option value="MO">Macau</option>
                <option value="LS">Lesotho</option>
                <option value="MT">Malta</option>
                <option value="ML">Mali</option>
                <option value="MH">Marshall Islands</option>
                <option value="BZ">Belize</option>
                <option value="BM">Bermuda</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="MN">Mongolia</option>
                <option value="NP">Nepal</option>
                <option value="TR">Turkey</option>
                <option value="GN">Guinea</option>
                <option value="KZ">Kazakhstan</option>
                <option value="HK">Hong Kong</option>
                <option value="VN">Vietnam</option>
                <option value="IL">Israel</option>
                <option value="IR">Iran Islamic Republic of</option>
                <option value="HU">Hungary</option>
                <option value="ZW">Zimbabwe</option>
                <option value="VU">Vanuatu</option>
                <option value="JP">Japan</option>
                <option value="AD">Andorra</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="GL">Greenland</option>
                <option value="GP">Guadeloupe</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IE">Ireland</option>
                <option value="MC">Monaco</option>
                <option value="FM">MicronesiaFederated</option>
                <option value="LU">Luxembourg</option>
                <option value="IT">Italy</option>
                <option value="DE">Germany</option>
                <option value="GD">Grenada</option>
                <option value="TC">Turks-Caicos Islands</option>
                <option value="UM">US Minor Outlying</option>
                <option value="KE">Kenya</option>
                <option value="YU">Yugoslavia</option>
                <option value="FK">Falkland Islands Malvinas</option>
                <option value="GH">Ghana</option>
                <option value="EE">Estonia</option>
                <option value="SZ">Swaziland</option>
                <option value="ZA">South Africa</option>
                <option value="HR">Croatia Hrvatska</option>
                <option value="SO">Somalia</option>
                <option value="CD">Congo-Brazzaville</option>
                <option value="KM">Comoros</option>
                <option value="PL">Poland</option>
                <option value="NG">Nigeria</option>
                <option value="CV">Cape Verde</option>
                <option value="NC">New Caledonia</option>
                <option value="AM">Armenia</option>
                <option value="MR">Mauritania</option>
                <option value="BO">Bolivia</option>
                <option value="BR">Brazil</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="SV">El Salvador</option>
                <option value="SR">Suriname</option>
                <option value="GM">Gambia</option>
                <option value="IS">Iceland</option>
                <option value="CO">Colombia</option>
                <option value="FR">France</option>
                <option value="YT">Mayotte</option>
                <option value="CI">Ivory Coast</option>
                <option value="NL">Netherlands</option>
                <option value="RE">Reunion</option>
                <option value="KN">Saint Kitts</option>
                <option value="NF">Norfolk Island</option>
                <option value="NU">Niue</option>
                <option value="VA">Vatican City State</option>
                <option value="VI">Virgin Islands - US</option>
                <option value="SM">San Marino</option>
                <option value="SI">Slovenia</option>
                <option value="WF">Wallis-Futuna Island</option>
                <option value="UZ">Uzbekistan</option>
                <option value="GF">French Guiana</option>
                <option value="TF">French Southern Terr</option>
                <option value="PF">French Polynesia</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="MS">Montserrat</option>
                <option value="HM">HeardMcDonald Island</option>
                <option value="MX">Mexico</option>
                <option value="NE">Niger</option>
                <option value="SJ">Svalbard-Jan Mayen</option>
                <option value="EH">Western Sahara</option>
                <option value="MP">North Mariana Island</option>
                <option value="PR">Puerto Rico</option>
                <option value="WS">Samoa</option>
                <option value="SG">Singapore</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="TM">Turkmenistan</option>
                <option value="TG">Togo</option>
                <option value="TZ">Tanzania</option>
                <option value="KR">Korea, South</option>
                <option value="US">United States</option>
                <option value="UY">Uruguay</option>
                <option value="SAT">SAMAO</option>
            </Form.Select>
            </Form.Group>
     </Row>

     <Row className = "mb-3">
       <Form.Group controlId="incomeDetail" className="col col-sm-12">
        <Form.Label>Are you a Tax Resident of any country other than Pakistan?</Form.Label>
        <Form.Select onChange={(e)=>handleOtherCountryResident(e)} value={otherCountryTaxResident} name= "remittance" className = "form-control font-weight-normal">
                  <option value="">Select...</option>  
                  <option value="yes">Yes</option>              
                  <option value="no">No</option>
              
                </Form.Select>
        </Form.Group>
       </Row>

       {otherCountryTaxResident === 'yes' &&(

        <div>
       <Row className = "mb-3">
       <Form.Group controlId="incomeDetail" className="col col-sm-12">
        <Form.Label>Country / Jurisdiction of Tax Residence </Form.Label>
    
                <Form.Select className="form-control font-weight-normal" name="mailingCountry" value={taxResidentCountry} onChange={(e)=>setTaxResidentCountry(e.target.value)}>
                <option value="">Select...</option>
                {country.map(items => <option key={items} >{items}</option>)}
            </Form.Select>

        </Form.Group>

        <Form.Group controlId="incomeDetail" className="col col-sm-12">
        <Form.Label>Do you have a Taxpayer Identification Number (“TIN”) or Equivalent Taxpayer Number? </Form.Label>
        <Form.Select onChange={(e)=>handleTinNumber(e)} value={taxpayerIdentificationNo} name= "remittance" className = "form-control font-weight-normal">
                  <option value="">Select...</option>  
                  <option value="yes">Yes</option>              
                  <option value="no">No</option>            
                </Form.Select>
        </Form.Group>
       </Row>
       </div>  )}

{
        taxpayerIdentificationNo === "yes" && (
            <div>
        <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Taxpayer Identification Number (“TIN”) or Equivalent Taxpayer Number</Form.Label>
              <input type = "text" onChange={(e)=>setTin(e.target.value)} value={tin} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>
            </div>
        )
     }

{ taxpayerIdentificationNo === "no" &&(
            <div>
               <Row className = "mb-3">   
               <Form.Group controlId="incomeDetail" className="col col-sm-12">
               <Form.Label>Reason for unavailability of TIN or Equivalent Taxpayer Number</Form.Label>
                <Form.Select onChange={(e)=>handleTinOption(e)} value={unavailableTin} name= "remittance" className = "form-control font-weight-normal">
                  <option value="">Select...</option>  
                  <option value="1">The country/jurisdiction where I reside does not issue TIN or Equivalent Taxpayer Number to its resident </option>              
                  <option value="2">I am unable to obtain a TIN or Equivalent Taxpayer Number </option>    
                  <option value="3">No TIN or Equivalent Taxpayer Numbe is required. Note: Only select this reason if the authorities of the country of tax residence do not require the TIN or Equivalent Taxpayer Number to be disclosed </option>            
                </Form.Select>
                </Form.Group>    
               </Row>  
           
          {unavailableTin === "2" && (
    <div>
        <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Please explain why you are unable to obtain a TIN or Equivalent Taxpayer Number?</Form.Label>
              <input type = "text" onChange={(e)=>setUnavailabilityReason(e.target.value)} value={unavailabilityReason} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>
    </div>
             ) }

           </div>
        )}

 

    
            <Row className = "mb-3">   
          <br></br>
          <Form.Label> For US status information  </Form.Label>
          <Form.Label> Are you a US Citizen / a US Resident / a US Green Card Holder </Form.Label>
           <Form.Group controlId = "usResident" className="col col-sm-12">
             <Form.Select onChange={(e)=>setUsCitizen(e.target.value)} value={usCitizen} name="usCitizen"  className = "form-control font-weight-normal">
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
             </Form.Select>
             </Form.Group>

             <Form.Group controlId="useResident" className="col col-sm-12">
                <Form.Label>Were you born in US?</Form.Label>
                <Form.Select onChange={(e)=>setUsBorn(e.target.value)}  value={usBorn} className = "form-control font-weight-normal" >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Form.Select>
                </Form.Group>

                <Form.Group controlId="useResident" className="col col-sm-12">
                <Form.Label>Do you have a US address or telephone number?</Form.Label>
                <Form.Select onChange={(e)=>setUsAddress(e.target.value)} value={usAddress} className = "form-control font-weight-normal" >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Form.Select>
                </Form.Group>

                <Form.Group controlId="useResident" className="col col-sm-12">
                <Form.Label>Do you have any Power of Attorney/ Authorized Signatory / Mandate holders having US address?</Form.Label>
                <Form.Select onChange={(e)=>setAttorneyPower(e.target.value)} value={attornyPower}  className = "form-control font-weight-normal" >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Form.Select>
                </Form.Group>

                <Form.Group controlId="useResident" className="col col-sm-12">
                <Form.Label>Will there be instructions to transfer funds to U.S.accounts or directions regularly received from a U.S.address?</Form.Label>
                <Form.Select onChange={(e)=>setUsAccount(e.target.value)} value={usAccount} className = "form-control font-weight-normal" >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Form.Select>
                </Form.Group>

                <Form.Group controlId="useResident" className="col col-sm-12">
                <Form.Label>Will there be address on file which is "in care of" or "hold mail" or U.S.P.O. Box and / or U.S.telephone number?</Form.Label>
                <Form.Select onChange={(e)=>setPhoneNo(e.target.value)} value={phoneNo}  className = "form-control font-weight-normal" >
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Form.Select>
                </Form.Group>
  
               </Row>
   
 
 
 <Row className= "mb-3">
 <Form.Label>Declaration of Politically Exposed Persons (PEPs)</Form.Label>
 <p className="sign-paragraph">Politically Exposed Persons (PEPs) are individuals who are or have been entrusted with prominent public functions either domestically/foreign country/ international organization. For example; Heads of State or of government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations/ department/autonomous bodies, includes family member (i.e.spouse, lineal ascendants / descendants and siblings of the PEP) or known close associate of an individual who entrusted with prominent public, including socially or professionally.</p>
  <Form.Group controlId="incomeDetail" className="col col-sm-12">
        <Form.Label>Are you a politically exposed person? </Form.Label>
        <Form.Select onChange={(e)=>handlePoliticalPerson(e)} value={politicalExposed} name= "remittance" className = "form-control font-weight-normal">
                  <option value="">Select...</option>  
                  <option value="yes">Yes</option>              
                  <option value="no">No</option>            
                </Form.Select>
        </Form.Group>
       </Row>

       {politicalExposed === "yes" && (
        <div>
            <Form.Label>Current Status</Form.Label>
            <Form.Select onChange={(e)=>setCurrentStatus(e.target.value)} value={currentStatus} name= "remittance" className = "form-control font-weight-normal">
                  <option value="">Select...</option>  
                  <option value="Currently holds position">Currently holds position </option>              
                  <option value="Previously held a position">Previously held a position</option>            
            </Form.Select> 

            <Form.Label>Category</Form.Label>
            <Form.Select onChange={(e)=>setCategory(e.target.value)} value={category} name= "remittance" className = "form-control font-weight-normal">
                  <option value="">Select...</option>  
                  <option value="Local ">Local </option>              
                  <option value="Foreign">Foreign</option>            
            </Form.Select> 

            <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Designation/Grade</Form.Label>
              <input type = "text" onChange={(e)=>setDesignation(e.target.value)} value={designation} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>

         <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Department/Organization</Form.Label>
              <input type = "text" onChange={(e)=>setPDepartment(e.target.value)} value={pdepartment} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>

        </div>
       )}


<Row>
  <Form.Group controlId="incomeDetail" className="col col-sm-12">
        <Form.Label>Is the customer close family member/ close associate, a politically exposed person? </Form.Label>
        <p className="sign-paragraph"> Family member includes (a spouse, lineal ascendants and descendants and siblings of the PEP) and  Close associate means,  an individual who is reasonably known to be closely connected with the PEP for any other reason, including socially or professionally. </p>
        <Form.Select onChange={(e)=>handleRelativePoliticalPerson(e)} value={fMPoliticalExposed} name= "remittance" className = "form-control font-weight-normal">
                  <option value="">Select...</option>  
                  <option value="yes">Yes</option>              
                  <option value="no">No</option>            
                </Form.Select>
        </Form.Group>
       </Row>

       {fMPoliticalExposed === "yes" && (
        <div>
            
            <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Name of the close family member/close associate (as per CNIC/NICOP/Passport)</Form.Label>
              <input type = "text" onChange={(e)=>setFMName(e.target.value)} value={fMName} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>
         <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Relationship with close family member/close associate</Form.Label>
              <input type = "text" onChange={(e)=>setFMRelation(e.target.value)} value={fMRelation} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>
       
        
            <Form.Label>Category</Form.Label>
            <Form.Select onChange={(e)=>setFMCategory(e.target.value)} value={fMCategory} name= "remittance" className = "form-control font-weight-normal">
                  <option value="">Select...</option>  
                  <option value="Local ">Local </option>              
                  <option value="Foreign">Foreign</option>            
            </Form.Select> 
     

            <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Designation / Grade of close family member/close associate</Form.Label>
              <input type = "text"  onChange={(e)=>setFMDesignation(e.target.value)} value={fMDesignation} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>

         <Row className = "mb-3">   
          <br></br>
          <Form.Group controlId="employerName" className = "col col-sm-12">
              <Form.Label>Department/Organization of close family member/close associate</Form.Label>
              <input type = "text" onChange={(e)=>setFMDepartment(e.target.value)} value={fMDepartment} name="employee" className = "form-control font-weight-normal" />
             </Form.Group>      
         </Row>

        </div>
       )}

   
       <div className="form-row row">
                <div className="col">
                    <input type="submit"  onClick={handleSubmit} className="btn btn-primary" />
                    <input type="button" value="Next" onClick={navigateToAccount}  className="btn btn-primary" />
                </div>
                </div>

                
   
                <div className="alert alert-success">
            {message}
           </div>
          <div className="alert alert-danger">
            {error}
        </div>

</form>

        </>
     
      
    )
    }
    export default AdditionalDetail;