import 'bootstrap/dist/css/bootstrap.min.css';  
import React, { useEffect, useState } from 'react';
import Animated from "./Animated.css";

import loginLogo from "./img/FSDGE/logo-login-left.png"
import {Routes, Route, useParams, useNavigate} from 'react-router-dom';
import { Form, InputGroup, Row, Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const linkInputStyle={
    width : "50%"


};
function AnimatedLogin(){
    
    const navigate = useNavigate();



      const navigateToSignUp = () => {     
        navigate('/SignUp');
      };
      const navigateToBasic = () => {
   
          navigate('/BasicDetails');
    
        
      };

      
      const [userId, setUserId] = useState("");
      const [password, setPassword] = useState("");
      const [accountType , setAccountType] = useState("normal");
      const [error, setError] = useState("");
      const [validateUser , setValidateUser] = useState('');
      const [validatePassword , setValidatePassword] = useState('');

      function validateForm (){
        setValidateUser("");
        setValidatePassword("");
        if(userId=="")
        {
            return  setValidateUser("Please Enter Your UserId");
        }
        if(userId != ""){
          let idPattern=  /^[0-9]{5}[0-9]{7}[0-9]$/;
          if(!idPattern.test(userId))
         {
          return  setValidateUser("Enter valid Uin Number ")
         }
         else{
          setValidateUser("")
         }
      }
        if(password=="")
        {
          return setValidatePassword("Please Enter Your Password")
        }
            return "Ok"; 
       }
      let handleSubmit = async (e) => {
        e.preventDefault();
       
        const result=validateForm();

        if(result=="Ok")
        {
        console.log("Inside handle Submit..");
        try {
    
            setError("");
     //  let res = await fetch("http://192.168.0.182:8085/sahulat/login", {
         let res = await fetch("https://sahulat.fsedge.com:8085/sahulat/login", {
            method: "POST",
            body: JSON.stringify({
                userId: userId,
                password: password,              

            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
              },
              credentials: 'include',
            withCredentials: true
          });
          let resJson = await res.json();
          if (res.status === 200) {
    
            setPassword("");
           
           // console.log( resJson.desc);
            if(resJson.responseCode == "00")
            {
            sessionStorage.setItem("userId" , userId);
            sessionStorage.setItem("accountType" , accountType  )
            
            navigateToBasic();
            }
            else{
              setError(resJson.desc);
            }
           
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
          setError("Server not responding");
        }}
      };

      const handleOtpVerification=()=>{
        navigate('/otpverification');
      };

      const handleOtpResend=()=>{
        navigate('/OtpResend');
      };

      const handleOtpRegenerate=()=>{
        navigate('/OtpRegenerate')
      };

      const handleApplicationStatus=()=>{
        navigate('/ApplicationStatus')
      };


 return(
        <>
  
        <div className="animated-Login">
        
        <form className="sign-animated">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        
       <div className="inner-form">
       
       <div className="signIn-logo" >
    
      

     

       </div>
      
       <div className="login-paragraph">
              <h4>Edge Sahulat Account</h4>
              <ul>
        
                <p>Sahulat Account is an opportunity for new individual resident Pakistani Customers, whether you are a student,housewife, novice investor or someone wanting to make an entry and invest in the stock exchange.</p>
                     
              </ul>

             <h6>Features:</h6>
             
              <ul>
                <li>
                <p> Simplified account opening process, open with CNIC only  </p>
                </li>
                <li>
                <p> No document required for source of income.</p>
                </li>
                <li>
                <p> Open an account with just PKR 5,000/-</p>
                </li>
                <li>
                <p> Maximum investment up to PKR 1,000,000</p>
                </li>
                
              </ul>
                    
             
           
             
            </div>
           

        <div className="signIn-content">

        <img className="sahulat-logo" src={loginLogo} alt={"logo"} width="200px" height="250px"  /> 
        <h4 className="SignIn-Heading">Sign In</h4>
            <br></br>
         
         
            <input  type="text" onChange={(e) => setUserId(e.target.value)} value={userId} className="input box-user-id" placeholder="UserId" style={linkInputStyle} required/>
           
            <p className="validateStyle">{validateUser}</p>

           
            <input  type="password" onChange={(e) => setPassword(e.target.value)} value={password}  className="input box-user-pwd" placeholder="Password" required/>
            <p className="validateStyle">{validatePassword}</p>
            
           
  
   
           
            <button type="submit" className="signIn-btn"  onClick={handleSubmit}>Login</button>
            <p className="validateStyle">{error}</p>
            
            <button type="button" className="btn-Reg signIn-btn" onClick={navigateToSignUp}>SignUp</button> 
            <p className="sign-paragraph">*For first time user</p>

            
            <p className="sign-paragraph"> For otp verification <a href = "#" onClick={handleOtpVerification} >Click Here</a> .</p>
            <p className="sign-paragraph"> For otp Resend <a href = "#" onClick={handleOtpResend} >Click Here</a> .</p>
            <p className="sign-paragraph"> For otp Regenerate <a href = "#" onClick={handleOtpRegenerate} >Click Here</a> .</p>
            <p className="sign-paragraph"> For application status <a href = "#" onClick={handleApplicationStatus} >Click Here</a> .</p>
        

        </div>
       </div>
      
       </form> 
       
       </div>
        </>
     
    )
    }
    export default AnimatedLogin;