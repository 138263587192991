import React from "react";
import { useEffect, useState } from "react";
import Login from "./input_forms/Login";
import SignUp from "./input_forms/SignUp";
import AccountDetail from "./input_forms/AccountDetail";
import AdditionalDetail from "./input_forms/AdditionalDetail";
import Logout from "./input_forms/Logout";
import NavbarBackUp from "./input_forms/NavbarBackUp";
import BasicDetailNormal from "./input_forms/BasicDetailNormal";
import ContactDetailNormal from "./input_forms/ContactDetailNormal";
import NomineeDetailNormal from "./input_forms/NomineeDetailNormal"
//import Login from "./input_forms/LoginFoundation";
import OtpRegenerate from "./input_forms/OtpRegenerate"
import OtpResend from  "./input_forms/OtpResend"
import otpverification from "./input_forms/OtpVerification";
import ApplicationStatus from "./input_forms/ApplicationStatus";
import userData from "./Admin_Panel/userData";
import OtpForm from "./Admin_Panel/OtpForm";


import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import BasicDetails from "./input_forms/BasicDetails";
import ContactDetails from "./input_forms/ContactDetails";
import AttorneyDetails from "./input_forms/AttorneyDetails";
import NomineeDetails from "./input_forms/NomineeDetails";
import OtherDetails from "./input_forms/OtherDetails";
import Report from "./input_forms/Report";
import PrintFormNormal from "./input_forms/PrintFormNormal"
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  
  Link
} from "react-router-dom";

import styled from "styled-components";

const NavUnlisted = styled.ul`
  text-decoration: none;
`;

const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  /*color: 'white'*/
  color: '#b29d3f'
};

function App() {
  return (
    <Router>
   

      
      <Routes >
        <Route  path="/" element={<PublicRoute component={Login}/>}/>
        <Route  path="/Login" element={<PublicRoute component={Login}/>}/>
        <Route  path="/SignUp" element={<PublicRoute component={SignUp}/>}/>
        <Route path="/Otpverification" element={<PublicRoute component={otpverification}/>}/> 
        <Route path="/OtpResend" element={<PublicRoute component={OtpResend}/>}/>
        <Route path="/ApplicationStatus" element={<PublicRoute component={ApplicationStatus}/>}/>
        <Route path="/OtpRegenerate" element={<PublicRoute component={OtpRegenerate}/>}/>
        <Route  path="/BasicDetails" element={<PrivateRoute component={BasicDetails}/>}/>
        <Route  path="/ContactDetails" element={<PrivateRoute component={ContactDetails}/>}/>
        <Route  path="/AttorneyDetails" element={<PrivateRoute component={AttorneyDetails }/>}/>
        <Route  path="/NomineeDetails" element={<PrivateRoute component={NomineeDetails }/>}/>
        <Route  path="/OtherDetails" element={<PrivateRoute component={OtherDetails }/>}/>
        <Route  path="/AccountDetail" element={<PrivateRoute component={AccountDetail}/>}/>
        <Route  path="/AdditionalDetail" element={<PrivateRoute component={AdditionalDetail}/>}/>
        <Route  path="/NomineeDetailNormal" element={<PrivateRoute component={NomineeDetailNormal }/>}/>
        <Route  path="/PrintFormNormal" element={<PrivateRoute component={PrintFormNormal }/>}/>
        <Route  path="/BasicDetailNormal" element={<PrivateRoute component={BasicDetailNormal }/>}/>
        <Route  path="/ContactDetailNormal" element={<PrivateRoute component={ContactDetailNormal}/>}/>
        <Route  path="/NavbarBackUp" element={<PrivateRoute component={NavbarBackUp}/>}/>
        <Route  path="/Report" element={<PrivateRoute component={Report}/>}/>
        <Route  path="/Logout" element={<PrivateRoute component={Logout}/>}/>
        <Route path = "/userData" element={<PublicRoute component={userData}/>}/>
        <Route path = "/userData/OtpForm" element = {<PublicRoute component={OtpForm}/>}/>
        
        

       
        
      </Routes>
      
    </Router>
  );
}

export default App;
