import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, InputGroup, Row, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from "./Navbar.js";

import Sidebar from "./Sidebar.js";
import ContactStyle from "./ContactStyle.css"

function NomineeDetails(){
    const navigate = useNavigate();

    const navigateToOther = () => {
    navigate('/AccountDetail');
      };

const [showHide , setShowHide] =useState('');      
const [disableInput , setDisableInput] = useState("true");
const [nominee , setNominee] = useState("");    
const [nomineeRelation , setNomineeRelation] = useState("");
const [nomineeName , setNomineeName] = useState("");
const [cnicType , setCnicType] = useState("");
const [nomineeCnic , setNomineeCnic] = useState("");
const [nomineeMobile , setNomineeMobile] = useState("");
const [nomineeAddress , setNomineeAddress] = useState("");
const [cnicLifeTimeExpiry , setCnicLifeTimeExpiry] = useState("");
const [cnicIssueDate , setCnicIssueDate] = useState();
const [cnicExpiryDate , setCnicEXpiryDate] = useState();
const [frontFile , setFrontFile] = useState();
const [backFile , setBackFile] = useState();
const [passportNo , setPassportNo] = useState();
const [placeOfIssue, setPlaceOfIssue] = useState();
const [dateOfIssue , setDateOfIssue] = useState();
const [dateOfExpiry , setDateOfExpiry] = useState();
const [nFHname , setNFHName] = useState();
const [nomineeFH ,setNomineeFH] = useState();
const [nameError , setNameError] = useState("");
const [uinError , setUinError] = useState("");
const [mobileError , setMobileError] = useState("");
const [addressError , setAddressError] = useState("");
const [showCnic , setShowCnic] = useState("");

const [message, setMessage] = useState("");
const [error, setError] = useState("");

const [nomineeError , setNomineeError] = useState("");

const fetchData = () => {
   fetch(`https://sahulat.fsedge.com:8085/sahulat/fetchNomineeDetails/${sessionStorage.getItem('userId')}`)
// fetch(`http://192.168.0.182:8085/sahulat/fetchNomineeDetails/${sessionStorage.getItem('userId')}`)
        .then(response => {
            if(response.status === 500){
                setDisableInput(false);
            }
          return response.json()
        })
        .then(data => {
            setNominee(data.nominee)
            setNomineeRelation(data.nomRelationship)
            setNomineeName(data.nomName)
            setNomineeCnic(data.nomUinDesc)
          
            setCnicLifeTimeExpiry(data.nomLifeTimeExpiry)
            setCnicIssueDate(data.nomDateOfIssue)
            setCnicEXpiryDate(data.nomDateOfExpiry)
            setPassportNo(data.nomPassportNo)

            setPlaceOfIssue(data.nomPlaceOfIssue)
            setDateOfIssue(data.nomDateOfIssue)
            setDateOfExpiry(data.nomDateOfExpiry)
            setNFHName(data.fatherHusbandName)

            setCnicType(data.nomUinType)
        
      
        })
    }

    useEffect(() => {
     
      fetchData()
      setShowHide(nominee);
        }, [])

function handleChange(e) {
    //console.log(e.target.files);
    //setFrontFile(URL.createObjectURL(e.target.files[0]));
  
    let cnicFront  = e.target.files;
    let cnicReader = new FileReader();
    cnicReader.readAsDataURL(cnicFront[0]);
    cnicReader.onload=(e)=>{
        setFrontFile(e.target.result);
    
}
}
function handleChange2(e) {
 //   setBackFile(URL.createObjectURL(e.target.files[0]));


    let cnicBack  = e.target.files;
    let cnicReader = new FileReader();
    cnicReader.readAsDataURL(cnicBack[0]);
    cnicReader.onload=(e)=>{
        setBackFile(e.target.result);
    
}
}

function handleShowHide  (e) {
 const getOption = e.target.value;

  setNominee(getOption);
 setShowHide(nominee);

}

function handleShowCnic  (e) {
    const getOption = e.target.value;

    setShowCnic(getOption);
    setCnicLifeTimeExpiry(getOption);
   }
function validation()
{
    setNomineeError("");
    setNameError("");
    setUinError("");
    setMobileError("");
    setAddressError("");

    if(nominee == ""){
     return setNomineeError("Select Nominee");
    }

    if(nomineeName != ""){
        let namePattern= /^[a-zA-Z]*/;
       if( !namePattern.test(nomineeName) )
       {
       return setNameError("Enter valid name")
       }
    }
  
    if(nomineeCnic != ""){
        let idPattern=  /^[0-9]{5}[0-9]{7}[0-9]$/;
        if(!idPattern.test(nomineeCnic))
       {
        return  setUinError("Enter valid Identity Card Number ")
       }
    }
   

    if(nomineeMobile != ""){
        let mobilePattern=  /^[0-9]+$/;       
        if(nomineeMobile.length>13){        
            return setMobileError("Enter valid mobile number") 
    }
    if(!mobilePattern.test(nomineeMobile))
        {
            return setMobileError("Enter valid mobile number") 
        }
    }

    if(nomineeAddress != ""){
        let addressPattern= /^([A-Za-z]|[0-9]){1,100}/;
       if(!addressPattern.test(nomineeAddress)){
             return setAddressError("Special characters not allowed");
       }
       }

    return "Ok";
}

let handleSubmit = async (e) => {
    e.preventDefault();
    const result = validation();
    if(result == "Ok")
    {
    console.log("Inside handle Submit..");

    try {
        setMessage("");
        setError("");
     let res = await fetch("https://sahulat.fsedge.com:8085/sahulat/saveNomineeInfo", {
     // let res = await fetch("http://192.168.0.182:8085/sahulat/saveNomineeInfo", {
        method: "POST",
        body: JSON.stringify({
            userId:  sessionStorage.getItem('userId'),
            nominee: nominee,
            nomRelationship: nomineeRelation,
            nomName: nomineeName,
            nomUinType: cnicType,
            nomUinDesc: nomineeCnic,
            nomMobile: nomineeMobile,
            nomAddress: nomineeAddress,
            nomLifeTimeExpiry: cnicLifeTimeExpiry,
            nomLifeTimeExpiryDate: cnicExpiryDate,
            frontFile: frontFile,
            backFile: backFile,
            nomPassportNo:passportNo,
            nomPlaceOfIssue:placeOfIssue,
            nomDateOfIssue:cnicIssueDate,
            nomDateOfExpiry:dateOfExpiry,
            fatherHusbandName:nFHname,
            nomineeCnicFront:frontFile,
            nomineeCnicBack:backFile,
            passportIssueDate:dateOfIssue,
            passportExpiryDate:dateOfExpiry,

         
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
            withCredentials: true
      });
      let resJson = await res.json();
      if (res.status === 200) {
       
        setMessage(resJson.desc);
      } else {
        setError("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }}
  };


   return(
        <>
        <Navbar/>
        <br></br>
        <br></br>
        <br></br>

       <br></br>
       <center>
        <h3><strong>Nominee Details</strong></h3>  
        </center>
        <form className="container mt-3 mb-3">
            <Row className="mb-3">
            <Form.Group controlId="incomeDetail" className="col col-sm-12">
                <Form.Label>Nominate a person for next of kin (Optional)</Form.Label>
                <p className="sign-paragraph">would you like to nominate a person for next of kin?</p>
                <Form.Select className="form-control font-weight-normal" name="investorName" value={nominee} onChange={(e) => handleShowHide(e)}>            
                <option value="">Select...</option>
                <option value="1">Yes</option> 
                <option value="2">No</option>              
            </Form.Select>
            <p className='validateStyle'>{nomineeError}</p>
            </Form.Group>
            </Row>
             <br></br>

           
             { nominee === '1' && (
              <div>
                <Row>
                <Form.Label>Enter your Nominee details</Form.Label>
           
                <Form.Group controlId="nomineeName" className="col col-sm-12">
                    <Form.Label>Name of nominee</Form.Label>
                    <p className="sign-paragraph">As per valid CNIC/NICOP</p>
                    <input type="text" placeholder='Please type your name '  onChange={(e) => setNomineeName(e.target.value)} value={nomineeName} name="nomineeName" className="form-control font-weight-normal" />
                    <p className='validateStyle'>{nameError}</p>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
  
                <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                <Form.Label>Father / Husband name</Form.Label>
                <p className="sign-paragraph">As per valid CNIC/NICOP</p>

                <input type="text" placeholder='Please type your name' onChange={(e) => setNFHName(e.target.value)} value={nFHname} name="applicationId" className="form-control font-weight-normal" />
                </Form.Group>
                </Row>

                <Row className="mb-3">
                <Form.Group controlId="incomeDetail" className="col col-sm-12">
                    <Form.Label>Relationship with applicant</Form.Label>
                    <p className="sign-paragraph">Nomination may be made in terms of requirements of Section 79 of the Companies Act, 2017, which inter alia requires that person nominated as aforesaid shall not be a person other than the relatives of the applicant.</p>
                    <Form.Select className="form-control font-weight-normal" name="nomineeRelation" value={nomineeRelation} onChange={(e) => setNomineeRelation(e.target.value)}>            
                    <option value="">Select...</option>
                    <option value="1">Spouse</option> 
                    <option value="2">Father</option>
                    <option value="3">Mother</option>
                    <option value="4">Brother</option>
                    <option value="5">Sister</option>
                    <option value="6">Daughter</option>
                    <option value="7">Son</option>              
                </Form.Select>
                    </Form.Group>
                   
                </Row>

           
 
                
            
                <Row className="mb-3">

                <Form.Group controlId="incomeDetail" className="col col-sm-4">
                    <Form.Label>Identity Card Type of nominee</Form.Label>
                    <p className="sign-paragraph"><br></br></p>

                    <Form.Select className="form-control font-weight-normal" name="cnicType" value={cnicType} onChange={(e) => setCnicType(e.target.value)}>            
                    <option value="">Select...</option>
                    <option value="CNIC">CNIC</option> 
                    <option value="SNIC">SNIC</option>
                    <option value="NICOP">NICOP</option>
                    <option value="POC">POC</option>
                           
                    </Form.Select>
                </Form.Group>
               
                    <Form.Group controlId="uin" className="col col-sm-8">
                    <Form.Label>Identity Card Number of nominee</Form.Label>
                    <p className="sign-paragraph">i.e. CNIC/NICOP</p>
                    <input type="text" placeholder='Id card format should be 00000-0000000-0' onChange={(e) => setNomineeCnic(e.target.value)} value={nomineeCnic} name="uin" className="form-control font-weight-normal" />
                    <p className='validateStyle'>{uinError}</p>
                    </Form.Group>

                  
                </Row>

                <Row className="mb-3">
              
                    <Form.Group controlId="cnicExpiryDate" className="col col-sm-6">
                    <Form.Label>Identity Card Date of Issue </Form.Label>
                    <input type="date" onChange={(e) => setCnicIssueDate(e.target.value)}  value={cnicIssueDate} className="form-control font-weight-normal" />

                    </Form.Group>
                </Row>
    
                <Row className="mb-3">
                <Form.Group controlId="cnicLifeTime" className="col col-sm-6">
                    <Form.Label>CNIC Life Time Expiry</Form.Label>
                    <Form.Select className="form-control font-weight-normal" name="cnicLifeTime" value={cnicLifeTimeExpiry} onChange={(e) => handleShowCnic(e)}>            
                    <option value="">Select...</option>
                    <option value="Y">Yes</option>
                    <option value="NULL">No</option>   
                </Form.Select>
                </Form.Group>
              
                   
                </Row>

                { cnicLifeTimeExpiry === "NULL" && (
                 <div>
                    <Row>
                     <Form.Group controlId="cnicExpiryDate" className="col col-sm-6">
                     <Form.Label>Identity Card Date of Expiry </Form.Label>

                     <input type="date" onChange={(e) => setCnicEXpiryDate(e.target.value)}  value={cnicExpiryDate} className="form-control font-weight-normal" />
                     </Form.Group>
                     </Row>
                     </div>
                )}
    
               
     
    
                <Row className="mb-3">
                <Form.Group controlId="incomeDetail" className="col col-sm-6">
                <Form.Label>Front Side</Form.Label>
                <p className="sign-paragraph">i.e. CNIC/NICOP</p>
                <p className="sign-paragraph">  Supported Formats: JPG, PNG, PDF; Max File Size: 5MB </p>
                <p className="sign-paragraph">  Do not upload password protected files </p>
                <input type="file" onChange={handleChange} />
                <img className="col-sm-6" src={frontFile} />
                </Form.Group>
    
                <Form.Group controlId="incomeDetail" className="col col-sm-6">
                <Form.Label>Back Side</Form.Label>
                <p className="sign-paragraph">i.e. CNIC/NICOP</p>
                <p className="sign-paragraph">  Supported Formats: JPG, PNG, PDF; Max File Size: 5MB </p>
                <p className="sign-paragraph">  Do not upload password protected files </p>
                <input type="file" onChange={handleChange2} />
                <img className="col-sm-6" src={backFile} />
                </Form.Group>
                </Row>

                <Row className="mb-3">
                <Form.Label>Nominee's passport details</Form.Label>
                <p className="sign-paragraph">In case of foreigner or a non-resident Pakistani </p>
                <Form.Group controlId="passport" className="col col-sm-6">
                    <Form.Label>Passport Number</Form.Label>
            
                    <input type="text" onChange={(e)=>setPassportNo(e.target.value)} value={passportNo} name="passportNo" className="form-control font-weight-normal"/>
                    
                    </Form.Group>
                    <Form.Group controlId="issuePlace" className="col col-sm-6">
                    <Form.Label>Place Of Issue</Form.Label>
                 
                    <input type="text" onChange={(e)=>setPlaceOfIssue(e.target.value)} value={placeOfIssue} name="placeOfIssue" className="form-control font-weight-normal"/>
    
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                <Form.Group controlId="issueDate" className="col col-sm-6">
                    <Form.Label>Date Of Issue</Form.Label>
                    <input type="date" onChange={(e) => setDateOfIssue(e.target.value)} value={dateOfIssue} className="form-control font-weight-normal" />

                    </Form.Group>
                    <Form.Group controlId="expiryDate" className="col col-sm-6">
                    <Form.Label>Date Of Expiry</Form.Label>

                    <input type="date" onChange={(e) => setDateOfExpiry(e.target.value)} value={dateOfExpiry} className="form-control font-weight-normal" />

                    </Form.Group>
                </Row>
             
          

                </div>

                
             )
             
             }

   
            <div className="form-row row">
                <div className="col">
                    <input type="submit" value="Save" onClick={handleSubmit} className="btn btn-primary" />
                    <input type="button" value="Next" onClick={navigateToOther}  className="btn btn-primary" />
                  
                </div>
                </div> 


            <div className="form-row row">
                <div className="col">
                    
                  
                    
                </div>
                </div>

                <div className="alert alert-success">
            {message}
           </div>
          <div className="alert alert-danger">
            {error}
        </div>
  
        </form>

        </>
     
    )
    }
    export default NomineeDetails;