import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";  
import Navbar from "./Navbar.js";
import Sidebar from "./Sidebar.js";
import ContactStyle from "./ContactStyle.css"
  
import "react-datepicker/dist/react-datepicker.css";  
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './style.css'
import { Form, InputGroup, Row, Button } from 'react-bootstrap';


function BasicDetails(){

    const navigate = useNavigate();

    const navigateToContacts = () => {
    navigate('/ContactDetails');
      };
      const fetchData = () => {
     fetch(`https://sahulat.fsedge.com:8085/sahulat/fetch/${sessionStorage.getItem('userId')}`)
    // fetch(`http://192.168.0.182:8085/sahulat/fetch/${sessionStorage.getItem('userId')}`)
          .then(response => {
            if(response.status === 500){
                setDisableInput(false);
            }
            return response.json()
           
          })
          .then(data => {
           // setUsers(data)
           setBdName(data.bdName);
           setBdUinTypeDesc(data.bdUinTypeDesc);
           setBdRelationshipName(data.bdRelationshipName);
           setBdMaritalStatus(data.bdMaritalStatus);
           setBdCnicLifeTimeExpiry(data.bdCnicLifeTimeExpiry)
           setBdCnicDateOfBirth(data.bdCnicDateOfBirth)
           setBdCnicExpiryDate(data.bdcnicexpirydate)
           setBdSalutation(data.bdSalutation);
           setBdRelationship(data.bdRelationship);
           setBdMotherName(data.bdMotherName);
           setBdGender(data.bdGender);
          })
      }

      useEffect(() => {
        setUserId( sessionStorage.getItem('userId'));
        fetchData()
      }, [])

    const [disableInput , setDisableInput] = useState("true");

    const [userId , setUserId] =useState("");
    const [bdUinTypeCode, setBdUinTypeCode] = useState("");
    const [bdUinTypeDesc, setBdUinTypeDesc] = useState("");
    const [bdSalutation, setBdSalutation] = useState("");
    const [bdName, setBdName] = useState("");

    const [bdRelationship, setBdRelationship] = useState("");
    const [bdRelationshipName, setBdRelationshipName] = useState("");
    const [bdNationality, setBdNationality] = useState("");
    const [bdMaritalStatus, setBdMaritalStatus] = useState("");
    const [bdMotherName , setBdMotherName] = useState("");
    const [bdGender , setBdGender] = useState("");

    const [bdCnicLifeTimeExpiry, setBdCnicLifeTimeExpiry] = useState("");
    const [bdCnicDateOfBirth, setBdCnicDateOfBirth] = useState(new Date());
    const [bdCnicExpiryDate, setBdCnicExpiryDate] = useState(new Date());
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [nameError , setNameError] = useState("");
    const [idError , setIdError] = useState("");
    const [mError , setMError] = useState("");
    const [rnError , setRnError] = useState(""); 
    const [showCnic , setShowCnic] = useState("");

    function handleShowHide  (e) {
        const getOption = e.target.value;
       
        setShowCnic(getOption);
        setBdCnicLifeTimeExpiry(getOption);
       }

    function validateForm (){
        setNameError("");
        setIdError("");
        setRnError("");
        if(bdUinTypeDesc=="")
        {
            return  setIdError("Id Card Should not be null")
            
        }
        if(bdUinTypeDesc != ""){
            let idPattern=  /^[0-9]{5}[0-9]{7}[0-9]$/;
            if(!idPattern.test(bdUinTypeDesc))
           {
            return  setIdError("Enter valid Identity Card Number ")
           }
           else {
            setIdError("")
           }
        }
        if(bdName =="") {
            return setNameError("Name should not be null")
            }
            if(bdName != ""){
                let namePattern= /^[a-zA-Z ]*$/;
               if( !namePattern.test(bdName) )
               {
               return setNameError("Enter valid name")
               }
               else {
                setNameError("")
               }
            }
     if(bdRelationshipName =="") {
        return setRnError("Name should not be null")
        }
        if(bdRelationshipName != ""){
            let namePattern= /^[a-zA-Z ]*$/;
           if( !namePattern.test(bdRelationshipName) )
           {
           return setRnError("Enter valid name")
           }
           else {
            setRnError("")
           }
        }

        if(bdMotherName =="") {
            return setMError("Name should not be null")
            }
            if(bdMotherName != ""){
                let namePattern= /^[a-zA-Z ]*$/;
               if( !namePattern.test(bdMotherName) )
               {
               return setMError("Enter valid name")
               }
               else {
                setMError("")
               }
            }
 
        return "Ok"; 
       }

    let handleSubmit = async (e) => {
        e.preventDefault();
        const result=validateForm();
        if(result == "Ok")
        {
        console.log("Inside handle Submit..");
       
        try {
            setMessage("");
            setError("");
      //   let res = await fetch("http://192.168.0.182:8085/sahulat/saveBasicDetails", {
          let res = await fetch("https://sahulat.fsedge.com:8085/sahulat/saveBasicDetails", {
            method: "POST",
            body: JSON.stringify({
                userId: sessionStorage.getItem('userId'),
                bdUinTypeCode: bdUinTypeCode,
                bdUinTypeDesc: bdUinTypeDesc,
                bdSalutation: bdSalutation,
                bdName: bdName,
                bdRelationship: bdRelationship,
                bdRelationshipName: bdRelationshipName,
                bdNationality: bdNationality,
                bdMaritalStatus:bdMaritalStatus,
                bdCnicLifeTimeExpiry:bdCnicLifeTimeExpiry,
                bdCnicDateOfBirth:bdCnicDateOfBirth,
                bdcnicexpirydate:bdCnicExpiryDate,
                bdMotherName:bdMotherName,
                bdGender:bdGender,

               
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
              },
              credentials: 'include',
            withCredentials: true
          });
          let resJson = await res.json();
          if (res.status === 200) {
        
            setMessage(resJson.desc);
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }
    }
      };
    return(
        <>
 <Navbar/>       
        <br></br>
        <br></br>
        <br></br>     
        <br></br>
        <center>
        <h3><strong>Personal Details</strong></h3>
        </center> 
        <br></br>
    <form className="container mt-3 mb-3">
        <Row className="mb-3">
           <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                <Form.Label>Identity Card Number</Form.Label>
                
                <p className="sign-paragraph">As per valid CNIC</p>
                 <input type="text" placeholder=' 00000-0000000-0' onChange={(e) => setBdUinTypeDesc(e.target.value)} value={bdUinTypeDesc} name="applicationId" className="form-control font-weight-normal"  />
                 <p className='validateStyle'>{idError}</p>
            </Form.Group>
        
        </Row>
      
    <Row className="mb-3">
      
       
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Mr./Mrs.</Form.Label>
            <p className="sign-paragraph"><br></br></p>
            <Form.Select className="form-control" name="a_state" value={bdSalutation} onChange={(e) => setBdSalutation(e.target.value)}>
                <option value="">Select...</option>
                <option value="MR">MR.</option>
                <option value="MRS">MRS.</option>
                <option value="MS">MS.</option>
                
            </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>Full Name of Applicant</Form.Label>
            <p className="sign-paragraph">As per valid CNIC</p>
            <input type="text" placeholder='Enter Here' onChange={(e) => setBdName(e.target.value)} value={bdName} name="applicationId" className="form-control font-weight-normal" />
            <p className='validateStyle'>{nameError}</p>
        </Form.Group>

    </Row>
    <Row className="mb-3">

    <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Father/Husband</Form.Label>
        
            <p className="sign-paragraph"><br></br></p>
            <Form.Select className="form-control" name="a_state" value={bdRelationship} onChange={(e) => setBdRelationship(e.target.value)}>
                <option value="">Select...</option>
                <option value="F">Father</option>
                <option value="H">Husband</option>
                
            </Form.Select>
        </Form.Group>
 

        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
            <Form.Label>Father/Husband's Name</Form.Label>
            <p className="sign-paragraph">As per valid CNIC</p>
            <input type="text" placeholder='Enter Here' onChange={(e) => setBdRelationshipName(e.target.value)} value={bdRelationshipName} name="applicationId" className="form-control font-weight-normal" />
            <p className='validateStyle'>{rnError}</p>
        </Form.Group>

    </Row>
    <Row className="mb-3">
            <Form.Group className="col col-sm-4">
                <Form.Label>Mother's Maiden Name</Form.Label>
                <input type="text" onChange={(e) => setBdMotherName(e.target.value) } value ={bdMotherName} className="form-control font-weight-normal"/>
                <p className='validateStyle'>{mError}</p>
            </Form.Group>

            <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Gender</Form.Label>
            <Form.Select className="form-control font-weight-normal" name="gender" value={bdGender} onChange={(e) => setBdGender(e.target.value)}>
                <option value="">Select...</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                
            </Form.Select>
        </Form.Group>

        </Row>
    <Row className="mb-3">

           <Form.Group controlId="formBasicEmail" className="col col-sm-4">
                <Form.Label>Date of Birth</Form.Label>

                <input type="date" onChange={(e) => setBdCnicDateOfBirth(e.target.value)} dateFormat="dd/MM/yyyy"  value={bdCnicDateOfBirth} className="form-control font-weight-normal" />

            </Form.Group>

            <Form.Group controlId="formBasicEmail" className="col col-sm-4">
                <Form.Label>Marital Status</Form.Label>
                <Form.Select className="form-control font-weight-normal" name="a_state" value={bdMaritalStatus} onChange={(e) => setBdMaritalStatus(e.target.value)}>
                
                <option value="">Select...</option>
                <option value="S">Single</option>
                <option value="M">Married</option>
                
            </Form.Select>
            </Form.Group>

        </Row>

        

        <Row className="mb-3">
            

             <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>CNIC Lifetime Expiry</Form.Label>
            <Form.Select className="form-control font-weight-normal" name="a_state" value={bdCnicLifeTimeExpiry} onChange={(e) => handleShowHide(e)}>
                <option value="">Select...</option>
                <option value="Y">Yes</option>
                <option value="NULL">No</option>
                
            </Form.Select>
        </Form.Group>

      
    </Row>
{bdCnicLifeTimeExpiry === "NULL" && (
    <div>
        <Row>
        <Form.Group controlId="formBasicEmail" className="col col-sm-8">
                <Form.Label>Identity Card Date of Expiry</Form.Label>
                <input type="date" onChange={(e) => setBdCnicExpiryDate(e.target.value)} dateFormat="DD/MM/YYYY"  value={bdCnicExpiryDate} className="form-control" />
            </Form.Group>
        </Row>
    </div>

)}
    <div className="form-row row">
                <div className="col">
                    <input type="submit"  value="Save" onClick={handleSubmit}  className="btn btn-primary" />
                    <input type="button" value="Next" onClick={navigateToContacts}  className="btn btn-primary" />
                </div>
                </div>

                <div className="alert alert-success">
            {message}
           </div>
          <div className="alert alert-danger">
            {error}
        </div>
</form>
  
        </>
     
      
    )
    }
    export default BasicDetails;