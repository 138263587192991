import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, InputGroup, Row, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Navbar from "./Navbar.js";
import Sidebar from "./Sidebar.js";
import ContactStyle from "./ContactStyle.css"

function ContactDetails(){
    const navigate = useNavigate();

    const navigateToAttorney = () => {
    navigate('/NomineeDetails');
      };

    const [disableInput , setDisableInput] = useState("true");
    const [mailingAddress , setMailingAddress] = useState('');
    const [mailingCountry , setMailingCountry] = useState('');
    const [mailingProvince , setMailingProvince] = useState('');
    const [mailingCity , setMailingCity] = useState('');
    const [otherCurrentAddress , setOtherCurrentAddress] = useState('');
    const [currentAddress ,setCurrentAddress] = useState('');
    const [currentCountry , setCurrentCountry] = useState('');
    const [currentProvince , setCurrentProvince] = useState('');
    const [currentCity , setCurrentCity] = useState('');
    const [otherPermanentAddress , setOtherPermanentAddress] = useState('');
    const [permanentAddress , setPermanentAddress] = useState('');
    const [permanentCountry , setPermanentCountry] = useState('');
    const [permanentProvince , setPermanentProvince] = useState('');
    const [permanentCity , setPermanentCity] = useState('');
    const [redPhoneNumber , setRedPhoneNumber] = useState('');
    const [offPhoneNumber , setOffPhoneNumber] = useState('');
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const [showCurrentAddress , setShowCurrentAddress] =useState(''); 
    const [showPermanentAddress , setShowPermanentAddress ] = useState('');

    const [mailingAddressError , setMailingAddressError] = useState("");
    const [mailingCountryError , setMailingCountryError] = useState("");
    const [mailingProvinceError , setMailingProvinceError] = useState("");
    const [mailingCityError , setMailingCityError] = useState("");
    const [redPhoneError , setRedPhoneError] = useState("");
    const [offPhoneError , setOffPhoneError] = useState("");

    const fetchData = () => {
     fetch(`https://sahulat.fsedge.com:8085/sahulat/fetchContactDetails/${sessionStorage.getItem('userId')}`)
 //  fetch(`http://192.168.0.182:8085/sahulat/fetchContactDetails/${sessionStorage.getItem('userId')}`)
          .then(response => {
            if(response.status === 500){
                setDisableInput(false);
            }
            return response.json()
          })
          .then(data => {
            setMailingAddress(data.cdMailingAddress)
            setMailingCountry(data.cdMailingCountry)
            setMailingProvince(data.cdMailingProvince)
            setMailingCity(data.cdMailingCity)
            
            setCurrentAddress(data.cdCurrentAddress)
            setCurrentCountry(data.cdCurrentCountry)
            setCurrentProvince(data.cdCurrentProvince)
            setCurrentCity(data.cdCurrentCity)

            setPermanentAddress(data.cdPermanentAddress)
            setPermanentCountry(data.cdPermanentCountry)
            setPermanentCity(data.cdPermanentProvince)
            setPermanentProvince(data.cdPermanentCity)
            setRedPhoneNumber(data.cdPhoneResidence)
            setOffPhoneNumber (data.cdPhoneOffice)

            setOtherCurrentAddress(data.currentAddress)
            setOtherPermanentAddress(data.permanentAddress)
        
          })
      }
      const[data,setData]=useState([]);

      const fetchCSC = () => {
        fetch("https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json")
            .then(response => {
                return response.json()
            })
            .then(data=>{
                setData(data);
              
            })
        
        }

      useEffect(() => {
        fetchCSC()
        fetchData()
        
      }, [])

    function handleRadioButton1  (e) {
        const getOption = e.target.value;
    
        setShowCurrentAddress(getOption);
        setOtherCurrentAddress(getOption);
        if(getOption == "same"){
            setCurrentAddress(mailingAddress);
            setCurrentCountry(mailingCountry);
            setCurrentProvince(mailingProvince);
            setCurrentCity(mailingCity);
        }
      /*  if(getOption == "other"){
            setCurrentAddress("");
            setCurrentCountry("");
            setCurrentProvince("");
            setCurrentCity("");
        }   */    
       }

       function handleRadioButton2  (e) {
        const getOption = e.target.value;
     
        setShowPermanentAddress(getOption);
        setOtherPermanentAddress(getOption);
        
        if (getOption == "sameMailingAddress"){
            setPermanentAddress(mailingAddress);
            setPermanentCountry(mailingCountry);
            setPermanentProvince(mailingProvince);
            setPermanentCity(mailingCity);
        }
        if(getOption == "currentResidentialAddress"){
            setPermanentAddress(currentAddress);
            setPermanentCountry(currentCountry);
            setPermanentProvince(currentProvince);
            setPermanentCity(currentCity);
        }
      /*  if(getOption == "other")
        {
            setPermanentAddress("");
            setPermanentCountry("");
            setPermanentProvince("");
            setPermanentCity("");
        }*/
       }

       function vaildation(){
        setMailingAddressError("");
        setMailingCountryError("");
        setMailingProvinceError("");
        setMailingCityError("");
        setRedPhoneError("");
        setOffPhoneError("");
 
        if(mailingAddress == ""){
            return setMailingAddressError("Mailing Address should not be null");
        }

       if(mailingAddress != ""){
        let addressPattern= /^[A-Za-z0-9 ]*$/;
       if(!addressPattern.test(mailingAddress)){
             return setMailingAddressError("Special characters not allowed");
       }
       if(mailingAddress.length>100)
          return setMailingAddressError("Address Length should not be greater than 100 characters");
      }
        else {
          setMailingAddressError("");
        }
       if(mailingCountry == ""){
        return setMailingCountryError("Select mailing country");
        }

       if(mailingProvince == ""){
        return setMailingProvinceError("Select mailing province");
       }

       if(mailingCity == ""){
        return setMailingCityError("Select mailing city");
    }

    if(redPhoneNumber != "" && redPhoneNumber != null){
        let mobilePattern=  /^[0-9]+$/;       
        if(redPhoneNumber.length>13){        
            return setRedPhoneError("Enter valid phone no");
    }
    if(!mobilePattern.test(redPhoneNumber))
        {
            return setRedPhoneError("Enter valid phone no");
        }
    }


    if(offPhoneNumber != "" && offPhoneNumber != null){
        let mobilePattern=  /^[0-9]+$/;       
        if(offPhoneNumber.length>13){        
         return setOffPhoneError("Enter valid phone no");
    }
    if(!mobilePattern.test(offPhoneNumber))
        {
          return setOffPhoneError("Enter valid phone no");
        }
    }



        return "Ok";
       }

    let handleSubmit = async (e) => {
        e.preventDefault();
        const result = vaildation();
        if(result == "Ok")
        {
        console.log("Inside handle Submit..");
        
        try {
            setMessage("");
            setError("");

       let res = await fetch("https://sahulat.fsedge.com:8085/sahulat/saveContactDetails", {
    //   let res = await fetch("http://192.168.0.182:8085/sahulat/saveContactDetails", {
            method: "POST",
            body: JSON.stringify({

                userId: sessionStorage.getItem('userId'),
                cdMailingAddress: mailingAddress,
                cdMailingCountry: mailingCountry,
                cdMailingCity: mailingCity,
                cdMailingProvince: mailingProvince, 
                cdCurrentAddress:currentAddress,
                cdCurrentCountry:currentCountry,
                cdCurrentProvince:currentProvince,
                cdCurrentCity:currentCity,         
                cdPermanentAddress:permanentAddress,              
                cdPermanentCountry:permanentCountry,
                cdPermanentProvince:permanentProvince,
                cdPermanentCity:permanentCity,              
                cdPhoneOffice:offPhoneNumber,
                cdPhoneResidence:redPhoneNumber,
                permanentAddress:otherPermanentAddress,
                currentAddress:otherCurrentAddress,

           
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
              },
              credentials: 'include',
            withCredentials: true
          });
          let resJson = await res.json();
          if (res.status === 200) {
          
            setMessage(resJson.desc);
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }}
      };
      const[getCountry , setCountry] = useState([]);
      const[getState,setState] = useState([]);
      const[getState1,setState1] = useState([]);
      const[getState2,setState2] = useState([]);
      const[selectedState,setSelectedState]=useState([]);
      const[cities,setCities]=useState([]);
      const[cities1,setCities1]=useState([]);
      const[cities2,setCities2]=useState([]);
      const country =[...new Set(data.map(item => item.country))];
      country.sort();
   

      const handleCountry=(e)=>{
        
          let states = data.filter(state=>state.country===e.target.value);
          
          setCurrentCountry(e.target.value);
          states=[...new Set(states.map(item=>item.subcountry))];
          states.sort();
       
          setState(states);

      }

      const handleState=(e)=>{
        let cities = data.filter(city=>city.subcountry===e.target.value);
       
        setCurrentProvince(e.target.value);
        cities=[...new Set(cities.map(item=>item.name))];
        setCities(cities);
        cities.sort()

      }

      const handleCountry1=(e)=>{
   
          let states = data.filter(state=>state.country===e.target.value);
        
          setMailingCountry(e.target.value);
          states=[...new Set(states.map(item=>item.subcountry))];
          states.sort();
       
          setState1(states);

      }

      const handleState1=(e)=>{
        let cities = data.filter(city=>city.subcountry===e.target.value);
      
        setMailingProvince(e.target.value);
        cities=[...new Set(cities.map(item=>item.name))];
        setCities1(cities);
        cities1.sort()

      }

      const handleCountry2=(e)=>{
      
          let states = data.filter(state=>state.country===e.target.value);
         
          setPermanentCountry(e.target.value);
          states=[...new Set(states.map(item=>item.subcountry))];
          states.sort();
         
          setState2(states);

      }

      const handleState2=(e)=>{
        let cities = data.filter(city=>city.subcountry===e.target.value);
       
        setPermanentProvince(e.target.value);
        cities=[...new Set(cities.map(item=>item.name))];
        setCities2(cities);
        cities2.sort()

      }
    return(
        <>
        <Navbar/>       
        <br></br>
        <br></br>
        <br></br>     
        <br></br>
        <center>
        <h3><strong>Contact Details</strong></h3>
        </center> 
  

        <form className="container mt-3 mb-3">
        <Row className="mb-3">
        <Form.Label>Share your address details</Form.Label>
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                <Form.Label>Mailing Address</Form.Label>
                 <input type="text" onChange={(e) => setMailingAddress(e.target.value)} value={mailingAddress} name="mailingAddress1" className="form-control font-weight-normal" />
                 <p className='validateStyle'>{mailingAddressError}</p>
            </Form.Group>
        
        </Row>
     
        <Row className="mb-3">

            
        <Form.Group controlId="formBasicEmail" className="col col-sm-4">
            <Form.Label> Country</Form.Label>
            <Form.Select className="form-control font-weight-normal"  name="mailingCountry" value={mailingCountry} onChange={(e) => handleCountry1(e)}>
                <option >Select...</option>
                {country.map(items => <option key={items} >{items}</option>)}
            </Form.Select>
            <p className='validateStyle'>{mailingCountryError}</p>
            </Form.Group>

        
           <Form.Group controlId="mailingState" className="col col-sm-4">
            <Form.Label> Province / State</Form.Label>
            <Form.Select  className="form-control font-weight-normal" value={mailingProvince} onChange={(e) => handleState1(e) }>
            { mailingCountry != 'Pakistan' && (
                <>
                <option >Select...</option>
                {getState1.map(items => <option key={items}  >{items}</option>)}
                </>
            )}
              { mailingCountry === 'Pakistan' && (
                <>
                <option value="">Select...</option>
                <option value="01">FATA / FANA</option>
                <option value="02">SINDH</option>
                <option value="03">PUNJAB</option>
                <option value="04">KHYBER PAKHTUNKHWA</option>
                <option value="05">BALOCHISTAN</option>
                <option value="06">FEDERAL CAPITAL</option>
                <option value="07">A.J.K.</option>
                </>
              )}
            </Form.Select>
            <p className='validateStyle'>{mailingProvinceError}</p>
        </Form.Group>

     

           

        <Form.Group controlId="mailingCity" className="col col-sm-4">
            <Form.Label>City / Town / Village</Form.Label>
            <Form.Select className="form-control font-weight-normal"  value={mailingCity} onChange={(e) => setMailingCity(e.target.value)}>
          
            { mailingCountry != 'Pakistan' && (
             <>
              <option >Select...</option>
              {cities1.map(items => <option key={items} >{items}</option>)} 
              </>
            )} 

               { mailingProvince === '07' && (
                <>
                  <option value="">Select...</option>
                 <option value="0001">BAGH</option>
                <option value="0002">BHIMBER</option>
                <option value="0003">DHUNGI</option>
                <option value="0004">KHUIRATTA</option>
                <option value="0005">KOTLI</option>
                <option value="0006">MANGLA</option>
                <option value="0007">MIRPUR</option>
                <option value="0008">MUZAFFARABAD</option>
                <option value="0009">PLANDRI</option>
                <option value="0010">PUNCH</option>
                <option value="0011">RAWALAKOT</option>
                <option value="401">TANDLIANWALA</option>
                <option value="0402">DADYAL</option>
                <option value="0403">NEELUM</option>
                <option value="0398">SUDHNOTI</option>
                </>
              )}

             { mailingProvince === '06' && (
                <>
                  <option value="">Select...</option>
                  <option value="0132">ISLAMABAD</option>
                </>
              )}

          
             { mailingProvince === '02' && (
                <>
                  <option value="">Select...</option>
                  <option value="0307">ALI BANDAR</option>
                <option value="0308">BADEN</option>
                <option value="0309">CHACHRO</option>
                <option value="0310">DADU</option>
                <option value="0311">DAHARKI</option>
                <option value="0312">DIGRI</option>
                <option value="0313">DIPLO</option>
                <option value="0314">DOKRI</option>
                <option value="0315">GADRA</option>
                <option value="0316">GHANIAN</option>
                <option value="0317">GHAUSPUR</option>
                <option value="0318">GHOTKI</option>
                <option value="0319">GOTH MACHI</option>
                <option value="0320">HALA (SINDH) HALA</option>
                <option value="0321">HYDERABAD</option>
                <option value="0322">ISLAMKOT</option>
                <option value="0323">JACOBABAD</option>
                <option value="0324">JAMESABAD</option>
                <option value="0325">JAMSHORO</option>
                <option value="0326">JANGHAR</option>
                <option value="0327">JATI</option>
                <option value="0328">JHUDO</option>
                <option value="0329">JUNGSHAHI</option>
                <option value="0330">KAMBER</option>
                <option value="0331">KANDIARO</option>
                <option value="0332">KARACHI</option>
                <option value="0333">KASHMOR</option>
                <option value="0334">KETI BANDAR</option>
                <option value="0335">KHAIRPUR</option>
                <option value="0336">KHOKHROPUR</option>
                <option value="0337">KHORA</option>
                <option value="0338">KLUPRO</option>
                <option value="0339">KOT SARAE</option>
                <option value="0340">KOTRI</option>
                <option value="0341">LARKANA</option>
                <option value="0342">LUND</option>
                <option value="0343">MATHI</option>
                <option value="0344">MATIARI</option>
                <option value="0345">MEHAR</option>
                <option value="0346">MIRPUR BATORO</option>
                <option value="0347">MIRPUR KHAS</option>
                <option value="0348">MIRPUR SAKRO</option>
                <option value="0349">MITHANI</option>
                <option value="0350">MITHI</option>
                <option value="0351">MORO</option>
                <option value="0352">NAGAR PARKAR</option>
                <option value="0353">NAOKOT</option>
                <option value="0354">NAUDERO</option>
                <option value="0355">NAUSHARA</option>
                <option value="0356">NAUSHERO FEROZE</option>
                <option value="0357">NAWABSHAH</option>
                <option value="0358">NAZIMABAD</option>
                <option value="0359">MOIN JO DARO</option>
                <option value="0360">PENDOO</option>
                <option value="0361">POKRAN</option>
                <option value="0362">QAMBAR</option>
                <option value="0363">QAZI AHMAD</option>
                <option value="0364">RANIPUR</option>
                <option value="0365">RATODERO</option>
                <option value="0366">ROHRI</option>
                <option value="0367">SAIDU SHARIF</option>
                <option value="0368">SAKRAND</option>
                <option value="0369">SANGHAR</option>
                <option value="0370">SHADADKHOT</option>
                <option value="0371">SHAHBANDAR</option>
                <option value="0372">SHAHDADPUR</option>
                <option value="0373">SHAHPUR CHAKAR</option>
                <option value="0374">SHIKARPUR</option>
                <option value="0375">SUJAWAL</option>
                <option value="0376">SUKKUR</option>
                <option value="0377">TANDO ADAM</option>
                <option value="0378">TANDO ALAH YAR</option>
                <option value="0379">TANDO BAGO</option>
                <option value="0380">TAR AHAMD RIND</option>
                <option value="0381">THARPARKAR</option>
                <option value="0382">THATTA</option>
                <option value="0383">TUJAL</option>
                <option value="0384">UMARKOT</option>
                <option value="0385">VEIRWARO</option>
                <option value="0386">WARAH</option>
                <option value="0387">MIRPUR MATHELO</option>
                <option value="0388">PANOAKIL</option>
                <option value="0389">NANKANA SAHIB</option>
                <option value="0390">GOJRA</option>
                <option value="0391">JARANWALA</option>
                <option value="0392">CHUNIA</option>
                <option value="0393">KANDHKOT</option>
                <option value="0394">KHAIRPUR MIRIS</option>
                <option value="0395">UBARO</option>
                <option value="0396">TANDO MUHAMMAD KHAN</option>
                <option value="0397">MATLI</option>
                </>
              )}

            { mailingProvince === '03' && (
                <>
                <option value="">Select...</option>
                <option value="0199">AHMED NAGER CHATHA</option>
                <option value="0200">AHMEDPUR EAST</option>
                <option value="0201">ALI PUR</option>
                <option value="0202">ARIFWALA</option>
                <option value="0203">ATTOCK</option>
                <option value="0204">BAHAWALNAGAR</option>
                <option value="0205">BAHAWALPUR</option>
                <option value="0206">BASTI MALOOK</option>
                <option value="0207">BHAGALCHUR</option>
                <option value="0208">BHAIPHERU</option>
                <option value="0209">BHAKKAR</option>
                <option value="0210">BHALWAL</option>
                <option value="0211">BUREWALA</option>
                <option value="0212">CHAILIANWALA</option>
                <option value="0213">CHAKWAL</option>
                <option value="0214">CHICHAWATNI</option>
                <option value="0215">CHINIOT</option>
                <option value="0216">CHOWK AZAM</option>
                <option value="0217">CHOWK SARWAR SHAHEED</option>
                <option value="0218">DARYA KHAN</option>
                <option value="0219">DASKA</option>
                <option value="0220">DERA GHAZI KHAN</option>
                <option value="0221">DERAWAR FORT</option>
                <option value="0222">DHAULAR</option>
                <option value="0223">DINA CITY</option>
                <option value="0224">DINGA</option>
                <option value="0225">DIPALPUR</option>
                <option value="0226">FAISALABAD</option>
                <option value="0227">FATEH JANG</option>
                <option value="0228">GADAR</option>
                <option value="0229">GHAKHAR MANDI</option>
                <option value="0230">GUJAR KHAN</option>
                <option value="0231">GUJRANWALA</option>
                <option value="0232">GUJRAT</option>
                <option value="0233">HAFIZABAD</option>
                <option value="0234">HAROONABAD</option>
                <option value="0235">HASILPUR</option>
                <option value="0236">HAVELI LAKHA</option>
                <option value="0237">JAHANIA</option>
                <option value="0238">JALLA ARAAIN</option>
                <option value="0239">JAMPUR</option>
                <option value="0240">JAUHARABAD</option>
                <option value="0241">JHANG</option>
                <option value="0242">JHELUM</option>
                <option value="0243">KALABAGH</option>
                <option value="0244">KAMALIA</option>
                <option value="0245">KAMOKEY</option>
                <option value="0246">KAROR LAL ESAN</option>
                <option value="0247">KASUR</option>
                <option value="0248">KHANEWAL</option>
                <option value="0249">KHANPUR</option>
                <option value="0250">KHARIAN</option>
                <option value="0251">KHUSHAB</option>
                <option value="0252">KOT ADDU</option>
                <option value="0253">LAAR</option>
                <option value="0254">LAHORE</option>
                <option value="0255">LALAMUSA</option>
                <option value="0256">LAYYAH</option>
                <option value="0257">LODHRAN</option>
                <option value="0258">MAILSI</option>
                <option value="0259">MAKHDOOM AALI</option>
                <option value="0260">MAMOORI</option>
                <option value="0261">MANDI BAHAUDDIN</option>
                <option value="0262">MANDI WARBURTON</option>
                <option value="0263">MIAN CHANNU</option>
                <option value="0264">MIANWALI</option>
                <option value="0265">MINAWALA</option>
                <option value="0266">MULTAN</option>
                <option value="0267">MURIDKE</option>
                <option value="0268">MURREE</option>
                <option value="0269">MUZAFFARGARH</option>
                <option value="0270">NAROWAL</option>
                <option value="0271">OKARA</option>
                <option value="0272">PAK PATTAN</option>
                <option value="0273">PANJGUR</option>
                <option value="0274">PATTOKI</option>
                <option value="0275">PIRMAHAL</option>
                <option value="0276">QILA DIDAR SINGH</option>
                <option value="0277">RABWAH</option>
                <option value="0278">RAHIM YAR KHAN</option>
                <option value="0279">RAIWIND</option>
                <option value="0280">RAJAN PUR</option>
                <option value="0281">RAWALPINDI</option>
                <option value="0282">RENALA KHURD</option>
                <option value="0283">ROHRI</option>
                <option value="0284">SADIQABAD</option>
                <option value="0285">SAFDARABAD-DABANSING</option>
                <option value="0286">SAHIWAL</option>
                <option value="0287">SAMBERIAL</option>
                <option value="0288">SANGLA HILL</option>
                <option value="0289">SARAI ALAMGIR</option>
                <option value="0290">SARGODHA</option>
                <option value="0291">SHAFQAT SHAHED CHOWK</option>
                <option value="0292">SHAKARGARH</option>
                <option value="0293">SHARAQPUR</option>
                <option value="0294">SHEIKHUPURA</option>
                <option value="0295">SIALKOT</option>
                <option value="0296">SOHAWA</option>
                <option value="0297">SOOIANWALA</option>
                <option value="0298">SUNDAR</option>
                <option value="0299">TAKHTBAI</option>
                <option value="0300">TALAGANG</option>
                <option value="0301">TARBELA</option>
                <option value="0302">TAXILA</option>
                <option value="0303">TOBA TEK SINGH</option>
                <option value="0304">VEHARI</option>
                <option value="0305">WAH CANTT</option>
                <option value="0306">WAZIRABAD</option>
                <option value="0404">HATTIAN BALA</option>
                </>
              )}

             { mailingProvince === '01' && (
                <>
                  <option value="">Select...</option>
                  <option value="0179">ASTOR</option>
                <option value="0180">BAJAUR</option>
                <option value="0181">BARAMULA</option>
                <option value="0182">DIR</option>
                <option value="0183">GILGIT</option>
                <option value="0184">HANGU</option>
                <option value="0185">KHYBER</option>
                <option value="0186">KURRAM</option>
                <option value="0187">MALAKAND</option>
                <option value="0188">MIRAM SHAH</option>
                <option value="0189">MOHMAND</option>
                <option value="0190">NAGAR</option>
                <option value="0191">NORTH WAZIRISTAN</option>
                <option value="0192">PARACHINAR</option>
                <option value="0193">PEACOCK</option>
                <option value="0194">SHANDUR</option>
                <option value="0195">SHANGRILA</option>
                <option value="0196">SKARDU</option>
                <option value="0197">SOUTH WAZIRISTAN</option>
                <option value="0198">WANA</option>
                <option value="0400">HUNZA</option>
                </>
              )} 

             { mailingProvince === '04' && (
                <>
                <option value="">Select...</option>
                <option value="0133">ABBOTTABAD</option>
                <option value="0134">ADEZAI</option>
                <option value="0135">AYUBIA</option>
                <option value="0136">BANDA DAUD SHAH</option>
                <option value="0137">BANNU</option>
                <option value="0138">BATAGRAM</option>
                <option value="0139">BIROTE</option>
                <option value="0140">BUNER</option>
                <option value="0141">CHAKDARA</option>
                <option value="0142">CHARSADDA</option>
                <option value="0143">CHITRAL</option>
                <option value="0144">DARGAI</option>
                <option value="0145">DARYA KHAN</option>
                <option value="0146">DERA ISMAIL KHAN</option>
                <option value="0147">DRASAN</option>
                <option value="0148">DROSH</option>
                <option value="0149">HANGU</option>
                <option value="0150">HARIPUR</option>
                <option value="0151">KALAM</option>
                <option value="0152">KARAK</option>
                <option value="0153">KHANASPUR</option>
                <option value="0154">KOHAT</option>
                <option value="0155">KOHISTAN</option>
                <option value="0156">LAKKI MARWAT</option>
                <option value="0157">LATAMBER</option>
                <option value="0158">LOWER DIR</option>
                <option value="0159">MADYAN</option>
                <option value="0160">MALAKAND</option>
                <option value="0161">MANSEHRA</option>
                <option value="0162">MARDAN</option>
                <option value="0163">MASTUJ</option>
                <option value="0164">MONGORA</option>
                <option value="0165">NOWSHERA</option>
                <option value="0166">PAHARPUR</option>
                <option value="0167">PESHAWAR</option>
                <option value="0168">SAIDU SHARIF</option>
                <option value="0169">SAKESAR</option>
                <option value="0170">SHANGLA</option>
                <option value="0171">SWABI</option>
                <option value="0172">SWAT</option>
                <option value="0173">TANGI</option>
                <option value="0174">TANK</option>
                <option value="0175">THALL</option>
                <option value="0176">TIMARGARA</option>
                <option value="0177">TORDHER</option>
                <option value="0178">UPPER DIR</option>
                <option value="0399">HASAN ABDAL</option>
                </>
              )}

                { mailingProvince === '05' && (
                <>
                <option value="">Select...</option>
                <option value="0012">AMIR CHAH</option>
                <option value="0013">BAZDAR</option>
                <option value="0014">BELA</option>
                <option value="0015">BELLPAT</option>
                <option value="0016">BHAG</option>
                <option value="0017">BURJ</option>
                <option value="0018">CHAGAI</option>
                <option value="0019">CHAH SANDAN</option>
                <option value="0020">CHAKKU</option>
                <option value="0021">CHAMAN</option>
                <option value="0022">CHHATR</option>
                <option value="0023">DALBANDIN</option>
                <option value="0024">DERA BUGTI</option>
                <option value="0025">DHANA SAR</option>
                <option value="0026">DIWANA</option>
                <option value="0027">DUKI</option>
                <option value="0028">DUSHI</option>
                <option value="0029">DUZAB</option>
                <option value="0030">GAJAR</option>
                <option value="0031">GANDAVA</option>
                <option value="0032">GARHI KHAIRO</option>
                <option value="0033">GARRUCK</option>
                <option value="0034">GHAZLUNA</option>
                <option value="0035">GIRDAN</option>
                <option value="0036">GULISTAN</option>
                <option value="0037">GWADAR</option>
                <option value="0038">GWASH</option>
                <option value="0039">HAB CHAUKI</option>
                <option value="0040">HAMEEDABAD</option>
                <option value="0041">HARNAI</option>
                <option value="0042">HINGLAJ</option>
                <option value="0043">HOSHAB</option>
                <option value="0044">HUB</option>
                <option value="0045">ISPIKAN</option>
                <option value="0046">JAFFERABAD</option>
                <option value="0047">JHAL</option>
                <option value="0048">JHAL JHAO</option>
                <option value="0049">JHAL MAGSI</option>
                <option value="0050">JHATPAT</option>
                <option value="0051">JIWANI</option>
                <option value="0052">KALANDI</option>
                <option value="0053">KALAT</option>
                <option value="0054">KAMARAROD</option>
                <option value="0055">KANAK</option>
                <option value="0056">KANDI</option>
                <option value="0057">KANPUR</option>
                <option value="0058">KAPIP</option>
                <option value="0059">KAPPAR</option>
                <option value="0060">KARODI</option>
                <option value="0061">KATURI</option>
                <option value="0062">KHARAN</option>
                <option value="0063">KHUZDAR</option>
                <option value="0064">KIKKI</option>
                <option value="0065">KOHAN</option>
                <option value="0066">KOHLU</option>
                <option value="0067">KORAK</option>
                <option value="0068">LAHRI</option>
                <option value="0069">LASBELA</option>
                <option value="0070">LIARI</option>
                <option value="0071">LORALAI</option>
                <option value="0072">MACH</option>
                <option value="0073">MAND</option>
                <option value="0074">MANGUCHAR</option>
                <option value="0075">MASHKI CHAH</option>
                <option value="0076">MASLTI</option>
                <option value="0077">MASTUNG</option>
                <option value="0078">MEKHTAR</option>
                <option value="0079">MERUI</option>
                <option value="0080">MIANEZ</option>
                <option value="0081">MURGHA KIBZAI</option>
                <option value="0082">MUSA KHEL BAZAR</option>
                <option value="0083">NAGHA KALAT</option>
                <option value="0084">NAL</option>
                <option value="0085">NASEERABAD</option>
                <option value="0086">NAUROZ KALAT</option>
                <option value="0087">NUR GAMMA</option>
                <option value="0088">NUSHKI</option>
                <option value="0089">NUTTAL</option>
                <option value="0090">ORMARA</option>
                <option value="0091">PALANTUK</option>
                <option value="0092">PANJGUR</option>
                <option value="0093">PASNI</option>
                <option value="0094">PIHARAK</option>
                <option value="0095">PISHIN</option>
                <option value="0096">QAMRUDDIN KAREZ</option>
                <option value="0097">QILA ABDULLAH</option>
                <option value="0098">QILA LADGASHT</option>
                <option value="0099">QILA SAFED</option>
                <option value="0100">QILA SAIFULLAH</option>
                <option value="0101">QUETTA</option>
                <option value="0102">RAKHNI</option>
                <option value="0103">ROBAT THANA</option>
                <option value="0104">RODKHAN</option>
                <option value="0105">SAINDAK</option>
                <option value="0106">SANJAWI</option>
                <option value="0107">SARUNA</option>
                <option value="0108">SHABAZ KALAT</option>
                <option value="0109">SHAHPUR</option>
                <option value="0110">SHARAM JOGIZAI</option>
                <option value="0111">SHINGAR</option>
                <option value="0112">SHORAP</option>
                <option value="0113">SIBI</option>
                <option value="0114">SONMIANI</option>
                <option value="0115">SPEZAND</option>
                <option value="0116">SPINTANGI</option>
                <option value="0117">SUI</option>
                <option value="0118">SUNTSAR</option>
                <option value="0119">SURAB</option>
                <option value="0120">THALO</option>
                <option value="0121">TUMP</option>
                <option value="0122">TURBAT</option>
                <option value="0123">UMARAO</option> 
                <option value="0124">UTHAL</option>
                <option value="0125">VITAKRI</option>
                <option value="0126">WADH</option>
                <option value="0127">WASHAP</option>
                <option value="0128">WASJUK</option>
                <option value="0129">YAKMACH</option>
                <option value="0130">ZHOB</option>
                <option value="0131">ZIARAT</option>
                </>
              )}



            </Form.Select>
            <p className='validateStyle'>{mailingCityError}</p>
        </Form.Group>

        
     </Row>



        <Row className="mb-3">
        <Form.Label>Current Residential Address</Form.Label>
        <Form.Group controlId="formGridState" className="col col-sm-4">
            
            <input type="radio"  value="same" onChange={(e) => handleRadioButton1(e)} name="currentMailingAddress" /> Same as Mailing Address
        </Form.Group>
        <Form.Group controlId="formGridState" className="col col-sm-4">
            
            <input type="radio" value="other" onChange={(e) => handleRadioButton1(e)}  name="currentMailingAddress" /> Other
        </Form.Group>
   
    </Row>

{ showCurrentAddress === 'other' && (
        <div>
 <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                <Form.Label>Complete Current Residential Address</Form.Label>
                 <input type="text" onChange={(e) => setCurrentAddress(e.target.value)} value={currentAddress} name="mailingAddress1" className="form-control font-weight-normal" />
            </Form.Group>
        
        </Row>
     
        <Row className="mb-3">

        <Form.Group controlId="formBasicEmail" className="col col-sm-4">
            <Form.Label>Country</Form.Label>
            <Form.Select className="form-control font-weight-normal" name="currentCountry" value={currentCountry} onChange={(e) => handleCountry(e) }>
               <option>Select...</option>
                {country.map(items => <option key={items} >{items}</option>)}
            </Form.Select>
            </Form.Group>

       

            <Form.Group controlId="CurrentState" className="col col-sm-4">
            <Form.Label>Province / State</Form.Label>
            <Form.Select  className="form-control font-weight-normal" value={currentProvince} onChange={(e) => handleState(e) }>
            { currentCountry != 'Pakistan' && (
                <>
                <option >Select...</option>
                {getState.map(items => <option key={items}  >{items}</option>)}
                </>
            )}
              { currentCountry === 'Pakistan' && (
                <>
                <option value="">Select...</option>
                <option value="01">FATA / FANA</option>
                <option value="02">SINDH</option>
                <option value="03">PUNJAB</option>
                <option value="04">KHYBER PAKHTUNKHWA</option>
                <option value="05">BALOCHISTAN</option>
                <option value="06">FEDERAL CAPITAL</option>
                <option value="07">A.J.K.</option>
                </>
              )}
            </Form.Select>
        </Form.Group>

        <Form.Group controlId="currentCity" className="col col-sm-4">
            <Form.Label>City / Town / Village</Form.Label>
            <Form.Select defaultValue="Choose..." className="form-control font-weight-normal" name="currentCity" value={currentCity} onChange={(e) => setCurrentCity(e.target.value)}>
            { currentCountry != 'Pakistan' && (
             <>
              <option >Select...</option>
              {cities.map(items => <option key={items} >{items}</option>)} 
              </>
            )} 

               { currentProvince === '07' && (
                <>
                  <option value="">Select...</option>
                 <option value="0001">BAGH</option>
                <option value="0002">BHIMBER</option>
                <option value="0003">DHUNGI</option>
                <option value="0004">KHUIRATTA</option>
                <option value="0005">KOTLI</option>
                <option value="0006">MANGLA</option>
                <option value="0007">MIRPUR</option>
                <option value="0008">MUZAFFARABAD</option>
                <option value="0009">PLANDRI</option>
                <option value="0010">PUNCH</option>
                <option value="0011">RAWALAKOT</option>
                <option value="401">TANDLIANWALA</option>
                <option value="0402">DADYAL</option>
                <option value="0403">NEELUM</option>
                <option value="0398">SUDHNOTI</option>
                </>
              )}

             { currentProvince === '06' && (
                <>
                  <option value="">Select...</option>
                  <option value="0132">ISLAMABAD</option>
                </>
              )}

          
             { currentProvince === '02' && (
                <>
                  <option value="">Select...</option>
                  <option value="0307">ALI BANDAR</option>
                <option value="0308">BADEN</option>
                <option value="0309">CHACHRO</option>
                <option value="0310">DADU</option>
                <option value="0311">DAHARKI</option>
                <option value="0312">DIGRI</option>
                <option value="0313">DIPLO</option>
                <option value="0314">DOKRI</option>
                <option value="0315">GADRA</option>
                <option value="0316">GHANIAN</option>
                <option value="0317">GHAUSPUR</option>
                <option value="0318">GHOTKI</option>
                <option value="0319">GOTH MACHI</option>
                <option value="0320">HALA (SINDH) HALA</option>
                <option value="0321">HYDERABAD</option>
                <option value="0322">ISLAMKOT</option>
                <option value="0323">JACOBABAD</option>
                <option value="0324">JAMESABAD</option>
                <option value="0325">JAMSHORO</option>
                <option value="0326">JANGHAR</option>
                <option value="0327">JATI</option>
                <option value="0328">JHUDO</option>
                <option value="0329">JUNGSHAHI</option>
                <option value="0330">KAMBER</option>
                <option value="0331">KANDIARO</option>
                <option value="0332">KARACHI</option>
                <option value="0333">KASHMOR</option>
                <option value="0334">KETI BANDAR</option>
                <option value="0335">KHAIRPUR</option>
                <option value="0336">KHOKHROPUR</option>
                <option value="0337">KHORA</option>
                <option value="0338">KLUPRO</option>
                <option value="0339">KOT SARAE</option>
                <option value="0340">KOTRI</option>
                <option value="0341">LARKANA</option>
                <option value="0342">LUND</option>
                <option value="0343">MATHI</option>
                <option value="0344">MATIARI</option>
                <option value="0345">MEHAR</option>
                <option value="0346">MIRPUR BATORO</option>
                <option value="0347">MIRPUR KHAS</option>
                <option value="0348">MIRPUR SAKRO</option>
                <option value="0349">MITHANI</option>
                <option value="0350">MITHI</option>
                <option value="0351">MORO</option>
                <option value="0352">NAGAR PARKAR</option>
                <option value="0353">NAOKOT</option>
                <option value="0354">NAUDERO</option>
                <option value="0355">NAUSHARA</option>
                <option value="0356">NAUSHERO FEROZE</option>
                <option value="0357">NAWABSHAH</option>
                <option value="0358">NAZIMABAD</option>
                <option value="0359">MOIN JO DARO</option>
                <option value="0360">PENDOO</option>
                <option value="0361">POKRAN</option>
                <option value="0362">QAMBAR</option>
                <option value="0363">QAZI AHMAD</option>
                <option value="0364">RANIPUR</option>
                <option value="0365">RATODERO</option>
                <option value="0366">ROHRI</option>
                <option value="0367">SAIDU SHARIF</option>
                <option value="0368">SAKRAND</option>
                <option value="0369">SANGHAR</option>
                <option value="0370">SHADADKHOT</option>
                <option value="0371">SHAHBANDAR</option>
                <option value="0372">SHAHDADPUR</option>
                <option value="0373">SHAHPUR CHAKAR</option>
                <option value="0374">SHIKARPUR</option>
                <option value="0375">SUJAWAL</option>
                <option value="0376">SUKKUR</option>
                <option value="0377">TANDO ADAM</option>
                <option value="0378">TANDO ALAH YAR</option>
                <option value="0379">TANDO BAGO</option>
                <option value="0380">TAR AHAMD RIND</option>
                <option value="0381">THARPARKAR</option>
                <option value="0382">THATTA</option>
                <option value="0383">TUJAL</option>
                <option value="0384">UMARKOT</option>
                <option value="0385">VEIRWARO</option>
                <option value="0386">WARAH</option>
                <option value="0387">MIRPUR MATHELO</option>
                <option value="0388">PANOAKIL</option>
                <option value="0389">NANKANA SAHIB</option>
                <option value="0390">GOJRA</option>
                <option value="0391">JARANWALA</option>
                <option value="0392">CHUNIA</option>
                <option value="0393">KANDHKOT</option>
                <option value="0394">KHAIRPUR MIRIS</option>
                <option value="0395">UBARO</option>
                <option value="0396">TANDO MUHAMMAD KHAN</option>
                <option value="0397">MATLI</option>
                </>
              )}

            { currentProvince === '03' && (
                <>
                <option value="">Select...</option>
                <option value="0199">AHMED NAGER CHATHA</option>
                <option value="0200">AHMEDPUR EAST</option>
                <option value="0201">ALI PUR</option>
                <option value="0202">ARIFWALA</option>
                <option value="0203">ATTOCK</option>
                <option value="0204">BAHAWALNAGAR</option>
                <option value="0205">BAHAWALPUR</option>
                <option value="0206">BASTI MALOOK</option>
                <option value="0207">BHAGALCHUR</option>
                <option value="0208">BHAIPHERU</option>
                <option value="0209">BHAKKAR</option>
                <option value="0210">BHALWAL</option>
                <option value="0211">BUREWALA</option>
                <option value="0212">CHAILIANWALA</option>
                <option value="0213">CHAKWAL</option>
                <option value="0214">CHICHAWATNI</option>
                <option value="0215">CHINIOT</option>
                <option value="0216">CHOWK AZAM</option>
                <option value="0217">CHOWK SARWAR SHAHEED</option>
                <option value="0218">DARYA KHAN</option>
                <option value="0219">DASKA</option>
                <option value="0220">DERA GHAZI KHAN</option>
                <option value="0221">DERAWAR FORT</option>
                <option value="0222">DHAULAR</option>
                <option value="0223">DINA CITY</option>
                <option value="0224">DINGA</option>
                <option value="0225">DIPALPUR</option>
                <option value="0226">FAISALABAD</option>
                <option value="0227">FATEH JANG</option>
                <option value="0228">GADAR</option>
                <option value="0229">GHAKHAR MANDI</option>
                <option value="0230">GUJAR KHAN</option>
                <option value="0231">GUJRANWALA</option>
                <option value="0232">GUJRAT</option>
                <option value="0233">HAFIZABAD</option>
                <option value="0234">HAROONABAD</option>
                <option value="0235">HASILPUR</option>
                <option value="0236">HAVELI LAKHA</option>
                <option value="0237">JAHANIA</option>
                <option value="0238">JALLA ARAAIN</option>
                <option value="0239">JAMPUR</option>
                <option value="0240">JAUHARABAD</option>
                <option value="0241">JHANG</option>
                <option value="0242">JHELUM</option>
                <option value="0243">KALABAGH</option>
                <option value="0244">KAMALIA</option>
                <option value="0245">KAMOKEY</option>
                <option value="0246">KAROR LAL ESAN</option>
                <option value="0247">KASUR</option>
                <option value="0248">KHANEWAL</option>
                <option value="0249">KHANPUR</option>
                <option value="0250">KHARIAN</option>
                <option value="0251">KHUSHAB</option>
                <option value="0252">KOT ADDU</option>
                <option value="0253">LAAR</option>
                <option value="0254">LAHORE</option>
                <option value="0255">LALAMUSA</option>
                <option value="0256">LAYYAH</option>
                <option value="0257">LODHRAN</option>
                <option value="0258">MAILSI</option>
                <option value="0259">MAKHDOOM AALI</option>
                <option value="0260">MAMOORI</option>
                <option value="0261">MANDI BAHAUDDIN</option>
                <option value="0262">MANDI WARBURTON</option>
                <option value="0263">MIAN CHANNU</option>
                <option value="0264">MIANWALI</option>
                <option value="0265">MINAWALA</option>
                <option value="0266">MULTAN</option>
                <option value="0267">MURIDKE</option>
                <option value="0268">MURREE</option>
                <option value="0269">MUZAFFARGARH</option>
                <option value="0270">NAROWAL</option>
                <option value="0271">OKARA</option>
                <option value="0272">PAK PATTAN</option>
                <option value="0273">PANJGUR</option>
                <option value="0274">PATTOKI</option>
                <option value="0275">PIRMAHAL</option>
                <option value="0276">QILA DIDAR SINGH</option>
                <option value="0277">RABWAH</option>
                <option value="0278">RAHIM YAR KHAN</option>
                <option value="0279">RAIWIND</option>
                <option value="0280">RAJAN PUR</option>
                <option value="0281">RAWALPINDI</option>
                <option value="0282">RENALA KHURD</option>
                <option value="0283">ROHRI</option>
                <option value="0284">SADIQABAD</option>
                <option value="0285">SAFDARABAD-DABANSING</option>
                <option value="0286">SAHIWAL</option>
                <option value="0287">SAMBERIAL</option>
                <option value="0288">SANGLA HILL</option>
                <option value="0289">SARAI ALAMGIR</option>
                <option value="0290">SARGODHA</option>
                <option value="0291">SHAFQAT SHAHED CHOWK</option>
                <option value="0292">SHAKARGARH</option>
                <option value="0293">SHARAQPUR</option>
                <option value="0294">SHEIKHUPURA</option>
                <option value="0295">SIALKOT</option>
                <option value="0296">SOHAWA</option>
                <option value="0297">SOOIANWALA</option>
                <option value="0298">SUNDAR</option>
                <option value="0299">TAKHTBAI</option>
                <option value="0300">TALAGANG</option>
                <option value="0301">TARBELA</option>
                <option value="0302">TAXILA</option>
                <option value="0303">TOBA TEK SINGH</option>
                <option value="0304">VEHARI</option>
                <option value="0305">WAH CANTT</option>
                <option value="0306">WAZIRABAD</option>
                <option value="0404">HATTIAN BALA</option>
                </>
              )}

             { currentProvince === '01' && (
                <>
                  <option value="">Select...</option>
                  <option value="0179">ASTOR</option>
                <option value="0180">BAJAUR</option>
                <option value="0181">BARAMULA</option>
                <option value="0182">DIR</option>
                <option value="0183">GILGIT</option>
                <option value="0184">HANGU</option>
                <option value="0185">KHYBER</option>
                <option value="0186">KURRAM</option>
                <option value="0187">MALAKAND</option>
                <option value="0188">MIRAM SHAH</option>
                <option value="0189">MOHMAND</option>
                <option value="0190">NAGAR</option>
                <option value="0191">NORTH WAZIRISTAN</option>
                <option value="0192">PARACHINAR</option>
                <option value="0193">PEACOCK</option>
                <option value="0194">SHANDUR</option>
                <option value="0195">SHANGRILA</option>
                <option value="0196">SKARDU</option>
                <option value="0197">SOUTH WAZIRISTAN</option>
                <option value="0198">WANA</option>
                <option value="0400">HUNZA</option>
                </>
              )} 

             { currentProvince === '04' && (
                <>
                <option value="">Select...</option>
                <option value="0133">ABBOTTABAD</option>
                <option value="0134">ADEZAI</option>
                <option value="0135">AYUBIA</option>
                <option value="0136">BANDA DAUD SHAH</option>
                <option value="0137">BANNU</option>
                <option value="0138">BATAGRAM</option>
                <option value="0139">BIROTE</option>
                <option value="0140">BUNER</option>
                <option value="0141">CHAKDARA</option>
                <option value="0142">CHARSADDA</option>
                <option value="0143">CHITRAL</option>
                <option value="0144">DARGAI</option>
                <option value="0145">DARYA KHAN</option>
                <option value="0146">DERA ISMAIL KHAN</option>
                <option value="0147">DRASAN</option>
                <option value="0148">DROSH</option>
                <option value="0149">HANGU</option>
                <option value="0150">HARIPUR</option>
                <option value="0151">KALAM</option>
                <option value="0152">KARAK</option>
                <option value="0153">KHANASPUR</option>
                <option value="0154">KOHAT</option>
                <option value="0155">KOHISTAN</option>
                <option value="0156">LAKKI MARWAT</option>
                <option value="0157">LATAMBER</option>
                <option value="0158">LOWER DIR</option>
                <option value="0159">MADYAN</option>
                <option value="0160">MALAKAND</option>
                <option value="0161">MANSEHRA</option>
                <option value="0162">MARDAN</option>
                <option value="0163">MASTUJ</option>
                <option value="0164">MONGORA</option>
                <option value="0165">NOWSHERA</option>
                <option value="0166">PAHARPUR</option>
                <option value="0167">PESHAWAR</option>
                <option value="0168">SAIDU SHARIF</option>
                <option value="0169">SAKESAR</option>
                <option value="0170">SHANGLA</option>
                <option value="0171">SWABI</option>
                <option value="0172">SWAT</option>
                <option value="0173">TANGI</option>
                <option value="0174">TANK</option>
                <option value="0175">THALL</option>
                <option value="0176">TIMARGARA</option>
                <option value="0177">TORDHER</option>
                <option value="0178">UPPER DIR</option>
                <option value="0399">HASAN ABDAL</option>
                </>
              )}

                { currentProvince === '05' && (
                <>
                <option value="">Select...</option>
                <option value="0012">AMIR CHAH</option>
                <option value="0013">BAZDAR</option>
                <option value="0014">BELA</option>
                <option value="0015">BELLPAT</option>
                <option value="0016">BHAG</option>
                <option value="0017">BURJ</option>
                <option value="0018">CHAGAI</option>
                <option value="0019">CHAH SANDAN</option>
                <option value="0020">CHAKKU</option>
                <option value="0021">CHAMAN</option>
                <option value="0022">CHHATR</option>
                <option value="0023">DALBANDIN</option>
                <option value="0024">DERA BUGTI</option>
                <option value="0025">DHANA SAR</option>
                <option value="0026">DIWANA</option>
                <option value="0027">DUKI</option>
                <option value="0028">DUSHI</option>
                <option value="0029">DUZAB</option>
                <option value="0030">GAJAR</option>
                <option value="0031">GANDAVA</option>
                <option value="0032">GARHI KHAIRO</option>
                <option value="0033">GARRUCK</option>
                <option value="0034">GHAZLUNA</option>
                <option value="0035">GIRDAN</option>
                <option value="0036">GULISTAN</option>
                <option value="0037">GWADAR</option>
                <option value="0038">GWASH</option>
                <option value="0039">HAB CHAUKI</option>
                <option value="0040">HAMEEDABAD</option>
                <option value="0041">HARNAI</option>
                <option value="0042">HINGLAJ</option>
                <option value="0043">HOSHAB</option>
                <option value="0044">HUB</option>
                <option value="0045">ISPIKAN</option>
                <option value="0046">JAFFERABAD</option>
                <option value="0047">JHAL</option>
                <option value="0048">JHAL JHAO</option>
                <option value="0049">JHAL MAGSI</option>
                <option value="0050">JHATPAT</option>
                <option value="0051">JIWANI</option>
                <option value="0052">KALANDI</option>
                <option value="0053">KALAT</option>
                <option value="0054">KAMARAROD</option>
                <option value="0055">KANAK</option>
                <option value="0056">KANDI</option>
                <option value="0057">KANPUR</option>
                <option value="0058">KAPIP</option>
                <option value="0059">KAPPAR</option>
                <option value="0060">KARODI</option>
                <option value="0061">KATURI</option>
                <option value="0062">KHARAN</option>
                <option value="0063">KHUZDAR</option>
                <option value="0064">KIKKI</option>
                <option value="0065">KOHAN</option>
                <option value="0066">KOHLU</option>
                <option value="0067">KORAK</option>
                <option value="0068">LAHRI</option>
                <option value="0069">LASBELA</option>
                <option value="0070">LIARI</option>
                <option value="0071">LORALAI</option>
                <option value="0072">MACH</option>
                <option value="0073">MAND</option>
                <option value="0074">MANGUCHAR</option>
                <option value="0075">MASHKI CHAH</option>
                <option value="0076">MASLTI</option>
                <option value="0077">MASTUNG</option>
                <option value="0078">MEKHTAR</option>
                <option value="0079">MERUI</option>
                <option value="0080">MIANEZ</option>
                <option value="0081">MURGHA KIBZAI</option>
                <option value="0082">MUSA KHEL BAZAR</option>
                <option value="0083">NAGHA KALAT</option>
                <option value="0084">NAL</option>
                <option value="0085">NASEERABAD</option>
                <option value="0086">NAUROZ KALAT</option>
                <option value="0087">NUR GAMMA</option>
                <option value="0088">NUSHKI</option>
                <option value="0089">NUTTAL</option>
                <option value="0090">ORMARA</option>
                <option value="0091">PALANTUK</option>
                <option value="0092">PANJGUR</option>
                <option value="0093">PASNI</option>
                <option value="0094">PIHARAK</option>
                <option value="0095">PISHIN</option>
                <option value="0096">QAMRUDDIN KAREZ</option>
                <option value="0097">QILA ABDULLAH</option>
                <option value="0098">QILA LADGASHT</option>
                <option value="0099">QILA SAFED</option>
                <option value="0100">QILA SAIFULLAH</option>
                <option value="0101">QUETTA</option>
                <option value="0102">RAKHNI</option>
                <option value="0103">ROBAT THANA</option>
                <option value="0104">RODKHAN</option>
                <option value="0105">SAINDAK</option>
                <option value="0106">SANJAWI</option>
                <option value="0107">SARUNA</option>
                <option value="0108">SHABAZ KALAT</option>
                <option value="0109">SHAHPUR</option>
                <option value="0110">SHARAM JOGIZAI</option>
                <option value="0111">SHINGAR</option>
                <option value="0112">SHORAP</option>
                <option value="0113">SIBI</option>
                <option value="0114">SONMIANI</option>
                <option value="0115">SPEZAND</option>
                <option value="0116">SPINTANGI</option>
                <option value="0117">SUI</option>
                <option value="0118">SUNTSAR</option>
                <option value="0119">SURAB</option>
                <option value="0120">THALO</option>
                <option value="0121">TUMP</option>
                <option value="0122">TURBAT</option>
                <option value="0123">UMARAO</option> 
                <option value="0124">UTHAL</option>
                <option value="0125">VITAKRI</option>
                <option value="0126">WADH</option>
                <option value="0127">WASHAP</option>
                <option value="0128">WASJUK</option>
                <option value="0129">YAKMACH</option>
                <option value="0130">ZHOB</option>
                <option value="0131">ZIARAT</option>
                </>
              )}

                
            </Form.Select>
        </Form.Group>
     
       
     </Row>

        </div>
     )}


<Row className="mb-3">
        <Form.Label>Permanent Address</Form.Label>
        <Form.Group controlId="formGridState" className="col col-sm-4">
            
            <input type="radio" value="sameMailingAddress" onChange={(e) => handleRadioButton2(e)} name="currentAddress" /> Same as Mailing Address
        </Form.Group>
        <Form.Group controlId="formGridState" className="col col-sm-4">
            
            <input type="radio" value="currentResidentialAddress" onChange={(e) => handleRadioButton2(e)}  name="currentAddress" /> Same as Current Residential Address
        </Form.Group>
        <Form.Group controlId="formGridState" className="col col-sm-4">
            
            <input type="radio" value="other" onChange={(e) => handleRadioButton2(e)}  name="currentAddress" /> Other
        </Form.Group>
   
    </Row>

    { showPermanentAddress === 'other' && (
        <div>

<Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
                <Form.Label>Permanent Address</Form.Label>
                 <input type="text" onChange={(e) => setPermanentAddress(e.target.value)} value={permanentAddress} name="mailingAddress1" className="form-control font-weight-normal" />
            </Form.Group>
        
        </Row>
     
        <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-4">
            <Form.Label>Country</Form.Label>
            <Form.Select className="form-control font-weight-normal" name="mailingCountry" value={permanentCountry} onChange={(e) => handleCountry2(e)}>
                <option >Select...</option>
                {country.map(items => <option key={items} >{items}</option>)}
            </Form.Select>
            </Form.Group>

            <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Province / State</Form.Label>
            <Form.Select defaultValue="Choose..." className="form-control font-weight-normal" name="mailingProvince" value={permanentProvince} onChange={(e) => handleState2(e)}>
       
            { permanentCountry != 'Pakistan' && (
                <>
                <option >Select...</option>
                {getState2.map(items => <option key={items}  >{items}</option>)}
                </>
            )}
              { permanentCountry === 'Pakistan' && (
                <>
                <option value="">Select...</option>
                <option value="01">FATA / FANA</option>
                <option value="02">SINDH</option>
                <option value="03">PUNJAB</option>
                <option value="04">KHYBER PAKHTUNKHWA</option>
                <option value="05">BALOCHISTAN</option>
                <option value="06">FEDERAL CAPITAL</option>
                <option value="07">A.J.K.</option>
                </>
              )}

            </Form.Select>
        </Form.Group>
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>City / Town / Village</Form.Label>
            <Form.Select defaultValue="Choose..." className="form-control font-weight-normal" name="mailingCity" value={permanentCity} onChange={(e) => setPermanentCity(e.target.value)}>
           
            { permanentCountry != 'Pakistan' && (
             <>
              <option >Select...</option>
              {cities2.map(items => <option key={items} >{items}</option>)} 
              </>
            )} 

               { permanentProvince === '07' && (
                <>
                  <option value="">Select...</option>
                 <option value="0001">BAGH</option>
                <option value="0002">BHIMBER</option>
                <option value="0003">DHUNGI</option>
                <option value="0004">KHUIRATTA</option>
                <option value="0005">KOTLI</option>
                <option value="0006">MANGLA</option>
                <option value="0007">MIRPUR</option>
                <option value="0008">MUZAFFARABAD</option>
                <option value="0009">PLANDRI</option>
                <option value="0010">PUNCH</option>
                <option value="0011">RAWALAKOT</option>
                <option value="401">TANDLIANWALA</option>
                <option value="0402">DADYAL</option>
                <option value="0403">NEELUM</option>
                <option value="0398">SUDHNOTI</option>
                </>
              )}

             { permanentProvince === '06' && (
                <>
                  <option value="">Select...</option>
                  <option value="0132">ISLAMABAD</option>
                </>
              )}

          
             { permanentProvince === '02' && (
                <>
                  <option value="">Select...</option>
                  <option value="0307">ALI BANDAR</option>
                <option value="0308">BADEN</option>
                <option value="0309">CHACHRO</option>
                <option value="0310">DADU</option>
                <option value="0311">DAHARKI</option>
                <option value="0312">DIGRI</option>
                <option value="0313">DIPLO</option>
                <option value="0314">DOKRI</option>
                <option value="0315">GADRA</option>
                <option value="0316">GHANIAN</option>
                <option value="0317">GHAUSPUR</option>
                <option value="0318">GHOTKI</option>
                <option value="0319">GOTH MACHI</option>
                <option value="0320">HALA (SINDH) HALA</option>
                <option value="0321">HYDERABAD</option>
                <option value="0322">ISLAMKOT</option>
                <option value="0323">JACOBABAD</option>
                <option value="0324">JAMESABAD</option>
                <option value="0325">JAMSHORO</option>
                <option value="0326">JANGHAR</option>
                <option value="0327">JATI</option>
                <option value="0328">JHUDO</option>
                <option value="0329">JUNGSHAHI</option>
                <option value="0330">KAMBER</option>
                <option value="0331">KANDIARO</option>
                <option value="0332">KARACHI</option>
                <option value="0333">KASHMOR</option>
                <option value="0334">KETI BANDAR</option>
                <option value="0335">KHAIRPUR</option>
                <option value="0336">KHOKHROPUR</option>
                <option value="0337">KHORA</option>
                <option value="0338">KLUPRO</option>
                <option value="0339">KOT SARAE</option>
                <option value="0340">KOTRI</option>
                <option value="0341">LARKANA</option>
                <option value="0342">LUND</option>
                <option value="0343">MATHI</option>
                <option value="0344">MATIARI</option>
                <option value="0345">MEHAR</option>
                <option value="0346">MIRPUR BATORO</option>
                <option value="0347">MIRPUR KHAS</option>
                <option value="0348">MIRPUR SAKRO</option>
                <option value="0349">MITHANI</option>
                <option value="0350">MITHI</option>
                <option value="0351">MORO</option>
                <option value="0352">NAGAR PARKAR</option>
                <option value="0353">NAOKOT</option>
                <option value="0354">NAUDERO</option>
                <option value="0355">NAUSHARA</option>
                <option value="0356">NAUSHERO FEROZE</option>
                <option value="0357">NAWABSHAH</option>
                <option value="0358">NAZIMABAD</option>
                <option value="0359">MOIN JO DARO</option>
                <option value="0360">PENDOO</option>
                <option value="0361">POKRAN</option>
                <option value="0362">QAMBAR</option>
                <option value="0363">QAZI AHMAD</option>
                <option value="0364">RANIPUR</option>
                <option value="0365">RATODERO</option>
                <option value="0366">ROHRI</option>
                <option value="0367">SAIDU SHARIF</option>
                <option value="0368">SAKRAND</option>
                <option value="0369">SANGHAR</option>
                <option value="0370">SHADADKHOT</option>
                <option value="0371">SHAHBANDAR</option>
                <option value="0372">SHAHDADPUR</option>
                <option value="0373">SHAHPUR CHAKAR</option>
                <option value="0374">SHIKARPUR</option>
                <option value="0375">SUJAWAL</option>
                <option value="0376">SUKKUR</option>
                <option value="0377">TANDO ADAM</option>
                <option value="0378">TANDO ALAH YAR</option>
                <option value="0379">TANDO BAGO</option>
                <option value="0380">TAR AHAMD RIND</option>
                <option value="0381">THARPARKAR</option>
                <option value="0382">THATTA</option>
                <option value="0383">TUJAL</option>
                <option value="0384">UMARKOT</option>
                <option value="0385">VEIRWARO</option>
                <option value="0386">WARAH</option>
                <option value="0387">MIRPUR MATHELO</option>
                <option value="0388">PANOAKIL</option>
                <option value="0389">NANKANA SAHIB</option>
                <option value="0390">GOJRA</option>
                <option value="0391">JARANWALA</option>
                <option value="0392">CHUNIA</option>
                <option value="0393">KANDHKOT</option>
                <option value="0394">KHAIRPUR MIRIS</option>
                <option value="0395">UBARO</option>
                <option value="0396">TANDO MUHAMMAD KHAN</option>
                <option value="0397">MATLI</option>
                </>
              )}

            { permanentProvince === '03' && (
                <>
                <option value="">Select...</option>
                <option value="0199">AHMED NAGER CHATHA</option>
                <option value="0200">AHMEDPUR EAST</option>
                <option value="0201">ALI PUR</option>
                <option value="0202">ARIFWALA</option>
                <option value="0203">ATTOCK</option>
                <option value="0204">BAHAWALNAGAR</option>
                <option value="0205">BAHAWALPUR</option>
                <option value="0206">BASTI MALOOK</option>
                <option value="0207">BHAGALCHUR</option>
                <option value="0208">BHAIPHERU</option>
                <option value="0209">BHAKKAR</option>
                <option value="0210">BHALWAL</option>
                <option value="0211">BUREWALA</option>
                <option value="0212">CHAILIANWALA</option>
                <option value="0213">CHAKWAL</option>
                <option value="0214">CHICHAWATNI</option>
                <option value="0215">CHINIOT</option>
                <option value="0216">CHOWK AZAM</option>
                <option value="0217">CHOWK SARWAR SHAHEED</option>
                <option value="0218">DARYA KHAN</option>
                <option value="0219">DASKA</option>
                <option value="0220">DERA GHAZI KHAN</option>
                <option value="0221">DERAWAR FORT</option>
                <option value="0222">DHAULAR</option>
                <option value="0223">DINA CITY</option>
                <option value="0224">DINGA</option>
                <option value="0225">DIPALPUR</option>
                <option value="0226">FAISALABAD</option>
                <option value="0227">FATEH JANG</option>
                <option value="0228">GADAR</option>
                <option value="0229">GHAKHAR MANDI</option>
                <option value="0230">GUJAR KHAN</option>
                <option value="0231">GUJRANWALA</option>
                <option value="0232">GUJRAT</option>
                <option value="0233">HAFIZABAD</option>
                <option value="0234">HAROONABAD</option>
                <option value="0235">HASILPUR</option>
                <option value="0236">HAVELI LAKHA</option>
                <option value="0237">JAHANIA</option>
                <option value="0238">JALLA ARAAIN</option>
                <option value="0239">JAMPUR</option>
                <option value="0240">JAUHARABAD</option>
                <option value="0241">JHANG</option>
                <option value="0242">JHELUM</option>
                <option value="0243">KALABAGH</option>
                <option value="0244">KAMALIA</option>
                <option value="0245">KAMOKEY</option>
                <option value="0246">KAROR LAL ESAN</option>
                <option value="0247">KASUR</option>
                <option value="0248">KHANEWAL</option>
                <option value="0249">KHANPUR</option>
                <option value="0250">KHARIAN</option>
                <option value="0251">KHUSHAB</option>
                <option value="0252">KOT ADDU</option>
                <option value="0253">LAAR</option>
                <option value="0254">LAHORE</option>
                <option value="0255">LALAMUSA</option>
                <option value="0256">LAYYAH</option>
                <option value="0257">LODHRAN</option>
                <option value="0258">MAILSI</option>
                <option value="0259">MAKHDOOM AALI</option>
                <option value="0260">MAMOORI</option>
                <option value="0261">MANDI BAHAUDDIN</option>
                <option value="0262">MANDI WARBURTON</option>
                <option value="0263">MIAN CHANNU</option>
                <option value="0264">MIANWALI</option>
                <option value="0265">MINAWALA</option>
                <option value="0266">MULTAN</option>
                <option value="0267">MURIDKE</option>
                <option value="0268">MURREE</option>
                <option value="0269">MUZAFFARGARH</option>
                <option value="0270">NAROWAL</option>
                <option value="0271">OKARA</option>
                <option value="0272">PAK PATTAN</option>
                <option value="0273">PANJGUR</option>
                <option value="0274">PATTOKI</option>
                <option value="0275">PIRMAHAL</option>
                <option value="0276">QILA DIDAR SINGH</option>
                <option value="0277">RABWAH</option>
                <option value="0278">RAHIM YAR KHAN</option>
                <option value="0279">RAIWIND</option>
                <option value="0280">RAJAN PUR</option>
                <option value="0281">RAWALPINDI</option>
                <option value="0282">RENALA KHURD</option>
                <option value="0283">ROHRI</option>
                <option value="0284">SADIQABAD</option>
                <option value="0285">SAFDARABAD-DABANSING</option>
                <option value="0286">SAHIWAL</option>
                <option value="0287">SAMBERIAL</option>
                <option value="0288">SANGLA HILL</option>
                <option value="0289">SARAI ALAMGIR</option>
                <option value="0290">SARGODHA</option>
                <option value="0291">SHAFQAT SHAHED CHOWK</option>
                <option value="0292">SHAKARGARH</option>
                <option value="0293">SHARAQPUR</option>
                <option value="0294">SHEIKHUPURA</option>
                <option value="0295">SIALKOT</option>
                <option value="0296">SOHAWA</option>
                <option value="0297">SOOIANWALA</option>
                <option value="0298">SUNDAR</option>
                <option value="0299">TAKHTBAI</option>
                <option value="0300">TALAGANG</option>
                <option value="0301">TARBELA</option>
                <option value="0302">TAXILA</option>
                <option value="0303">TOBA TEK SINGH</option>
                <option value="0304">VEHARI</option>
                <option value="0305">WAH CANTT</option>
                <option value="0306">WAZIRABAD</option>
                <option value="0404">HATTIAN BALA</option>
                </>
              )}

             { permanentProvince === '01' && (
                <>
                  <option value="">Select...</option>
                  <option value="0179">ASTOR</option>
                <option value="0180">BAJAUR</option>
                <option value="0181">BARAMULA</option>
                <option value="0182">DIR</option>
                <option value="0183">GILGIT</option>
                <option value="0184">HANGU</option>
                <option value="0185">KHYBER</option>
                <option value="0186">KURRAM</option>
                <option value="0187">MALAKAND</option>
                <option value="0188">MIRAM SHAH</option>
                <option value="0189">MOHMAND</option>
                <option value="0190">NAGAR</option>
                <option value="0191">NORTH WAZIRISTAN</option>
                <option value="0192">PARACHINAR</option>
                <option value="0193">PEACOCK</option>
                <option value="0194">SHANDUR</option>
                <option value="0195">SHANGRILA</option>
                <option value="0196">SKARDU</option>
                <option value="0197">SOUTH WAZIRISTAN</option>
                <option value="0198">WANA</option>
                <option value="0400">HUNZA</option>
                </>
              )} 

             { permanentProvince === '04' && (
                <>
                <option value="">Select...</option>
                <option value="0133">ABBOTTABAD</option>
                <option value="0134">ADEZAI</option>
                <option value="0135">AYUBIA</option>
                <option value="0136">BANDA DAUD SHAH</option>
                <option value="0137">BANNU</option>
                <option value="0138">BATAGRAM</option>
                <option value="0139">BIROTE</option>
                <option value="0140">BUNER</option>
                <option value="0141">CHAKDARA</option>
                <option value="0142">CHARSADDA</option>
                <option value="0143">CHITRAL</option>
                <option value="0144">DARGAI</option>
                <option value="0145">DARYA KHAN</option>
                <option value="0146">DERA ISMAIL KHAN</option>
                <option value="0147">DRASAN</option>
                <option value="0148">DROSH</option>
                <option value="0149">HANGU</option>
                <option value="0150">HARIPUR</option>
                <option value="0151">KALAM</option>
                <option value="0152">KARAK</option>
                <option value="0153">KHANASPUR</option>
                <option value="0154">KOHAT</option>
                <option value="0155">KOHISTAN</option>
                <option value="0156">LAKKI MARWAT</option>
                <option value="0157">LATAMBER</option>
                <option value="0158">LOWER DIR</option>
                <option value="0159">MADYAN</option>
                <option value="0160">MALAKAND</option>
                <option value="0161">MANSEHRA</option>
                <option value="0162">MARDAN</option>
                <option value="0163">MASTUJ</option>
                <option value="0164">MONGORA</option>
                <option value="0165">NOWSHERA</option>
                <option value="0166">PAHARPUR</option>
                <option value="0167">PESHAWAR</option>
                <option value="0168">SAIDU SHARIF</option>
                <option value="0169">SAKESAR</option>
                <option value="0170">SHANGLA</option>
                <option value="0171">SWABI</option>
                <option value="0172">SWAT</option>
                <option value="0173">TANGI</option>
                <option value="0174">TANK</option>
                <option value="0175">THALL</option>
                <option value="0176">TIMARGARA</option>
                <option value="0177">TORDHER</option>
                <option value="0178">UPPER DIR</option>
                <option value="0399">HASAN ABDAL</option>
                </>
              )}

                { permanentProvince === '05' && (
                <>
                <option value="">Select...</option>
                <option value="0012">AMIR CHAH</option>
                <option value="0013">BAZDAR</option>
                <option value="0014">BELA</option>
                <option value="0015">BELLPAT</option>
                <option value="0016">BHAG</option>
                <option value="0017">BURJ</option>
                <option value="0018">CHAGAI</option>
                <option value="0019">CHAH SANDAN</option>
                <option value="0020">CHAKKU</option>
                <option value="0021">CHAMAN</option>
                <option value="0022">CHHATR</option>
                <option value="0023">DALBANDIN</option>
                <option value="0024">DERA BUGTI</option>
                <option value="0025">DHANA SAR</option>
                <option value="0026">DIWANA</option>
                <option value="0027">DUKI</option>
                <option value="0028">DUSHI</option>
                <option value="0029">DUZAB</option>
                <option value="0030">GAJAR</option>
                <option value="0031">GANDAVA</option>
                <option value="0032">GARHI KHAIRO</option>
                <option value="0033">GARRUCK</option>
                <option value="0034">GHAZLUNA</option>
                <option value="0035">GIRDAN</option>
                <option value="0036">GULISTAN</option>
                <option value="0037">GWADAR</option>
                <option value="0038">GWASH</option>
                <option value="0039">HAB CHAUKI</option>
                <option value="0040">HAMEEDABAD</option>
                <option value="0041">HARNAI</option>
                <option value="0042">HINGLAJ</option>
                <option value="0043">HOSHAB</option>
                <option value="0044">HUB</option>
                <option value="0045">ISPIKAN</option>
                <option value="0046">JAFFERABAD</option>
                <option value="0047">JHAL</option>
                <option value="0048">JHAL JHAO</option>
                <option value="0049">JHAL MAGSI</option>
                <option value="0050">JHATPAT</option>
                <option value="0051">JIWANI</option>
                <option value="0052">KALANDI</option>
                <option value="0053">KALAT</option>
                <option value="0054">KAMARAROD</option>
                <option value="0055">KANAK</option>
                <option value="0056">KANDI</option>
                <option value="0057">KANPUR</option>
                <option value="0058">KAPIP</option>
                <option value="0059">KAPPAR</option>
                <option value="0060">KARODI</option>
                <option value="0061">KATURI</option>
                <option value="0062">KHARAN</option>
                <option value="0063">KHUZDAR</option>
                <option value="0064">KIKKI</option>
                <option value="0065">KOHAN</option>
                <option value="0066">KOHLU</option>
                <option value="0067">KORAK</option>
                <option value="0068">LAHRI</option>
                <option value="0069">LASBELA</option>
                <option value="0070">LIARI</option>
                <option value="0071">LORALAI</option>
                <option value="0072">MACH</option>
                <option value="0073">MAND</option>
                <option value="0074">MANGUCHAR</option>
                <option value="0075">MASHKI CHAH</option>
                <option value="0076">MASLTI</option>
                <option value="0077">MASTUNG</option>
                <option value="0078">MEKHTAR</option>
                <option value="0079">MERUI</option>
                <option value="0080">MIANEZ</option>
                <option value="0081">MURGHA KIBZAI</option>
                <option value="0082">MUSA KHEL BAZAR</option>
                <option value="0083">NAGHA KALAT</option>
                <option value="0084">NAL</option>
                <option value="0085">NASEERABAD</option>
                <option value="0086">NAUROZ KALAT</option>
                <option value="0087">NUR GAMMA</option>
                <option value="0088">NUSHKI</option>
                <option value="0089">NUTTAL</option>
                <option value="0090">ORMARA</option>
                <option value="0091">PALANTUK</option>
                <option value="0092">PANJGUR</option>
                <option value="0093">PASNI</option>
                <option value="0094">PIHARAK</option>
                <option value="0095">PISHIN</option>
                <option value="0096">QAMRUDDIN KAREZ</option>
                <option value="0097">QILA ABDULLAH</option>
                <option value="0098">QILA LADGASHT</option>
                <option value="0099">QILA SAFED</option>
                <option value="0100">QILA SAIFULLAH</option>
                <option value="0101">QUETTA</option>
                <option value="0102">RAKHNI</option>
                <option value="0103">ROBAT THANA</option>
                <option value="0104">RODKHAN</option>
                <option value="0105">SAINDAK</option>
                <option value="0106">SANJAWI</option>
                <option value="0107">SARUNA</option>
                <option value="0108">SHABAZ KALAT</option>
                <option value="0109">SHAHPUR</option>
                <option value="0110">SHARAM JOGIZAI</option>
                <option value="0111">SHINGAR</option>
                <option value="0112">SHORAP</option>
                <option value="0113">SIBI</option>
                <option value="0114">SONMIANI</option>
                <option value="0115">SPEZAND</option>
                <option value="0116">SPINTANGI</option>
                <option value="0117">SUI</option>
                <option value="0118">SUNTSAR</option>
                <option value="0119">SURAB</option>
                <option value="0120">THALO</option>
                <option value="0121">TUMP</option>
                <option value="0122">TURBAT</option>
                <option value="0123">UMARAO</option> 
                <option value="0124">UTHAL</option>
                <option value="0125">VITAKRI</option>
                <option value="0126">WADH</option>
                <option value="0127">WASHAP</option>
                <option value="0128">WASJUK</option>
                <option value="0129">YAKMACH</option>
                <option value="0130">ZHOB</option>
                <option value="0131">ZIARAT</option>
                </>
              )}

            </Form.Select>
        </Form.Group>
    
     </Row>
    
        </div>
     )}

   
   
 
    <Row className="mb-3">
    <Form.Label>Share your contact details (Optional)</Form.Label>
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Telephone No. (Office)</Form.Label>
            <input type="text" onChange={(e) => setOffPhoneNumber(e.target.value)} value={offPhoneNumber} name="officePermanentPhoneNo" className="form-control font-weight-normal" />          
            <p className='validateStyle'>{offPhoneError}</p>
        </Form.Group>
        <Form.Group controlId="formGridState" className="col col-sm-4">
            <Form.Label>Telephone No. (Residence)</Form.Label>
            <input type="text" onChange={(e) => setRedPhoneNumber(e.target.value)} value={redPhoneNumber} name="residencePermanentPhoneNo" className="form-control font-weight-normal" />
            <p className='validateStyle'>{redPhoneError}</p>
        </Form.Group>

    </Row>
    <div className="form-row row">
                <div className="col">
                    <input type="submit" value="Save" onClick={handleSubmit} className="btn btn-primary" />
                    <input type="button" value="Next" onClick={navigateToAttorney}  className="btn btn-primary" />
                </div>
                </div>

                <div className="alert alert-success">
            {message}
           </div>
          <div className="alert alert-danger">
            {error}
        </div>
</form>

        </>
     
      
    )
    }
    export default ContactDetails;