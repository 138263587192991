import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, InputGroup, Row, Button } from 'react-bootstrap';
import { Fragment, useState } from 'react';
import style from './style.css';
import Navbar from "./Navbar.js";
import SidebarNormal from "./SidebarNormal.js";  
function OtherDetails(){
    const [grossIncome, setGrossIncome] = useState("");
    const [incomeSource, setIncomeSource] = useState("");
    const [occupation, setOccupation] = useState("");
    const [occupationNotInList, setOccupationNotInList] = useState("");
    const [jobTitle , setJobTitle] = useState("");
    const [department , setDepartment] = useState("");
    const [employeeName , setEmployeeName] = useState("");
    const [employeeAddress , setEmployeeAddress]  = useState("");
    const [zakatStatus , setZakatStatus] = useState("");
    const [bankName , setBankName] = useState("");
    const [remittanceBasis , setRemittanceBasis] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Inside handle Submit..");
        try {
          setMessage("");
          setError("");
          let res = await fetch("https://192.168.0.182:8085/normal/saveNormalOthersDetails", {
            method: "POST",
            body: JSON.stringify({
                userId:  sessionStorage.getItem('userId'),
                othGrossAnnualIncome: grossIncome,
                othSourceIncome: incomeSource,
                othOccupation: occupation,
                othOccupationOthers: occupationNotInList,
                othDesignation: jobTitle,
                othDepartment: department,
                othEmployeerDetails: employeeName,
                othEmployeerAddress: employeeAddress,
                othZakatStatus: zakatStatus,
                othBankName: bankName,
                othRemittance: remittanceBasis,
             
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            credentials: 'include',
            withCredentials: true
          });
          let resJson = await res.json();
          if (res.status === 200) {
            setGrossIncome("");
            setIncomeSource("");
            setOccupation("");
            setOccupationNotInList("");
            setJobTitle("");
            setDepartment("");
            setEmployeeName("");      
            setEmployeeAddress("");
            setZakatStatus("");
            setBankName("");
            setRemittanceBasis("");     
            setMessage("Form Submitted Successfully");
          } else {
            setError("Some error occured");
          }
        } catch (err) {
          console.log(err);
        }
      };

    return(
        <>
     <Row className="mb-3">
         <Navbar/>
    <div className="col-3">
  
        <br></br>
        <br></br>
    <SidebarNormal/>
    </div>
    <div className="col-9">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h3><b>Other Details</b></h3>
        

    <form className="container mt-3 mb-3">
 
 
       <Row className="mb-3">
           
                
            <Form.Label>INCOME DETAIL</Form.Label> 
            <br></br>
            <Form.Group controlId="incomeDetail" className="col col-sm-4">
                <Form.Label>Gross Annual Income Slab</Form.Label>
                <Form.Select className="form-control" name="gross_income" value={grossIncome} onChange={(e) => setGrossIncome(e.target.value)}>            
                <option value="">Select...</option>  
                <option value="J05">1,000,001 - 2,500,000</option>   
                <option value="J06">Above 2,500,000</option>   
                <option value="J01">UP TO 100,000</option>   
                <option value="J02">100,001 - 250,000</option>   
                <option value="J03">250,001 - 500,000</option>   
                <option value="J04">500,001 - 1,000,000</option>              
            </Form.Select>
            </Form.Group>
            <Form.Group controlId="incomeDetail" className="col col-sm-8">
            <Form.Label>Source Of Income</Form.Label>
            <input type="text" onChange={(e) => setIncomeSource(e.target.value)} value={incomeSource} name="income_Source" className="form-control" />
            </Form.Group>           
        </Row>
      

        <Row className="mb-3">
        <Form.Label>OCCUPATION</Form.Label>          
        <br></br>
            <Form.Group controlId="SelectOccupation" className="col col-sm-4">
                <Form.Label>Select Other If not In the List</Form.Label> 
                    <Form.Select className="form-control" name="gross_income" value={occupation} onChange={(e) => setOccupation(e.target.value)}>
                        <option value="">Select...</option>  
                        <option value="P001">Agriculturist</option>
                        <option value="P002">Business</option>
                        <option value="P003">Business Executive</option>
                        <option value="P005">House Hold</option>
                        <option value="P006">House Wife</option>
                        <option value="P007">Industrialist</option>
                        <option value="P012">Professional</option>
                        <option value="P013">Retired Person</option>
                        <option value="P014">Service</option>
                        <option value="P015">Student</option>
                        <option value="P019">Govt. / Public Sector</option>
                        <option value="P999">Others</option>
                    </Form.Select> 
                    </Form.Group>
                    <Form.Group controlId="incomeDetail" className="col col-sm-8">
                    <Form.Label>If Occupation Not In The List</Form.Label>  
                    <input type="text" onChange={(e)=>setOccupationNotInList(e.target.value)} value={occupationNotInList} name="occupNotINList" className="form-control" />                
            </Form.Group>
        </Row>

        <Row className = "mb-3">
            <Form.Label>JOB DETAILS</Form.Label>
            <br></br>
            <Form.Group controlId = "occupation" className="col col-sm-4">
            <Form.Label>Job Title / Designation</Form.Label>
            <input type = "text" onChange={(e)=>setJobTitle(e.target.value)} value={jobTitle} name="jobTitle" className="form-control" />
            </Form.Group>
            <Form.Group controlId="incomeDetail" className="col col-sm-8">
            <Form.Label>Department</Form.Label>
            <input type = "text" onChange={(e)=>setDepartment(e.target.value)} value={department} name="department" className="form-control" />
            </Form.Group>        
        </Row>

        <Row className = "mb-3">
            <Form.Label>Employer Detail</Form.Label>
            <br></br>
            <Form.Group controlId="employerName" className = "col col-sm-4">
                <Form.Label>Employer Name / Business</Form.Label>
                <input type = "text" onChange={(e)=>setEmployeeName(e.target.value)} value={employeeName} name="employee" className = "form-control" />
               </Form.Group>
                <Form.Group controlId="incomeDetail" className="col col-sm-8"> 
                <Form.Label>Employee Address</Form.Label>
                <input type = "text" onChange={(e)=>setEmployeeAddress(e.target.value)} value={employeeAddress} name="address" className = "form-control" />
            </Form.Group>
        </Row>

        <Row className = "mb-3">
            <Form.Label>Other</Form.Label>
            <br></br>
            <Form.Group controlId = "zakatStatus" className="col col-sm-4">
                <Form.Label>Zakat Status</Form.Label>
                <Form.Select onChange={(e)=>setZakatStatus(e.target.value)} value={zakatStatus} name= "zakat" className = "form-control">
                  <option value="">Select...</option>  
                  <option value="5">Muslim Zakat Deductible</option>              
                  <option value="6">Muslim Zakat Non-Deductible</option>
                  <option value="7">Not Applicable</option>
                </Form.Select>
                </Form.Group>
                <Form.Group controlId = "zakatStatus" className="col col-sm-8">
                <Form.Label>Bank Name</Form.Label>
                <input type="text" onChange={(e)=>setBankName(e.target.value)} value={bankName} name= "bankName" className="form-control"/>
            </Form.Group>
        </Row>
    
       <Row className = "mb-3">
       <Form.Group controlId="incomeDetail" className="col col-sm-12">
        <Form.Label>Remittance Basis</Form.Label>
 
        <Form.Select onChange={(e)=>setRemittanceBasis(e.target.value)} value={remittanceBasis} name= "remittance" className = "form-control">
                  <option value="">Select...</option>  
                  <option value="1">Non-Resident Pakistani (Repatriable)</option>              
                  <option value="2">Non-Resident Pakistani (Non-Repatriable)</option>
                  <option value="3">Foreigner Resident (Repatriable)</option>
                  <option value="4">Foreigner Resident (Non-Repatriable)</option>
                  <option value="5">Foreigner Non-Resident (Repatriable)</option>
                  <option value="6">Foreigner Non-Resident (Non-Repatriable)</option>
                  <option value="7">Resident Pakistani</option>
                </Form.Select>
        </Form.Group>
       </Row>

       <div className="form-row row">
                <div className="col">
                    <input type="submit" onClick={handleSubmit} className="btn btn-primary" />
                </div>
                </div>
   
                <div className="alert alert-success">
            {message}
           </div>
          <div className="alert alert-danger">
            {error}
        </div>

</form>
</div>
</Row>
        </>
     
      
    )
    }
    export default OtherDetails;