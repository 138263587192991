import React, { useEffect,useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import adminCss from './adminCss.css';

const userData = () =>{

    const navigate = useNavigate();
    const [users , setUsers] = useState([])
   
    var x = 1;
    useEffect(() => {
       
        getUserData()
      }, [])


    let getUserData= async (e) => {     
    try { 
        let res = await fetch("https://sahulat.fsedge.com:8085/admin/getAdminData", {
       //   let res = await fetch("http://192.168.0.182:8085/admin/getAdminData", {
        method: "POST",
        body: JSON.stringify({
        
       }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
          },
          credentials: 'include',
        withCredentials: true
      });
      let resJson = await res.json();
      if (res.status === 200) {
     //   console.log(resJson);
          setUsers(resJson)
      
        
      } else {
       
      }
     } catch (err) {
        console.log(err);
      }
    };

  function  edit (data)  { 
  
    navigate('OtpForm',{state:data.uin})
    console.log(data);
}


function handleOtp(data){
     
  fetch(`https://sahulat.fsedge.com:8085/sahulat/otpGeneration/${data.uin}`)
 // fetch(`http://192.168.0.182:8085/sahulat/otpGeneration/${data.uin}`)
       .then(response => {
       
         return response.json()
        
       })
       .then(data => {
       
       })
   }

   function handleKyc(data){
     
    fetch(`https://sahulat.fsedge.com:8085/sahulat/getKycDataByUserId/${data.uin}`)
  //  fetch(`http://192.168.0.182:8085/sahulat/getKycDataByUserId/${data.uin}`)
         .then(response => {
         
           return response.json()
          
         })
         .then(data => {
         
         })
     }
  
    return(
        <div className='container'>
            <h2 className='text-center'>User Data</h2>
            <table className='table table-bordered table-striped'>
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>UIN</th>
                    <th>Email</th>
                    <th>Otp Response</th>
                    <th>Send Otp</th>
                    <th>Kyc Response</th>
                    <th>Send Kyc</th>
                    <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        users.map(
                            user =>
                           
                            <tr key ={user.uin}>
                               <td>{x++}</td>
                                <td>{user.uin}</td>
                                <td>{user.email}</td>
                                <td>{user.otpResponse}</td>
                                <td> <input type="submit" value="Send Otp Data" onClick={() => handleOtp(user)} className="admin-btn btn-danger" /></td>
                                <td>{user.kycResponse}</td>
                                <td> <input type="submit" value="Send Kyc Data" onClick={() => handleKyc(user)} className="admin-btn btn-danger" /></td>
                               
                                <td> <input type="submit" value="Edit Otp" onClick={() => edit(user)} className="admin-btn btn-info" /></td>

                            </tr>
                       )
                    }
                </tbody>

            </table>

        </div>
    )
}

export default userData

